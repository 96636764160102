<template>
  <div
    v-if="authStore && loadCalendar"
    class="position-relative"
  >
    <FullCalendar
      ref="calendar"
      :options="options"
    />
  </div>
</template>

<script>
import '@fullcalendar/core/vdom';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import frLocale from '@fullcalendar/core/locales/fr';
import nlLocale from '@fullcalendar/core/locales/nl';
import moment from 'moment';
import { Popover } from 'bootstrap';
import qs from 'qs';
import { mapActions } from 'pinia';
import { useMainStore } from '../stores';
import { TOAST_TYPES } from '../helpers/constants';
import { getColor } from '../helpers/color';

export default {
  name: 'Calendar',
  components: {
    FullCalendar,
  },
  props: {
    droppable: {
      type: Boolean,
      default: false,
    },
    view: {
      type: String,
      default: 'user',
    },
  },
  data() {
    return {
      loadCalendar: false,
      popovers: [],
      options: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          listPlugin,
        ],
        locales: [frLocale, nlLocale],
        locale: this.$i18n.locale,
        initialView: 'dayGridMonth',
        aspectRatio: 1.3,
        headerToolbar: {
          left: 'prev,today,next',
          center: 'title',
          right: 'dayGridMonth,listMonth',
        },
        events: (info, successCallback, failureCallback) => {
          const query = qs.stringify({
            uid: this.authStore?.user?.id || null,
            type: this.view,
            start: info.startStr,
            end: info.endStr,
          });
          this.$axios
            .get('/checkupentries/calendar?' + query)
            .then((response) => {
              successCallback(response.data);
            })
            .catch((error) => {
              failureCallback(error);
            });
        },
        eventSourceSuccess: this.transformEvents,
        lazyFetching: true,
        eventDidMount: this.setPopover,
        eventReceive: this.receiveEvent,
      },
    };
  },
  /**
   * Add event listeners
   */
  mounted() {
    window.addEventListener('resize', this.setAspectRatio);

    this.mitt.on('langChanged', () => {
      this.options.locale = this.$i18n.locale;
    });

    /**
     * Allow calendar to load after rendering
     */
    this.$nextTick(() => {
      this.loadCalendar = true;
    });
  },

  /**
   * Remove event listeners
   */
  beforeUnmount() {
    this.mitt.off('langChanged');
    this.hidePopovers();
    window.removeEventListener('resize', this.setAspectRatio);
  },

  methods: {
    ...mapActions(useMainStore, ['addToast']),
    /**
     * Clear calendar and refetch all events
     */
    refetchEvents() {
      //
      this.$refs.calendar.getApi().removeAllEvents();
      this.$refs.calendar.getApi().refetchEvents();
    },

    goToDate(date) {
      this.$refs.calendar.getApi().gotoDate(date);
    },
    /**
     * Set aspect ratio for calendar
     */
    setAspectRatio() {
      this.options.aspectRatio =
        window.outerWidth < 1025 ? 0.8 : 1.35;
    },

    /**
     * Handle the receiving of an event
     * @param info
     */
    receiveEvent(info) {
      if (!this.droppable) return;
      const events = this.$refs.calendar.getApi().getEvents();
      const pulse = events.find(
        (event) =>
          event.id === info.event.id && event.extendedProps.isPulse
      );
      if (
        pulse &&
        (moment(info.event.start).isBetween(
          moment(pulse.start),
          moment(pulse.end)
        ) ||
          moment(info.event.end).isBetween(
            moment(pulse.start),
            moment(pulse.end)
          ))
      ) {
        this.addToast({
          type: TOAST_TYPES.WARNING,
          content: this.$t('schedule.pulse_already_ongoing'),
        });
        info.revert();
        return;
      }
      info.event.setEnd(
        moment(info.event.start).add(
          info.event.extendedProps.duration,
          'd'
        )
      );
      this.$emit('postEvent', info);
      this.addToast({
        type: TOAST_TYPES.SUCCESS,
        content: this.$t('schedule.checkup_planned'),
      });
    },

    /**
     * Add color and other client-side params to events
     * @param content
     * @returns {*}
     */
    transformEvents(content) {
      return content.map((item) => ({
        ...item,
        borderColor: getColor(item.borderColor),
        backgroundColor: getColor(item.backgroundColor),
      }));
    },
    /**
     * Hide all popovers
     */
    hidePopovers() {
      for (const popover of this.popovers) {
        popover.hide();
      }
    },
    /**
     * Set popovers for current events
     * @param info info object of fullcalendar event
     */
    setPopover(info) {
      if (this.view !== 'schedule') return;
      info.el.setAttribute('tabindex', '0');
      const popover = new Popover(info.el, {
        title: '',
        content: this.popoverContent(info.event),
        placement: 'left',
        html: true,
        allowList: Popover.Default.allowList,
      });

      info.el.addEventListener('shown.bs.popover', () => {
        // Make a click outside element to hide popovers
        document
          .querySelector('button.edit-entry')
          ?.addEventListener('click', () => {
            this.$emit('openEntryModal', info.event);
          });
        document
          .querySelector('button.edit-event')
          ?.addEventListener('click', () => {
            this.$emit('openEntryModal', info.event);
          });
        window.addEventListener('click', this.hidePopovers, {
          once: true,
        });
      });

      this.popovers.push(popover);
    },
    /**
     * Modify popover content here, cannot add vue components
     * @param event
     * @returns {string}
     */
    popoverContent(event) {
      return `
                <div class="row">
                  <div class="border-bottom pb-2 mb-2  col-12 d-flex justify-content-between align-items-center">
                    <h5 class="mb-0">${event.title}</h5>
                     ${
                       moment(event.start).isAfter(moment()) ||
                       !event.extendedProps.submissions
                         ? `<button id="entry-${event.extendedProps.entryid}" class="p-0 btn btn-link edit-entry">
                        <i class="fas fa-calendar c-theme c-200"></i>
                    </button>`
                         : ''
                     }
                    <button id="event-${
                      event.id
                    }" class="p-0 btn btn-link edit-event">
                        <img src="${require(`@/assets/icons/Icon-checkup-edit.svg`)}" alt="edit-popover">
                    </button>
                  </div>
                  <div class="col-4">Starts</div>
                  <div class="col-8">${moment(event.start).format(
                    'YYYY-MM-DD'
                  )}</div>
                  <div class="col-4">Ends</div>
                  <div class="col-8">${moment(event.end).format(
                    'YYYY-MM-DD'
                  )}</div>
                  <div class="col-4">Grace</div>
                  <div class="col-8">${
                    event.extendedProps.graceperiod +
                    ' ' +
                    this.$t('message.days')
                  }</div>
                </div>
                `;
    },
  },
};
</script>

<style lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
.fc-event {
  cursor: pointer;
}

/**
 * Full calendar mobile styling
 */
@include media-breakpoint-down(md) {
  .fc .fc-toolbar {
    flex-wrap: wrap !important;

    > div:nth-child(2) {
      order: -1;
      flex-basis: 100%;
      margin-bottom: 1rem;
    }
  }
}
</style>
