<template>
  <form
    class="forgot-password card login-form"
    action=""
    @submit.prevent="submitForm"
  >
    <div class="card-header bgc-okergeel c-50">
      <div class="overline text-sm">
        <span>{{ $t('message.forgot_password') }}</span>
      </div>
    </div>
    <div class="card-body">
      <p>
        <label
          for="email"
          class="text-sentence"
          >{{ $t('message.email') }}</label
        >
        <input
          id="email"
          v-model="email"
          required
          class="w-100"
          name="email"
          type="text"
        />
      </p>
      <p
        v-if="error"
        class="text-sentence mb-0 c-koraalrood c-200"
      >
        {{ error }}
      </p>
      <p
        style="margin-top: 37px"
        class="mb-0 d-flex justify-content-between align-items-center"
      >
        <button
          type="button"
          class="btn btn-secondary me-5"
          @click="handleCancel"
        >
          <span>{{ $t('message.cancel') }}</span>
        </button>
        <button
          v-if="!isLoading"
          class="btn btn-primary"
          type="submit"
        >
          <span>{{ $t('message.send') }}</span>
        </button>
        <button
          v-else
          class="btn disabled"
          type="button"
          disabled
        >
          <span
            style="height: 12px; width: 12px"
            class="spinner-border"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </span>
        </button>
      </p>
    </div>
  </form>
</template>
<script>
import _ from 'lodash';
// import axios from "@/axios-auth";
export default {
  data() {
    return {
      email: '',
      valid: true,
      isLoading: false,
      error: null,
    };
  },
  methods: {
    handleCancel() {
      this.email = '';
      this.error = null;
      this.valid = true;
      this.$emit('forgot');
    },
    async submitForm() {
      this.error = null;
      this.valid = true;
      if (this.email === '') {
        this.valid = false;
        this.error = this.$t('message.fill_in_all_fields');
        return;
      }
      try {
        this.isLoading = true;
        await this.$axios.post('/mailer/mailreset', {
          email: this.email,
          url: window.location.origin,
        });

        this.error =
          this.$t('message.password_mail_sent', {
            email: this.email,
          }) + '.';
        this.email = '';
      } catch (err) {
        console.error(err);
        this.error = _.get(
          err,
          'response.data.message[0].messages[0].message',
          'Authentication failure'
        );
      }
      this.isLoading = false;
    },
  },
};
</script>
