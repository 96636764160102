import { createI18n } from 'vue-i18n';
import { dateTime } from '../lang';

/**
 * Load all translation files from each language directory
 * @returns {{}}
 */
const loadLocaleMessages = () => {
  //! require.context is webpack specific, replace this when moving to Vite
  const locales = require.context(
    '../lang',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  ); // Load all the translation files in directories

  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\//i); // Get the language key from the folder name
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = { ...messages[locale], ...locales(key) }; // Append messages to the language key
    }
  });
  return messages;
};

/**
 * Export the i18n instance
 */
export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'en',
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
  datetimeFormats: dateTime,
});
