<script setup>
import { Chart, registerables } from 'chart.js';

import { Radar } from 'vue-chartjs';
import { computed /* inject, ref, watch */ } from 'vue';

// import { breakpointsBootstrapV5, useBreakpoints } from '@vueuse/core';

Chart.register(...registerables);

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  cluster: {
    type: Number,
    default: null,
  },
  type: {
    type: String,
    required: true,
    validator(value) {
      return ['user', 'swarm'].includes(value);
    },
  },
  hideLabels: {
    type: Boolean,
    default: false,
  },
});

const availableData = computed(() =>
  props.data?.datasets?.some(
    (set) =>
      set.data.length &&
      set.data.every((item) => item?.value > 0 || item > 0)
  )
);

// const breakpoints = useBreakpoints(breakpointsBootstrapV5);
// const showLabels = computed(
//   () => !props.hideLabels && breakpoints.greater('xl')
// );
const emit = defineEmits(['click']);
const chartOptions = computed(() => {
  return {
    responsive: true,
    scales: {
      r: {
        min: 0,
        max: 100,
        pointLabels: {
          // display: showLabels.value,
          font: {
            size: 14,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: props.data.datasets.length > 1,
        position: 'bottom',
      },
    },
    onClick: (event, activeElements) => emit('click', activeElements),
  };
});
</script>

<template>
  <div>
    <div v-if="availableData">
      <!--            <Modal
              ref="feedbackModal"
              size="lg"
            >
              <FeedbackModal
                :type="`${type}_radar`"
                :factor="selectedItem"
              />
            </Modal>-->
      <template v-if="props.data">
        <Radar
          :chart-data="{
            ...props.data,
            labels:
              type === 'swarm'
                ? props.data.labels
                : props.data.labels.map((_, i) => i + 1),
          }"
          :chart-options="chartOptions"
        />
        <div
          v-if="type === 'user'"
          style="columns: 2"
        >
          <div
            v-for="(label, i) in props.data.labels"
            :key="`legend-${label}`"
            class="d-flex justify-content-between cursor-pointer align-items-start gap-2 text-sm mb-2"
            @click="emit('click', i)"
          >
            <span>{{ i + 1 }}. {{ label }}</span>
            <span
              class="rounded-pill px-2 py-0 border-0 bg-theme--300"
            >
              {{ Math.round(props.data.datasets[0].data[i]) }}%
            </span>
          </div>
        </div>
      </template>
    </div>
    <div v-else>
      <p class="text-center text-black-50">
        {{ $t('warning.data_is_processing') }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadarChart',
};
</script>

<style scoped></style>
