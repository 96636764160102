<script setup>
import { computed, inject, ref } from 'vue';

const props = defineProps({
  totalRows: {
    type: Number,
    required: true,
  },
  currentPage: {
    type: Number,
    required: true,
  },
  allowLengthChange: {
    type: Boolean,
    default: true,
  },
  perPage: {
    type: Number,
    default: null,
  },
});

const pageLengths = {
  10: '10',
  25: '25',
  50: '50',
  100: '100',
};

const showPerPage = inject('itemsPerPage', 10);
const itemsPerPage = ref(props.perPage || showPerPage);

const totalPages = computed(() =>
  Math.ceil(props.totalRows / itemsPerPage.value)
);
const activePage = computed(() => props.currentPage + 1);

const emit = defineEmits(['change']);

const emitPage = (moveBy) => {
  emit('change', moveBy);
};
</script>

<template>
  <div class="mt-3 d-flex justify-content-between align-items-center">
    <div class="flex-grow-1">
      <slot name="bottom-left" />
    </div>
    <div
      v-if="totalPages > 1"
      class="pagination justify-content-center align-items-center flex-grow-0 mb-0"
    >
      <button
        v-if="currentPage"
        class="btn btn-sm"
        @click="emitPage(-1)"
      >
        <i class="fas fa-chevron-left fa-fw" />
      </button>
      <div class="mx-2">
        {{
          $t('message.active_page', {
            current: activePage,
            total: totalPages,
          })
        }}
      </div>
      <button
        v-if="activePage !== totalPages"
        class="btn btn-sm"
        @click="emitPage(1)"
      >
        <i class="fas fa-chevron-right fa-fw" />
      </button>
    </div>
    <div
      v-if="allowLengthChange"
      class="flex-grow-1 d-flex justify-content-end"
    >
      <select
        v-model="itemsPerPage"
        class="input"
      >
        <option
          v-for="(value, key) in pageLengths"
          :key="key"
          :value="+key"
        >
          {{ value }}
        </option>
      </select>
    </div>
  </div>
</template>

<style scoped></style>
