<script setup>
import { nextTick, onMounted, ref, watch } from 'vue';
import { Tooltip } from 'bootstrap';

const props = defineProps({
  score: {
    type: Number,
    required: true,
  },
});

const barArrow = ref(null);
const bar = ref(null);

const arrowOffset = ref(0);
const setOffset = () => {
  const height = barArrow.value?.clientHeight || 0;
  const barHeight = bar.value?.clientHeight || 0;
  const barVal = (barHeight / 100) * props.score;
  arrowOffset.value = barVal - height / 2;
};

onMounted(() => {
  nextTick(() => {
    new Tooltip(barArrow.value);
    setOffset();
  });
});

watch(
  () => props.score,
  () => {
    nextTick(() => {
      setOffset();
    });
  }
);
</script>

<template>
  <div
    ref="bar"
    style="width: 10px; height: 90px; margin-top: 8px"
    class="position-relative"
  >
    <div
      class="bg-great rounded-top"
      style="height: 22.5px"
    ></div>
    <div
      class="bg-average"
      style="height: 45px"
    ></div>
    <div
      class="bg-bad rounded-bottom"
      style="height: 22.5px"
    ></div>
    <div
      ref="barArrow"
      class="position-absolute bar-indicator"
      style="
        left: 100%;
        height: 20px;
        rotate: -0.25turn;
        font-size: 12px;
      "
      data-bs-toggle="tooltip"
      :data-bs-title="`${score}%`"
      :style="`bottom: ${arrowOffset}px`"
    >
      <i class="fas fa-triangle"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerticalScale',
};
</script>

<style scoped>
.bar-indicator {
  transition: all 0.5s ease-in-out;
}
</style>
