<script setup>
import { TOAST_TYPES, TOAST_CLASSES } from '../../helpers/constants';
import { computed, ref, defineProps, onMounted } from 'vue';
import { useMainStore } from '../../stores';
import { getContrastColor, getColor } from '../../helpers/color';

const store = useMainStore();

const props = defineProps({
  message: {
    type: Object,
    default: () => ({
      id: null,
      content: 'This is a toast!',
      type: TOAST_TYPES.SUCCESS,
      duration: 2000,
    }),
  },
});
const toast = ref(null);
const textColor = computed(() => {
  if (!toast.value) return getColor('theme--800');
  return getContrastColor(
    window.getComputedStyle(toast.value).backgroundColor
  );
});

onMounted(() => {
  setTimeout(close, props.message.duration);
});

const close = () => {
  store.removeToast(props.message.id);
};
</script>

<template>
  <div
    v-if="message"
    ref="toast"
    class="rounded-8 shadow py-2 px-4 d-flex justify-content-between align-items-center"
    :class="[
      TOAST_CLASSES[message.type.toString().toUpperCase()],
      textColor,
    ]"
  >
    <span>{{ message.content }}</span>
    <button
      class="cursor-pointer border-0 bg-transparent py-0 px-0"
      :class="textColor"
      @click="close"
    >
      <i class="fas fa-times" />
    </button>
  </div>
</template>

<style scoped></style>
