<template>
  <form
    class="action-form"
    @submit.stop.prevent="submitAction"
  >
    <!--    {{type}}-->
    <!--    id: {{ id }}-->
    <select
      v-if="type === 'team'"
      id=""
      v-model="teamid"
      required
      class="w-100 mb-3"
      name=""
    >
      <option
        disabled
        value=""
      >
        {{ $t('message.choose_team') }}
      </option>
      <option
        v-for="team in teams"
        :key="team"
        :value="team.id"
      >
        {{ team.title }}
      </option>
    </select>
    <textarea
      id=""
      v-model="body"
      :placeholder="$t('message.description')"
      name=""
      cols="30"
      rows="5"
      class="w-100 mb-3"
    />
    <div class="bottom-actions d-flex mb-0">
      <div
        v-if="type !== 'personal'"
        class="col d-flex align-items-center"
      >
        <checkbox v-model="visible" />
        <label
          class="mb-0 text-sentence"
          for=""
          >{{ $t('message.share_action_with_team') }}</label
        >
      </div>
      <div class="ms-auto col-auto">
        <button
          v-if="!loading"
          type="submit"
          class="btn btn-primary"
        >
          <span class="text-sentence">{{ $t('message.save') }}</span>
        </button>
        <button
          v-else
          class="btn disabled d-flex"
          type="button"
          disabled
        >
          <div
            style="height: 12px; width: 12px"
            class="spinner-border"
            role="status"
          >
            <span class="visually-hidden">{{
              $t('message.loading')
            }}</span>
          </div>
          <span class="ms-2 text-sentence">{{
            $t('message.submitting')
          }}</span>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import Checkbox from '@/views/components/simple/Checkbox';
import { mapState } from 'pinia';
import { useAuthStore } from '../../stores/auth';
// import qs from "qs";

export default {
  components: { Checkbox },
  props: {
    availableTeams: Array,
    type: {
      default() {
        return 'personal';
      },
    },
    defer: {
      default() {
        return false;
      },
    },
    id: null,
  },
  emits: ['submitActionForm'],
  data() {
    return {
      loading: false,
      teams: [],
      body: '',
      completed: false,
      visible: false,
      teamid: '',
    };
  },
  created() {
    if (this.availableTeams.length) {
      this.teamid = this.availableTeams[0].id.toString();
    }

    if (this.availableTeams.length) {
      this.teams = this.availableTeams;
    } else {
      this.getManagedTeams();
    }

    if (this.id) {
      this.getAction();
    }
  },
  computed: {
    ...mapState(useAuthStore, ['user']),
    isbase() {
      return this.type === 'company' ? true : false;
    },
  },
  methods: {
    async getManagedTeams() {
      this.teams = this.user.swarms.filter((swarm) => {
        return !swarm.issegment;
      });
    },
    async getAction() {
      const get = await this.$axios.get('/actions/' + this.id);
      const action = get.data;
      this.body = action.body;
      this.completed = action.completed;
      this.visible = action.visible;
      this.teamid = action.teamid;
    },
    async submitAction() {
      this.loading = true;
      const action = {
        isbase: this.isbase,
        body: this.body,
        completed: this.completed,
        visible: this.visible,
        teamid: this.type === 'company' ? '0' : String(this.teamid),
      };
      if (this.defer) {
        this.$emit('submitActionForm', action);
      } else {
        if (this.id !== null) {
          const request = await this.$axios.put(
            '/actions/' + this.id,
            action
          );
          this.$emit('submitActionForm', request.data);
        } else {
          const request = await this.$axios.post('/actions', action);
          this.$emit('submitActionForm', request.data);
        }
      }
      this.loading = false;
    },
  },
};
</script>
