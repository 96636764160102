import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';
import { useSettingsStore } from './settings';

export const useMainStore = defineStore('main', {
  state: () => {
    return {
      company: null,
      publicSettings: {},
      toasts: [],
      vitaminColors: [],
      advancedAnalytics: useStorage('advanced', true),
    };
  },
  getters: {
    companyName: (state) => state.company?.title || '',
    setting: (state) => (property) => {
      if (!(property in state.publicSettings)) return false;
      return state?.publicSettings[property] || false;
    },
    keyColor: (state) => (key) => {
      if (
        !state.vitaminColors.some((c) =>
          c.keys.some((k) => k === key.toUpperCase())
        )
      )
        return 'theme-300';
      return state.vitaminColors.find((c) =>
        c.keys.some((k) => k === key.toUpperCase())
      ).color;
    },
  },
  actions: {
    /**
     * Fetch company data and public settings to use throughout the application
     * Full settings are moved to separate store to use on settings page, prevents unnecessary fetching
     * @returns {Promise<void>}
     */
    async fetchAll() {
      const [
        { data: company },
        { data: publicSettings },
        { data: vitamins },
      ] = await Promise.all([
        this.axios.get(`/companybranches?isdefault=true`),
        this.axios.get(`/settings/1`),
        this.axios.get(
          `/vitamins?_locale=${this.i18n.global.locale}`
        ),
      ]);
      this.company = company[0];
      this.publicSettings = publicSettings;
      this.vitaminColors = [
        ...vitamins.map((v) => ({
          keys: [v.key, ...v.factors.map((f) => f.key)],
          title: v.title,
          body: v.body,
          color: v.color,
          factors: v.factors,
        })),
      ];
    },

    addToast(payload) {
      const toast = {
        id: this.toasts.length,
        duration: 3000,
        ...payload,
      };

      this.toasts.push(toast);
    },

    removeToast(id) {
      this.toasts = this.toasts.filter((toast) => toast.id !== id);
    },

    async setSetting(key, value) {
      this.publicSettings[key] = value;
      const settingsStore = useSettingsStore();
      if (!settingsStore.settings)
        await settingsStore.fetchSettings();
      settingsStore.settings.data[key] = value;
      settingsStore.saveSettings();
    },
  },
});
