import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Signin from '../views/Signin';
import Vitamins from '@/views/Vitamins';
import CheckupForm from '@/views/CheckupForm';
import UserResetPassword from '@/views/UserResetPassword';
import { useAuthStore } from '@/stores/auth';
import { useMainStore } from '@/stores';
import { TOAST_TYPES } from '../helpers/constants';
import Definitions from '../views/Definitions';
// import axios from "@/axios-auth";

const routes = [
  {
    path: '/',
    alias: '/dashboard',
    meta: { requiresAuth: true },
    name: 'dashboard',
    component: Home,
  },
  {
    path: '/login',
    meta: { requiresUnAuth: true },
    name: 'login',
    component: Signin,
  },
  {
    path: '/reset',
    meta: {
      requiresUnAuth: true,
      passThrough: true,
    },
    name: 'reset',
    component: UserResetPassword,
    async beforeEnter(to, from, next) {
      const authStore = useAuthStore();
      const mainStore = useMainStore();
      /*
       * check if user is authenticated.
       * if user is authenticated attach a message on redirect to dashboard
       */
      const auth = authStore.isAuthenticated;
      if (auth) {
        await mainStore.addToast({
          content: 'message.already_logged_in',
          type: TOAST_TYPES.INFO,
        });
        next('/');
      } else {
        next();
      }
    },
  },
  {
    path: '/vitamins',
    meta: { requiresAuth: true },
    name: 'vitamins',
    component: Vitamins,
    // component: () => import(/* webpackChunkName: "vitamins" */ '../views/Vitamins.vue')
  },
  {
    path: '/vitamins/checkup/:id',
    meta: { requiresAuth: true },
    name: 'checkup',
    // props: true,
    // route level code-splitting
    // this generates a separate chunk (vitamins.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:
      CheckupForm /*() => import('../views/CheckupForm.vue')*/,
  },
  {
    path: '/actions',
    meta: { requiresAuth: true },
    name: 'actions',
    component: () => import('../views/Actions.vue'),
    async beforeEnter(to, from, next) {
      const mainStore = useMainStore();

      if (mainStore.setting('actionsenabled')) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/definitions',
    meta: { requiresAuth: true },
    name: 'definitions',
    component: Definitions,
    // component: () => import(/* webpackChunkName: "vitamins" */ '../views/Vitamins.vue')
  },
  {
    path: '/profile',
    meta: { requiresAuth: true },
    name: 'profile',
    component: () => import('../views/Profile.vue'),
  },
  {
    path: '/feedback',
    meta: { requiresAuth: true },
    name: 'feedback',
    component: () => import('../views/Feedback.vue'),
    async beforeEnter(to, from, next) {
      const mainStore = useMainStore();

      if (mainStore.setting('hidefeedback')) {
        next(from.path === to.path ? '/' : from.path);
      } else {
        next();
      }
    },
  },
  {
    path: '/schedule',
    meta: { requiresAuth: true, permissions: ['manageschedule'] },
    name: 'schedule',
    component: () => import('../views/Schedule.vue'),
  },
  {
    path: '/checkups',
    meta: {
      requiresAuth: true,
      permissions: [
        'ismanager',
        'viewTeams',
        'manageteams',
        'managesegments',
        'viewSegments',
      ],
    },
    name: 'checkups',
    component: () => import('../views/Checkups.vue'),
  },
  {
    path: '/tree',
    meta: {
      requiresAuth: true,
      permissions: ['ismanager', 'issegmentmanager', 'manageteams'],
    },
    name: 'tree',
    component: () =>
      import(/* webpackChunkName: "tree" */ '../views/Tree.vue'),
  },
  {
    path: '/segments',
    meta: { requiresAuth: true, permissions: ['managesegments'] },
    name: 'segments',
    component: () =>
      import(
        /* webpackChunkName: "segments" */ '../views/Segments.vue'
      ),
    /*    async beforeEnter(to, from, next) {
      const user = store.getters.getUser;
      const settings = store.getters.getPublicSettings;

      if (!settings) {
        await store.dispatch("fetchPublicSettings");
      }
      if (user.managesegments || user.managecompany) {
        next();
      } else {
        next("/");
      }
    },*/
  },
  {
    path: '/analytics',
    meta: {
      requiresAuth: true,
      permissions: ['manageanalytics'],
      settings: ['statsenabled'],
    },
    name: 'analytics',
    component: () =>
      import(
        /* webpackChunkName: "heatmap" */ '../views/Heatmap.vue'
      ),
  },
  {
    path: '/users',
    meta: { requiresAuth: true, permissions: ['manageusers'] },
    name: 'users',
    component: () =>
      import(/* webpackChunkName: "users" */ '../views/Users.vue'),
  },
  {
    path: '/settings',
    meta: { requiresAuth: true, permissions: ['managesettings'] },
    name: 'settings',
    component: () =>
      import(
        /* webpackChunkName: "settings" */ '../views/Settings.vue'
      ),
  },
  { path: '/:catchAll(.*)', redirect: '/' },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async function (to, from, next) {
  const authStore = useAuthStore();
  const mainStore = useMainStore();

  // Attempt to log in before entering a route if user is not authenticated
  if (!authStore.isAuthenticated && authStore.token) {
    try {
      await authStore.login({ email: '', password: '' });
    } catch (error) {
      return next('/login');
    }
  }

  const { requiresAuth, requiresUnAuth, permissions, settings } =
    to.meta;
  let access = true;

  // If user is not logged in and page requires it, no need for further checks
  if (!authStore.isAuthenticated && requiresAuth) {
    return next('/login');
  }

  /**
   * Fetch settings if they are not already fetched
   */
  if (
    authStore.isAuthenticated &&
    !Object.keys(mainStore.publicSettings).length
  ) {
    await mainStore.fetchAll();
  }

  let indexRoute = '/';

  if (
    mainStore.setting('hidedashboard') ||
    (authStore.isAuthenticated && !authStore.hasDashboard)
  ) {
    indexRoute = '/vitamins';

    if (to.path === '/') {
      return next(indexRoute);
    }
  }

  // If it requires a non-logged in user and user is logged in, go to home
  if (requiresUnAuth && authStore.isAuthenticated) {
    if (to.meta.passthrough) next();
    return next(indexRoute);
  }

  if (permissions?.length && !authStore.permission(permissions)) {
    access = false;
  }

  if (settings?.length) {
    for (const setting of settings) {
      if (!mainStore.setting(setting)) {
        access = false;
        break;
      }
    }
  }

  if (!access) {
    return next(indexRoute);
  }

  return next();
});

export default router;
