<script setup>
import Card from '../../views/components/simple/Card';
import { defineEmits, defineProps, inject, ref } from 'vue';
import { useCheckupStore } from '../../stores/checkup';
import moment from 'moment';
import { useAsyncState } from '@vueuse/core';
import { useAuthStore } from '../../stores/auth';
import _ from 'lodash';
import { useRouter } from 'vue-router';
import { useMainStore } from '../../stores';

const checkupStore = useCheckupStore();
const vitamins = inject('vitamins');

defineProps({
  hasAnsweredAll: {
    type: Boolean,
    default: false,
  },
});

const authStore = useAuthStore();
const mainStore = useMainStore();
const axios = inject('axios');
const { state: managers, isLoading } = useAsyncState(() => {
  /*  const { data } = await this.$axios.get(
      `/swarms/zerglings?_user=${this.form.user.id}`
  );*/
  return axios
    .request({
      method: 'GET',
      url: '/swarms/zerglings',
      params: {
        _user: authStore.user.id,
      },
    })
    .then((res) =>
      _.uniqBy(res.data.map((swarm) => swarm.overlords).flat(), 'id')
    );
});

defineEmits(['finishQuestions']);

const router = useRouter();
const isSaving = ref(false);
const finalizeCheckup = async () => {
  isSaving.value = true;
  checkupStore.isfinished = true;
  await checkupStore.save();
  router.push('/');
};

/**
 * Update the privacy of the current submission
 */
const setPrivacy = () => {
  checkupStore.isprivate = !checkupStore.isprivate;
  checkupStore.submit();
};
</script>

<template>
  <Card
    :color="hasAnsweredAll ? 'green--dark' : 'theme--500'"
    :color-shade="hasAnsweredAll ? 200 : 500"
  >
    <template #header>
      <span>{{
        hasAnsweredAll ? $t('message.completed') : $t('message.einde')
      }}</span>
    </template>
    <h4 class="text-sentence">
      {{
        hasAnsweredAll
          ? $t('message.bedankt')
          : $t('message.je_bent_er_bijna')
      }}
    </h4>
    <template v-if="!hasAnsweredAll">
      <p>{{ $t('message.bent_er_bijna_copy') }}</p>
      <ul class="checkup-details list-group list-group-flush">
        <li
          class="px-0 list-group-item d-flex justify-content-between align-items-baseline"
        >
          <div class="me-auto">
            <img
              src="../../assets/icons/icon-toggle-check-box.svg"
              alt=""
            />&nbsp;<span class="text-sentence">{{
              $t('message.vragen_ingevuld')
            }}</span>
          </div>
          <div>{{ checkupStore.getAnsweredQuestions.length }}</div>
        </li>
        <li
          class="px-0 list-group-item d-flex justify-content-between align-items-baseline"
        >
          <div class="me-auto">
            <img
              src="../../assets/icons/icon-toggle-check-box_off.svg"
              alt=""
            />&nbsp;<span class="text-sentence">{{
              $t('message.nog_in_te_vullen')
            }}</span>
          </div>
          <div>
            {{
              vitamins.questionCount -
              checkupStore.getAnsweredQuestions.length
            }}
          </div>
        </li>
        <li
          v-if="vitamins.deadline"
          class="px-0 list-group-item d-flex justify-content-between align-items-baseline"
        >
          <div class="me-auto">
            <img
              src="../../assets/icons/Icon-survey-Calendar.svg"
              alt=""
            />&nbsp;<span class="text-sentence">{{
              $t('message.resterende_dagen')
            }}</span>
          </div>
          <div>
            {{ moment(vitamins.deadline).diff(moment(), 'days') }}
          </div>
        </li>
        <li
          v-if="!mainStore.setting('hidefeedback')"
          class="px-0 list-group-item d-flex justify-content-between align-items-baseline"
        >
          <div class="me-auto d-flex gap-2 align-items-center">
            <span
              class="status cursor-pointer"
              @click.prevent="setPrivacy()"
            >
              <span v-show="checkupStore.isprivate"
                ><img
                  src="../../assets/icons/Icon-survey-locked.svg"
                  alt=""
                />&nbsp;<span class="text-sentence">{{
                  $t('message.je_antwoorden_zijn_prive')
                }}</span>
              </span>
              <span v-show="!checkupStore.isprivate"
                ><img
                  src="../../assets/icons/Icon-survey-un-locked.svg"
                  alt=""
                />&nbsp;<span class="text-sentence">{{
                  $t('message.je_antwoorden_zijn_zichtbaar')
                }}</span>
              </span>
            </span>
            <select
              v-if="!isLoading"
              v-model="checkupStore.manager"
            >
              <option :value="null">All your managers</option>
              <option
                v-for="manager in managers"
                :key="`manager-${manager.id}`"
                :value="manager.id"
              >
                {{ manager.firstname }} {{ manager.lastname }}
              </option>
            </select>
          </div>
        </li>
      </ul>
    </template>
    <template v-else>
      <div
        v-if="!mainStore.setting('hidefeedback')"
        class="row privatemode mt-3 mb-5"
      >
        <div class="col-auto">
          <span
            class="status cursor-pointer"
            @click.prevent="setPrivacy()"
          >
            <span v-show="checkupStore.isprivate"
              ><img
                src="../../assets/icons/Icon-survey-locked.svg"
                alt=""
            /></span>
            <span v-show="!checkupStore.isprivate"
              ><img
                src="../../assets/icons/Icon-survey-un-locked.svg"
                alt=""
            /></span>
          </span>
        </div>
        <div class="col">
          <span
            v-if="checkupStore.isprivate"
            class="text-sentence"
          >
            <strong>{{
              $t('message.je_antwoorden_blijven_prive')
            }}</strong
            ><br />
            <p class="text-sentence">
              {{
                $t(
                  'message.wil_je_ze_met_je_manager_delen_ontgrendel_dan_het_slotje'
                )
              }}
            </p>
          </span>
          <span
            v-else
            class="text-sentence"
          >
            <strong>{{
              $t(
                'message.je_antwoorden_worden_gedeeld_met_je_manager'
              )
            }}</strong
            ><br />
            <p class="text-sentence">
              {{
                $t(
                  'message.wil_je_prive_houden_vergrendel_dan_het_slotje'
                )
              }}
            </p>
          </span>
        </div>
      </div>
      <div
        v-if="!checkupStore.isprivate"
        class="d-flex align-items-center gap-2 mb-3"
      >
        <span>Your vitamins are shared with: </span>
        <select
          v-if="!isLoading"
          v-model="checkupStore.manager"
        >
          <option :value="null">All your managers</option>
          <option
            v-for="manager in managers"
            :key="`manager-${manager.id}`"
            :value="manager.id"
          >
            {{ manager.firstname }} {{ manager.lastname }}
          </option>
        </select>
      </div>
    </template>
    <div class="actions row">
      <div
        v-if="!hasAnsweredAll"
        class="col-auto mx-auto"
      >
        <button
          class="btn btn-cta"
          @click="$emit('finishQuestions')"
        >
          <span class="text-sentence">{{
            $t('message.afwerken')
          }}</span>
        </button>
      </div>
      <div
        v-else
        class="col-auto mx-auto d-flex align-items-end"
      >
        <button
          class="btn btn-secondary"
          @click="finalizeCheckup"
        >
          <span
            v-if="isSaving"
            class="me-2"
          >
            <i class="fas fa-circle-notch fa-spin"></i>
          </span>
          <span class="text-sentence">{{
            $t('message.afronden')
          }}</span>
        </button>
      </div>
    </div>
  </Card>
</template>

<script>
export default {
  name: 'CheckupEndCard',
};
</script>

<style scoped></style>
