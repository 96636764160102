<script setup>
import { useI18n } from 'vue-i18n';

const { locale, availableLocales } = useI18n();
</script>

<template>
  <!-- MOBILE NAV -->
  <mobile-nav />
  <!--  <pre>-->
  <!--    auth: {{ isAuthenticated }}-->
  <!--    token: {{ hasToken }}-->
  <!--  </pre>-->
  <div
    v-show="authStore && mainStore && globalLoad"
    id="main-wrapper"
    class=""
  >
    <!-- DESKTOP NAV -->
    <aside
      v-if="
        $route.name !== 'login' &&
        authStore.isAuthenticated &&
        authStore.user
      "
      class="d-none d-md-flex main-navigation can-collapse-vert flex-column overflow-auto"
      :class="navbarCollapsed ? 'collapsed' : ''"
      style="max-height: 100vh"
    >
      <div class="collapse-wrapper border-bottom text-right mb-3">
        <button
          class="btn btn-link d-block ms-auto action"
          :class="navbarCollapsed && 'px-0'"
          @click="navbarCollapsed = !navbarCollapsed"
        >
          <span v-show="navbarCollapsed">
            <img
              src="./assets/icons/Icon-Navigation-expand-1.svg"
              alt=""
            />
          </span>
          <span v-show="!navbarCollapsed">
            <img
              src="./assets/icons/icon-navigation-collapse.svg"
              alt=""
            />
          </span>
        </button>
      </div>
      <nav
        id="nav"
        class="user-nav nav flex-column border-bottom py-3"
      >
        <router-link
          v-if="
            !mainStore.setting('hidedashboard') &&
            authStore.user &&
            authStore.hasDashboard
          "
          class="nav-item mb-2"
          to="/"
        >
          <span class="icon">
            <img
              src="./assets/icons/Icon-sidebar-dashboard.svg"
              alt=""
            />
          </span>
          <span class="hide-collapse"
            >&nbsp;<span class="text-sentence">{{
              $t('message.dashboard')
            }}</span></span
          >
        </router-link>
        <router-link
          class="nav-item mb-2"
          to="/vitamins"
        >
          <span class="icon">
            <img
              src="./assets/icons/Icon-sidebar-vitamins.svg"
              alt=""
            />
          </span>
          <span class="hide-collapse"
            >&nbsp;<span class="text-sentence">{{
              $t('message.vitamins')
            }}</span></span
          >
          <!--      TODO: Re-add vitamins badge.    -->
          <!--          <span
            v-if="activeVitamins > 0"
            class="badge bg-secondary rounded-circle"
          >
            {{ activeVitamins }}
          </span>-->
        </router-link>
        <router-link
          v-show="mainStore.setting('actionsenabled')"
          class="nav-item mb-2"
          to="/actions"
        >
          <span class="icon">
            <img
              src="./assets/icons/Icon-sidebar-actions.svg"
              alt=""
            />
          </span>
          <span class="hide-collapse"
            >&nbsp;<span class="text-sentence">{{
              $t('message.actions')
            }}</span></span
          >
        </router-link>
        <router-link
          class="nav-item"
          to="/definitions"
        >
          <span class="icon">
            <i class="fas fa-fw fa-books c-groen c-200" />
          </span>
          <span class="hide-collapse"
            >&nbsp;<span class="text-sentence">{{
              $t('message.definitions')
            }}</span></span
          >
        </router-link>
      </nav>
      <nav
        class="profile-nav profile nav flex-column border-bottom py-3"
      >
        <router-link
          class="nav-item"
          to="/profile"
        >
          <span class="icon">
            <img
              src="./assets/icons/Icon-sidebar-Profile.svg"
              alt=""
            />
          </span>
          <span class="hide-collapse"
            >&nbsp;<span class="text-sentence">{{
              $t('message.profile')
            }}</span></span
          >
        </router-link>
      </nav>
      <nav
        v-if="
          authStore.permission([
            'ismanager',
            'manageschedule',
            'issegmentmanager',
            'manageteams',
            'managesegments',
            'managefeedback',
            'manageanalytics',
            'managesettings',
            'viewSegments',
            'viewTeams',
          ])
        "
        class="manager-nav profile nav flex-column py-3"
      >
        <router-link
          v-if="authStore.permission(['manageschedule'])"
          class="nav-item mb-2"
          to="/schedule"
        >
          <span class="icon">
            <img
              src="./assets/icons/Icon-sidebar-schedule.svg"
              alt=""
            />
          </span>
          <span class="hide-collapse"
            >&nbsp;<span class="text-sentence">{{
              $t('message.schedule')
            }}</span></span
          >
        </router-link>
        <router-link
          v-if="
            authStore.permission([
              'ismanager',
              'viewTeams',
              'manageteams',
              'managesegments',
              'viewSegments',
            ])
          "
          class="nav-item mb-2"
          to="/checkups"
        >
          <span class="icon">
            <img
              src="./assets/icons/icon-sidebar-checkups.svg"
              alt=""
            />
          </span>
          <span class="hide-collapse"
            >&nbsp;<span class="text-sentence">{{
              $t('message.checkups')
            }}</span></span
          >
        </router-link>
        <router-link
          v-if="
            !mainStore.setting('hidefeedback') &&
            authStore.permission(['managefeedback', 'ismanager'])
          "
          class="nav-item mb-2"
          to="/feedback"
        >
          <span class="icon">
            <i class="fas fa-comments-alt c-groengrijs" />
          </span>
          <span class="hide-collapse"
            >&nbsp;<span class="text-sentence">{{
              $t('message.feedback')
            }}</span></span
          >
        </router-link>
        <router-link
          v-if="
            authStore.permission([
              'ismanager',
              'issegmentmanager',
              'manageteams',
            ])
          "
          class="nav-item mb-2"
          to="/tree"
        >
          <span class="icon">
            <img
              src="./assets/icons/Icon-sidebar-tree.svg"
              alt=""
            />
          </span>
          <span class="hide-collapse"
            >&nbsp;<span class="text-sentence">{{
              $t('message.tree')
            }}</span></span
          >
        </router-link>
        <router-link
          v-if="authStore.permission(['managesegments'])"
          class="nav-item mb-2"
          to="/segments"
        >
          <span class="icon">
            <img
              src="./assets/icons/Icon-sidebar-segments.svg"
              alt=""
            />
          </span>
          <span class="hide-collapse"
            >&nbsp;<span class="text-sentence">{{
              $t('message.segments')
            }}</span></span
          >
        </router-link>
        <router-link
          v-show="mainStore.setting('statsenabled')"
          v-if="authStore.permission(['manageanalytics'])"
          class="nav-item mb-2"
          to="/analytics"
        >
          <span class="icon">
            <img
              src="./assets/icons/Icon-sidebar-heat.svg"
              alt=""
            />
          </span>
          <span class="hide-collapse"
            >&nbsp;<span class="text-sentence">{{
              $t('message.analytics')
            }}</span></span
          >
        </router-link>
        <router-link
          v-if="authStore.permission(['manageusers'])"
          class="nav-item mb-2"
          to="/users"
        >
          <span class="icon">
            <img
              src="./assets/icons/Icon-sidebar-Users.svg"
              alt=""
            />
          </span>
          <span class="hide-collapse"
            >&nbsp;<span class="text-sentence">{{
              $t('message.users')
            }}</span></span
          >
        </router-link>
        <router-link
          v-if="authStore.permission(['managesettings'])"
          class="nav-item"
          to="/settings"
        >
          <span class="icon">
            <img
              src="./assets/icons/Icon-sidebar-Settings.svg"
              alt=""
            />
          </span>
          <span class="hide-collapse"
            >&nbsp;<span class="text-sentence">{{
              $t('message.settings')
            }}</span></span
          >
        </router-link>
      </nav>
      <div
        class="logo-wrapper border-bottom flex-grow-1 d-flex align-items-end py-3"
      >
        <!--        <span v-if="serverurl && mainCompany.logo" class="w-100 hide-collapse">
          <img class="img-fluid" :src="serverurl + mainCompany.logo.url" alt=""
        /></span>-->
        <span
          v-if="mainStore.company && mainStore.company.logosmall"
          style="height: 54px; transition: all 0.4s ease"
          class="mx-auto d-flex justify-content-center overflow-hidden w-100"
        >
          <img
            class="img-fluid h-100"
            style="object-fit: contain"
            :src="`${$axios.defaults.baseURL}${mainStore.company.logosmall.url}`"
            alt=""
        /></span>
      </div>
      <nav class="log-out py-3 border-top">
        <a
          href="#"
          class="nav-item"
          @click.prevent.stop="logout"
        >
          <img
            src="./assets/icons/Icon-sidebar-log-out.svg"
            alt=""
          />
          <span class="hide-collapse"
            >&nbsp;<span class="text-sentence">{{
              $t('message.logout')
            }}</span></span
          >
        </a>
      </nav>
    </aside>
    <!-- MAIN PAGE CONTENT -->
    <main class="main-content-wrapper flex-grow-1">
      <!--   TOP BAR   -->

      <div
        v-if="$route.name !== 'login' && authStore.isAuthenticated"
        :class="
          authStore.user && authStore.user.id ? 'border-bottom' : ''
        "
        class="top-nav d-none d-md-flex"
      >
        <div
          v-if="authStore.user && authStore.user.id"
          class="pagetitle text-capitalize"
        >
          {{ $t(`message.${$route.name}`) }}
        </div>
        <div
          v-if="authStore.user && authStore.user.id"
          class="flex-grow-1 justify-content-center title d-flex align-items-baseline"
        >
          <img
            height="25"
            class="d-inline"
            src="./assets/images/vitamins.svg"
            alt=""
          />

          <!--          <div class="text">gi
            <em class="small text-sentence"
              >{{ $t('message.for') }}&nbsp;</em
            >
            <span class="company-name">{{
              mainStore.companyName
            }}</span>
          </div>-->
        </div>
        <nav
          :class="
            !authStore.user || !authStore.user.id ? 'flex-grow-1' : ''
          "
          class="d-flex justify-content-end top-menu"
        >
          <select
            v-model="locale"
            class="btn btn-xs bg-white"
          >
            <option
              v-for="lang in availableLocales"
              :key="`locale-${lang}`"
              :value="lang"
            >
              {{ lang.split('-')[0] }}
            </option>
          </select>
        </nav>
      </div>
      <!--   MAIN PAGE CONTENT   -->
      <!--      <div v-if="isAuthLoaded || $route.name === 'login'">-->
      <div v-if="loaded || $route.name === 'login'">
        <router-view @loadcomplete="handleLoaded" />
      </div>

      <!--      <div v-if="!attemptedLogin() && $route.name !== 'login'"-->
      <div
        v-if="!loaded"
        style="height: 100vh"
        class="d-flex align-items-center justify-content-center position-relative w-100 container-spinner"
      >
        <div
          class="spinner-border"
          role="status"
        >
          <span class="visually-hidden"
            >{{ $t('message.loading') }}...</span
          >
        </div>
      </div>
    </main>
    <!--   faq: {{activeFaq}}-->
    <!--  FAQ SIDEBAR  -->
    <aside
      v-if="
        $route.name !== 'login' &&
        authStore.isAuthenticated &&
        activeFaq
      "
      class="flex-shrink-0 help-sidebar can-collapse-vert flex-column overflow-hidden"
      :class="helpCollapsed ? 'collapsed' : ''"
    >
      <div
        v-show="helpCollapsed"
        class="flex-grow-1"
      >
        <div class="d-flex flex-column h-100">
          <button class="btn btn-link action px-0">
            <img
              src="./assets/icons/Icon-navigation-help.svg"
              alt=""
            />
          </button>
          <div class="flex-grow-1 d-flex align-items-center">
            <button
              class="btn btn-link action px-0"
              @click="helpCollapsed = !helpCollapsed"
            >
              <img
                src="./assets/icons/icon-navigation-collapse.svg"
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
      <div
        v-show="!helpCollapsed"
        class="collapse-wrapper border-bottom text-right mb-3"
      >
        <div class="d-flex">
          <button
            class="btn btn-link action"
            @click="helpCollapsed = !helpCollapsed"
          >
            <img
              src="./assets/icons/Icon-Navigation-expand-1.svg"
              alt=""
            />
          </button>
          <button class="btn btn-link d-block ms-auto action">
            <img
              src="./assets/icons/Icon-navigation-help.svg"
              alt=""
            />
          </button>
        </div>
      </div>
      <div class="help-content hide-collapse">
        <div v-html="activeFaq" />
      </div>
    </aside>
  </div>
  <template v-if="!globalLoad">
    <div
      class="bg-theme--200 position-absolute d-flex align-items-center justify-content-center flex-column"
      style="width: 100vw; height: 100vh"
    >
      <div class="mb-3">
        <img
          height="36"
          src="./assets/images/vitamins.svg"
          alt=""
        />
      </div>
      <p class="mb-5 text-sentence">
        {{ $t('message.loading_app') }}
      </p>
      <div
        style="height: 28px; width: 28px"
        class="spinner-grow bg-theme--400"
        role="status"
      >
        <span class="visually-hidden"
          >{{ $t('message.loading') }}...</span
        >
      </div>
    </div>
  </template>

  <!-- Top message -->
  <ToastList />
  <!--  <top-message :message="activeMessage" />-->
  <!--  <button @click.prevent="fillDummy">add teams to users</button>-->
  <!-- PRIMARY DYNAMIC MODAL -->
  <Modal
    id="primaryModal"
    ref="globalModal"
    :size="modalStore.modalOptions.size"
    :centered="modalStore.modalOptions.centered"
    :disable-background="modalStore.modalOptions?.disableBackground"
  >
    <component
      :is="modalStore.modalContent"
      v-bind="modalStore.modalProps"
      v-on="modalStore.modalEvents"
    />
  </Modal>
  <!--  <div
    v-if="modalStore"
    ref="globalModal"
    id="primaryModal"
    :data-bs-backdrop="
      modalStore
        ? modalStore.modalOptions.clickOutside
          ? false
          : 'static'
        : 'static'
    "
    class="modal"
    tabindex="-1"
  >
    <div
      class="modal-dialog"
      :class="
        (modalStore && modalStore.modalOptions
          ? 'modal-' + modalStore.modalOptions.size
          : '') +
        (modalStore && modalStore.modalOptions.centered
          ? ' modal-dialog-centered'
          : '')
      "
    >
    </div>
  </div>-->
</template>
<script>
import 'bootstrap';
import ErrorModal from '@/views/components/modals/ErrorModal';
import MobileNav from '@/views/MobileNav';
import { useModalStore } from '@/stores/modal';
import { mapStores } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { useMainStore } from '@/stores';
import Modal from '@/components/Modal';
import ToastList from './components/toast/ToastList';

export default {
  components: { ToastList, MobileNav, Modal },
  created() {
    this.initApp();
    this.mitt.on('loadcomplete', (e) => this.handleLoaded(e));
  },
  methods: {
    initModal() {
      const element = this.$refs.globalModal;
      this.modalStore.setModal({ element });

      this.$axios.interceptors.response.use(
        (res) => res,
        (err) => {
          if (!err.status && !err.response) {
            this.modalStore.openModal({
              component: ErrorModal,
              options: {
                centered: true,
              },
              properties: {
                err,
              },
            });
          } else {
            throw err;
          }
        }
      );
    },
    async initApp() {
      this.globalLoad = false;

      /**
       * Subscriber for Pinia login function to fetch extra information.
       */
      const unsubscribe = this.authStore.$onAction(
        ({ name, after }) => {
          after(async () => {
            if (name === 'login') {
              if (this.authStore.isAuthenticated) {
                await this.mainStore.fetchAll();
                this.faq = await this.getFaq();
                unsubscribe();
              }
              this.loaded = true;
              this.handleLoaded();
            }
          });
        }
      );

      try {
        await this.authStore.login({ email: '', password: '' });
      } catch (error) {
        console.error(error);
        this.authStore.logout();
        this.$router.push('/login');
      }
      this.initModal();
    },
    handleLoaded() {
      this.globalLoad = true;
      this.mitt.emit('hiddenLoader', { complete: true });
      // if (this.firstload) {
      //   this.mitt.emit("hiddenLoader", { complete: true });
      //   this.firstload = false;
      // }
    },
    handleChangeLocale() {
      localStorage.setItem('lang', this.$i18n.locale);
      if (this.user) {
        this.$axios.put('/customuser/' + this.user.id, {
          language: this.$i18n.locale,
        });
      }
      if (this.authStore.isAuthenticated) {
        this.mainStore.fetchAll();
      }

      this.mitt.emit('langChanged');
    },
    removeMessage() {
      this.$store.dispatch('removeMessage');
    },
    async getFaq() {
      try {
        const get = await this.$axios.get('/settings/faq');
        return get.data;
      } catch (err) {
        console.error(err);
        return [];
      }
    },
    async logout() {
      await this.authStore.logout();
      this.$router.replace('/login');
    },
  },
  computed: {
    ...mapStores(useMainStore, useModalStore, useAuthStore),
    activeFaq() {
      let locale = this.$i18n.locale;
      locale === 'en-GB' ? (locale = 'en') : '';
      if (this.faq) {
        if (this.faq[locale]) {
          if (this.faq[locale][this.$route.name]) {
            return this.faq[locale][this.$route.name];
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    /*
    activeMessage() {
      const store = useStore();
      return store.getters.getMessage;
    },*/
  },
  watch: {
    '$i18n.locale'() {
      this.handleChangeLocale();
    },
  },
  name: 'Home',
  data() {
    return {
      globalLoad: false,
      loaded: false,
      navbarCollapsed: false,
      helpCollapsed: true,
      firstload: true,
      faq: [],
    };
  },
};
</script>
<style lang="scss">
// TODO: Get rid of all this scss in the main app file
/*@font-face {
  font-family: 'Rubik';
  src: url(assets/fonts/Rubik/static/Rubik-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url(assets/fonts/Rubik/static/Rubik-Italic.ttf);
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url(assets/fonts/Rubik/static/Rubik-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url(assets/fonts/Rubik/static/Rubik-MediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'BioRhyme';
  src: url(assets/fonts/BioRhyme/BioRhyme-Light.ttf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'BioRhyme';
  src: url(assets/fonts/BioRhyme/BioRhyme-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url(assets/fonts/Nunito_Sans/NunitoSans-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url(assets/fonts/RobotoMono/RobotoMono-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}*/

$font-head: 'BioRhyme', Avenir, Helvetica, Arial, sans-serif;
$font-body: 'Rubik', Avenir, Helvetica, Arial, sans-serif;
$font-pct: 'Nunito Sans', Avenir, Helvetica, Arial, sans-serif;
$font-mono: 'Roboto Mono', 'Courier New', sans-serif;

$c_body: #3d4152;
$c_vitamins: #7cd579;
$c_vitamins-50: #bef8bc;
$c_theme: #2c303e;
$c_theme-800: $c_body;
$c_theme-700: #464c5f;
$c_theme-600: #555b6e;
$c_theme-500: #8b93a8;
$c_theme-400: #afb4c7;
$c_theme-300: #d9dce8;
$c_theme-200: #f2f2f5;
$c_theme-100: #ffffff;

$c_groen: #bae193;
$c_groen-50: #e1f7cc;
$c_groen-200: #91bf62;
$c_turkoois: #7bcdc7;
$c_turkoois-50: #c9edea;
$c_turkoois-200: #32a299;
$c_paars: #b99fd1;
$c_paars-50: #e3d8ed;
$c_paars-200: #8b61b2;
$c_magenta: #f0a5c4;
$c_magenta-25: #fff2f7;
$c_magenta-50: #f9dbe7;
$c_magenta-200: #c9638d;
$c_blauw: #99c7ef;
$c_blauw-50: #d6e9f9;
$c_blauw-200: #3c85c6;
$c_koraalrood: #ff9b87;
$c_koraalrood-50: #ffd7cf;
$c_koraalrood-200: #e26c54;
$c_okergeel: #fad173;
$c_okergeel-25: #fff7e5;
$c_okergeel-50: #fdedc7;
$c_okergeel-200: #d8a93d;
$c_groengrijs: #9fbeb6;
$c_groengrijs-50: #d8e5e2;
$c_groengrijs-200: #629588;

$c_success: #7cd578;
$c_warning: #ffa441;
$c_error: #fc7c86;

$c_great: #00c798;
$c_good: $c_success;
$c_average: #ffde53;
$c_poor: $c_warning;
$c_bad: $c_error;

$c_light_bg: $c_theme-200;
$c_light_card: $c_theme-100;
$c_light_enabled: $c_theme-800;
$c_light_disabled: $c_theme-400;

$c_dark_bg: $c_theme-700;
$c_dark_card: $c_theme-600;
$c_dark_enabled: $c_theme-200;
$c_dark_disabled: $c_theme-400;

$b_radius: 10px;

.nav-link {
  color: $c_body;

  &:hover {
    color: $c_theme-800;
  }
}

.rounded-8 {
  border-radius: 8px !important;
}

.rounded-10 {
  border-radius: 10px !important;
}

.rounded-16 {
  border-radius: 16px !important;
}

.rounded-20 {
  border-radius: 20px !important;
}

.rounded-24 {
  border-radius: 24px !important;
}

.rounded-30 {
  border-radius: 30px !important;
}

.rounded-32 {
  border-radius: 32px !important;
}

.text-sentence {
  display: inline-block;

  &:first-letter {
    text-transform: capitalize;
  }
}

.navbar-toggler:focus {
  box-shadow: none;
}

span::first-letter,
a::first-letter,
p::first-letter,
h1::first-letter,
h2::first-letter,
h3::first-letter,
h4::first-letter,
h5::first-letter,
th::first-letter,
button::first-letter,
em::first-letter,
input::first-letter,
option::first-letter,
strong::first-letter,
img::first-letter,
div::first-letter,
i::first-letter,
label::first-letter {
  text-transform: uppercase;
}
//
//div:first-letter {
//  text-transform: uppercase;
//}

.monospace {
  font-family: $font-mono;
}

.text-sentence-fl {
  &:first-letter {
    text-transform: capitalize;
  }
}

input[type='radio']:checked ~ .color-label {
  border-width: 3px;
  border-style: solid;
}

/*.nav-pills {
  .nav-link {
    &.active {
      border-radius: 8px;

      &.pill-groen {
        background-color: transparentize($c_groen-50, 0.4);
        color: $c_body;
      }

      &.pill-turkoois {
        background-color: transparentize($c_turkoois-50, 0.4);
        color: $c_body;
      }

      &.pill-paars {
        background-color: transparentize($c_paars-50, 0.4);
        color: $c_body;
      }

      &.pill-magenta {
        background-color: transparentize($c_magenta-50, 0.4);
        color: $c_body;
      }

      &.pill-blauw {
        background-color: transparentize($c_blauw-50, 0.4);
        color: $c_body;
      }

      &.pill-koraalrood {
        background-color: transparentize($c_koraalrood-50, 0.4);
        color: $c_body;
      }

      &.pill-okergeel {
        background-color: transparentize($c_okergeel-50, 0.4);
        color: $c_body;
      }

      &.pill-groengrijs {
        background-color: transparentize($c_groengrijs-50, 0.4);
        color: $c_body;
      }
    }
  }
}*/

.mb-gutter {
  margin-bottom: 1.5rem;
}

.bgc-groen {
  background-color: $c_groen !important;

  &.c-50 {
    background-color: $c_groen-50 !important;
  }

  &.c-200 {
    background-color: $c_groen-200 !important;
  }
}

.bgc-turkoois {
  background-color: $c_turkoois !important;

  &.c-50 {
    background-color: $c_turkoois-50 !important;
  }

  &.c-200 {
    background-color: $c_turkoois-200 !important;
  }
}

.bgc-paars {
  background-color: $c_paars !important;

  &.c-50 {
    background-color: $c_paars-50 !important;
  }

  &.c-200 {
    background-color: $c_paars-200 !important;
  }
}

.bgc-magenta {
  background-color: $c_magenta !important;

  &.c-50 {
    background-color: $c_magenta-50 !important;
  }

  &.c-200 {
    background-color: $c_magenta-200 !important;
  }
}

.bgc-blauw {
  background-color: $c_blauw !important;

  &.c-50 {
    background-color: $c_blauw-50 !important;
  }

  &.c-200 {
    background-color: $c_blauw-200 !important;
  }
}

.bgc-koraalrood {
  background-color: $c_koraalrood !important;

  &.c-50 {
    background-color: $c_koraalrood-50 !important;
  }

  &.c-200 {
    background-color: $c_koraalrood-200 !important;
  }
}

.bgc-okergeel {
  background-color: $c_okergeel !important;

  &.c-50 {
    background-color: $c_okergeel-50 !important;
  }

  &.c-200 {
    background-color: $c_okergeel-200 !important;
  }
}

.bgc-groengrijs {
  background-color: $c_groengrijs !important;

  &.c-50 {
    background-color: $c_groengrijs-50 !important;
  }

  &.c-200 {
    background-color: $c_groengrijs-200 !important;
  }
}

.bgc-theme {
  &.c-100 {
    background-color: $c_theme-100;
  }

  &.c-200 {
    background-color: $c_theme-200;
  }

  &.c-300 {
    background-color: $c_theme-300;
  }

  &.c-400 {
    background-color: $c_theme-400;
  }

  &.c-500 {
    background-color: $c_theme-500;
  }

  &.c-600 {
    background-color: $c_theme-600;
  }

  &.c-700 {
    background-color: $c_theme-700;
  }
}

.bc-groen {
  border-color: $c_groen !important;

  &.c-50 {
    border-color: $c_groen-50 !important;
  }

  &.c-200 {
    border-color: $c_groen-200 !important;
  }
}

.bc-turkoois {
  border-color: $c_turkoois !important;

  &.c-50 {
    border-color: $c_turkoois-50 !important;
  }

  &.c-200 {
    border-color: $c_turkoois-200 !important;
  }
}

.bc-paars {
  border-color: $c_paars !important;

  &.c-50 {
    border-color: $c_paars-50 !important;
  }

  &.c-200 {
    border-color: $c_paars-200 !important;
  }
}

.bc-magenta {
  border-color: $c_magenta !important;

  &.c-50 {
    border-color: $c_magenta-50 !important;
  }

  &.c-200 {
    border-color: $c_magenta-200 !important;
  }
}

.bc-blauw {
  border-color: $c_blauw !important;

  &.c-50 {
    border-color: $c_blauw-50 !important;
  }

  &.c-200 {
    border-color: $c_blauw-200 !important;
  }
}

.bc-koraalrood {
  border-color: $c_koraalrood !important;

  &.c-50 {
    border-color: $c_koraalrood-50 !important;
  }

  &.c-200 {
    border-color: $c_koraalrood-200 !important;
  }
}

.bc-okergeel {
  border-color: $c_okergeel !important;

  &.c-50 {
    border-color: $c_okergeel-50 !important;
  }

  &.c-200 {
    border-color: $c_okergeel-200 !important;
  }
}

.bc-groengrijs {
  border-color: $c_groengrijs !important;

  &.c-50 {
    border-color: $c_groengrijs-50 !important;
  }

  &.c-200 {
    border-color: $c_groengrijs-200 !important;
  }
}

.bc-theme {
  &.c-100 {
    border-color: $c_theme-100;
  }

  &.c-200 {
    border-color: $c_theme-200;
  }

  &.c-300 {
    border-color: $c_theme-300;
  }

  &.c-400 {
    border-color: $c_theme-400;
  }

  &.c-500 {
    border-color: $c_theme-500;
  }

  &.c-600 {
    border-color: $c_theme-600;
  }

  &.c-700 {
    border-color: $c_theme-700;
  }
}

.c-error {
  color: $c_error;
}

.bgc-error {
  background-color: $c_error;
}

.c-success {
  color: $c_success;
}

.bgc-success {
  background-color: $c_success;
}

.c-body {
  color: $c_body;
}

.c-groen {
  color: $c_groen !important;

  &.c-50 {
    color: $c_groen-50 !important;
  }

  &.c-200 {
    color: $c_groen-200 !important;
  }
}

.c-turkoois {
  color: $c_turkoois !important;

  &.c-50 {
    color: $c_turkoois-50 !important;
  }

  &.c-200 {
    color: $c_turkoois-200 !important;
  }
}

.c-paars {
  color: $c_paars !important;

  &.c-50 {
    color: $c_paars-50 !important;
  }

  &.c-200 {
    color: $c_paars-200 !important;
  }
}

.c-magenta {
  color: $c_magenta !important;

  &.c-50 {
    color: $c_magenta-50 !important;
  }

  &.c-200 {
    color: $c_magenta-200 !important;
  }
}

.c-blauw {
  color: $c_blauw !important;

  &.c-50 {
    color: $c_blauw-50 !important;
  }

  &.c-200 {
    color: $c_blauw-200 !important;
  }
}

.c-koraalrood {
  color: $c_koraalrood !important;

  &.c-50 {
    color: $c_koraalrood-50 !important;
  }

  &.c-200 {
    color: $c_koraalrood-200 !important;
  }
}

.c-okergeel {
  color: $c_okergeel !important;

  &.c-50 {
    color: $c_okergeel-50 !important;
  }

  &.c-200 {
    color: $c_okergeel-200 !important;
  }
}

.c-groengrijs {
  color: $c_groengrijs !important;

  &.c-50 {
    color: $c_groengrijs-50 !important;
  }

  &.c-200 {
    color: $c_groengrijs-200 !important;
  }
}

@mixin btncta($color) {
  border-radius: 20px;
  background-color: $color;
  color: $c_theme-100;
  border-color: $color;

  &:hover {
    background-color: $c_theme-100;
    color: $c_body;
    border-color: $c_theme-300;
  }

  &:active,
  &.active,
  &:focus {
    border-color: $c_theme-300;
    border-width: 1px;
    padding: 12px;
    background-color: $color;
    color: $c_theme_100;
  }

  &:disabled,
  &.disabled {
    border-color: $c_light_disabled;
    background-color: $c_light_disabled;
  }

  &.btn-lg {
    border-radius: 24px;

    &:active,
    &.active,
    &:focus {
      border-color: $c_theme-300;
      border-width: 1px;
      padding: 16px 20px;
      background-color: $color;
    }
  }
}

.fc-button-group > .fc-button:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.fc-button-group > .fc-button:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.fc-button {
  .fc-icon {
    height: 0.7em;
    line-height: 0.7;
  }

  font-size: 14px !important;
  text-transform: uppercase !important;
  font-weight: 500 !important;
  line-height: 1 !important;
  padding: 8px !important;
  border: 1px solid $c_body !important;
  border-radius: $b_radius !important;

  &:focus {
    box-shadow: none !important;
    outline: none !important;
    border-width: 2px !important;
    padding: 7px !important;
  }

  background-color: $c_theme-100 !important;
  color: $c_body !important;

  .hide-light {
    display: none !important;
  }

  &:hover,
  &.active {
    background-color: $c_theme-700 !important;
    color: $c_theme-100 !important;

    .hide-dark {
      display: none !important;
    }

    .hide-light {
      display: initial !important;
    }
  }

  &:active {
    color: $c_body !important;
    background-color: $c_theme-200 !important;
  }

  &:disabled,
  &.disabled {
    border-color: $c_light_disabled !important;
    color: $c_light_disabled !important;
  }
}

.btn {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1;
  padding: 12px;
  border: 1px solid $c_body;
  border-radius: $b_radius;

  &:focus {
    box-shadow: none;
    outline: none;
    border-width: 2px;
    padding: 11px;
  }

  &.btn-lg {
    padding: 16px 20px;

    &:focus {
      padding: 15px 19px;
    }
  }

  &.btn-sm {
    padding: 8px;

    &:focus {
      padding: 7px;
    }
  }

  &.btn-cta {
    @include btncta($c_success);

    &.btn-cta-groen {
      @include btncta($c_groen);
    }

    &.btn-cta-turkoois {
      @include btncta($c_turkoois);
    }

    &.btn-cta-paars {
      @include btncta($c_paars);
    }

    &.btn-cta-magenta {
      @include btncta($c_magenta);
    }

    &.btn-cta-blauw {
      @include btncta($c_blauw);
    }

    &.btn-cta-koraalrood {
      @include btncta($c_koraalrood);
    }

    &.btn-cta-okergeel {
      @include btncta($c_okergeel);
    }

    &.btn-cta-groengrijs {
      @include btncta($c_groengrijs);
    }
  }

  &.btn-primary {
    border-color: $c_theme-300;
    background-color: $c_theme-700;

    .hide-dark {
      display: none;
    }

    &:active {
      background-color: $c_light_enabled;
      border-color: $c_light_enabled;
    }

    &:hover,
    &.active {
      background-color: $c_theme-100;
      color: $c_body;

      .hide-dark {
        display: initial;
      }

      .hide-light {
        display: none;
      }
    }

    &:focus {
      box-shadow: none;
      outline: none;

      color: $c_theme-100;
      background-color: $c_theme-600;
    }

    &:disabled,
    &.disabled {
      border-color: $c_light_disabled;
      background-color: $c_light_disabled;
    }
  }

  &.btn-secondary {
    background-color: $c_theme-100;
    color: $c_body;

    .hide-light {
      display: none;
    }

    &:hover,
    &.active {
      background-color: $c_theme-700;
      color: $c_theme-100;

      .hide-dark {
        display: none;
      }

      .hide-light {
        display: initial;
      }
    }

    &:active {
      color: $c_body;
      background-color: $c_theme-200;
    }

    &:disabled,
    &.disabled {
      border-color: $c_light_disabled;
      color: $c_light_disabled;
    }
  }

  &.btn-link {
    border-color: transparent;
    text-decoration: none;
    color: $c_body;
    font-weight: 500;

    &:hover {
      background-color: $c_theme-200;
    }

    &:disabled,
    &.disabled {
      color: $c_light_disabled;
    }

    &.p-0:focus,
    &.p-0 {
      padding: 0 !important;
      border: 0 !important;
      border-width: 0 !important;
    }
  }

  &.btn-xs {
    font-size: 12px;
    padding: 3px;
    border-radius: 12px;

    &:focus {
      border-width: 1px;
      padding: 3px;
    }
  }
}

.list-group-item {
  color: inherit;
  border-color: $c_theme-300;
}

.checkup-question-options {
  .list-group-item {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

html {
  font-size: 15px;
}

body {
  font-family: $font-body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $c_body;
}

h1,
h2,
.h1,
.h2 {
  font-family: $font-head;
}

h3,
h4,
h5,
.h3,
.h4,
.h5 {
  font-weight: 500;
}

h1,
.h1 {
  font-weight: 300;
  font-size: 54px;
}

h2,
.h2 {
  font-weight: 400;
  font-size: 35px;
}

h3,
.h3 {
  font-size: 25px;
}

h4,
.h4 {
  font-size: 20px;
}

h5,
.h5 {
  font-size: 16px;
}

label {
  @extend .text-sm;
  display: block;
  margin-bottom: 4px;
}

select,
textarea,
input[type='text'],
input[type='password'],
input[type='number'],
input[type='email'],
input[type='date'],
input[type='datetime-local'] {
  display: block;
  padding: 0px 12px;
  border: 1px solid $c_theme-600;
  border-radius: 10px;

  &:focus,
  &:focus-visible {
    outline: none;
  }
}

textarea {
  background-color: #f7f7f7;
  border: 0;
  padding: 1rem;
  margin-bottom: 1rem;
}

select,
input[type='text'],
input[type='password'],
input[type='number'],
input[type='date'],
input[type='datetime-local'] {
  height: 32px;

  &.input-lg {
    height: 42px;
  }
}

.search-bar {
  position: relative;
  background: #fff;
  border-radius: 10px;

  input {
    background: transparent;
    z-index: 1;
    transition: all 0.3s;
    width: 42px;
    position: relative;
  }

  &.active {
    input {
      width: 200px;
    }
  }

  .search-icon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.text-disabled {
  color: $c_light_disabled;
}

.text-lg {
  font-size: 120%;
}

.text-md {
  font-size: 1rem;
}

.text-sm {
  font-size: 80%;
}

caption {
  font-size: 12px;
}

.overline {
  text-transform: uppercase;
}

main {
  background-color: $c_light_bg;
}

.boxshadow {
  box-shadow: 0px 3px 6px #29282c40 !important;
}

.boxshadow-0 {
  box-shadow: none !important;
}

.chart-score {
  &.size-lg {
    .progress-score {
      font-size: 40px;
    }
  }

  &.size-md {
    .progress-score {
      font-size: 24px;
    }
  }

  &.size-sm {
    .progress-score {
      font-size: 20px;
    }
  }
}

.progress-score {
  line-height: 1;
  font-family: $font-pct;
  font-weight: 700;
}

.progress-pct {
  line-height: 1;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-start-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.input-xl {
  font-size: 20px !important;
  font-weight: 500 !important;
  border: 0 !important;
  padding: 0 !important;
}

.p-25 {
  padding: 25px !important;
}

/*.card-nav-main {
  .nav-pills {
    > li {
      position: static;
    }
  }

  ul.dropdown-menu {
    @extend .boxshadow;
    width: 100%;
    margin: 0;
    border: 0;
    border-top: 1px solid $c_theme-200;

    .dropdown-item {
      &:hover {
        @extend .c-200;
        color: #fff;
      }

      padding-top: 9px;
      padding-bottom: 9px;
      padding-left: 40px;
    }

    padding: 0;
    margin-top: -2px !important;
    border-radius: 0;
  }
}*/

/*.card {
  .card-header {
    .card-header-nav-btn {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    display: flex;
    align-items: center;
    height: 40px;
    border: 0;
    //background-color: $c_theme-300;

    .title {
      line-height: 1;
    }
  }

  .card-body {
    padding: 25px;
  }

  @extend .boxshadow;
  border: 0;
  border-radius: 0px 0px 16px 16px;

  &.card-rounded {
    border-radius: 16px;
  }
}*/

.modal-backdrop {
  background-color: #b1b1b1;

  &.show {
    opacity: 0.7;
  }
}

.modal-header {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  height: 40px;
}

.modal-body {
  padding: 25px;
}

.modal {
  .modal-content {
    border-radius: 16px;
    border: 0;
    box-shadow: 0 6px 12px rgba(41, 41, 44, 0.6);
  }
}

a.nav-item {
  display: flex;
  white-space: nowrap;
  overflow: hidden;

  .hide-collapse {
    display: inline;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: all 0.4s;
  }
}

.can-collapse-vert {
  transition: 0.4s all;

  &.collapsed {
    flex-basis: 64px;
    width: 64px;

    .hide-collapse {
      width: 0 !important;
      //font-size: 0;
      opacity: 0;
      transform-origin: left;
      overflow: hidden;
      visibility: hidden;
    }

    .show-collapse {
      display: block;
    }
  }
}

%list-item {
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $c_theme-800;
    border: 2px solid $c_theme-800;
    position: absolute;
    left: -16px;
    top: 24px;
    transform: translateY(-50%);
  }
}

li.team-detail {
  > .team-content {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  @extend %list-item;
}

.branch-list {
  list-style-type: none;
  //list-style-image: url(./assets/icons/Tree-branch-closed-dot.svg);

  li {
    @extend %list-item;
  }

  &.active-branch {
    //list-style-image: url(./assets/icons/Tree-starting-point.svg);

    li {
      &:after {
        background-color: $c_theme-800;
        border-color: $c_theme-800;
      }
    }
  }
}

.company.branch-list {
  > li {
    > .tree-ul {
      > li {
        &:last-of-type.opened {
          &:before {
            height: 50% !important;
          }
        }
      }
    }

    //&:last-of-type:before{
    //  content: none !important;
    //}
  }
}

#main-wrapper {
  display: flex;
}

main.main-content-wrapper {
  max-height: 100vh;
  overflow-y: scroll;
  @media all and (max-width: 767px) {
    padding-top: calc(53px + 25px);
  }
}

.h-48 {
  height: 48px;
}

ul.tree-ul {
  @extend .branch-list;
  position: relative;

  li {
    &:before {
      width: 1px;
      height: calc(100% - 0px);
      background-color: #3d4152;
      content: '';
      display: block;
      position: absolute;
      left: -42px;
      top: -23px;
    }

    @extend %list-item;

    &.active-list-item {
      &:after {
        background-color: #fff;
        border-color: $c_okergeel-200;
      }

      //list-style-image: url(./assets/icons/Tree-starting-point.svg);

      > .team-content {
        background-color: #f2f2f5;
      }
    }
  }

  .team-row {
    &.active {
      > .team-content {
        background-color: #f2f2f5;
      }
    }

    &.team {
      > .team-content {
        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 26px;
          height: 1px;
          background-color: #3d4152;
          left: -41px;
          top: 50%;
          transition: translateY(-50%);
        }
      }
    }

    > .team-content {
      padding-top: 7px;
      padding-bottom: 7px;
      align-items: center;

      &:hover,
      &.active {
        background-color: #f2f2f5;
      }

      position: relative;
    }

    position: relative;

    //&:before {
    //  //content: '';
    //  display: block;
    //  position: absolute;
    //  width: 20px;
    //  height: 2px;
    //  background-color: blue;
    //  left: -32px;
    //  top: 50%;
    //  transition: translateY(-50%);
    //}
  }

  &.no-tree {
    padding-left: 0;

    li:before,
    li:after,
    .team-content,
    .team-row.team > .team-content:before {
      display: none;
    }
  }
}

.team-tree {
  .user .team-name {
    padding-left: 6px;
  }

  .user .branch-list.active-branch li:after,
  ul.active-branch.tree-ul li:after,
  .user li.team-detail:after,
  .branch-list li:after,
  ul.tree-ul li:after {
    background-image: url(assets/icons/Icon-sidebar-Profile.svg);
    background-color: transparent;
    border: 0;
    width: 12px;
    height: 12px;
    border-radius: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.tree-action {
  width: 40px;
  text-align: center;
  padding: 4px 8px;

  @media all and (max-width: 767px) {
    padding-left: 0;
    padding-right: 4px;
  }

  &:first-child {
    text-align: left;
    //width: 55px;
    width: 7ch;
  }

  &:last-child {
    text-align: left;
    //width: 55px;
    width: 7ch;
  }
}

//
//.main-nav-wrapper{
//  position: fixed;
//  height: 100vh;
//}

.main-navigation {
  flex-basis: 168px;
  width: 168px;
  padding-left: 16px;
  padding-right: 16px;
  flex-shrink: 0;
  //max-height: 100vh;
}

.show-collapse {
  display: none;
}

.help-sidebar {
  display: flex;
  flex-basis: 320px;
  padding-left: 16px;
  padding-right: 16px;
  width: 192px;
  @media all and (max-width: 767px) {
    display: none;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

.bg-secondary {
  background-color: $c_theme-800 !important;
}

.nav-item {
  position: relative;
  padding: 5px;
  //white-space: nowrap;

  &.router-link-active {
    background-color: $c_theme-200;
    border-radius: 10px;
  }

  .icon {
    width: 22px;
    text-align: center;
    display: inline-block;
  }

  .badge {
    width: 16px;
    font-size: 12px;
    font-family: $font-pct;

    position: absolute;
    right: -24px;
    padding: 2px 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
  }
}

.top-nav {
  padding-top: 15px;
  padding-bottom: 16px;
  //border-bottom: 1px solid $c_theme-300;
  margin-bottom: 25px;
}

.main-content-wrapper {
  padding-left: 25px;
  padding-right: 25px;
  min-height: 100vh;
}

.tip {
  position: relative;

  &:hover {
    .tip-content {
      display: block;
    }
  }

  .tip-content {
    min-width: 175px;
    padding: 8px;
    position: absolute;
    bottom: calc(100% + 4px);
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 8px;
    display: none;
    @extend .boxshadow;

    &:after {
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #fff;
      content: '';
      bottom: calc(100% + 4px);
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
      display: block;
      position: absolute;
    }
  }
}

.list-group-flush {
  .list-group-item {
    &.active {
      background-color: $c_theme-200;
      border-color: $c_theme-300;
      color: $c_body;
    }
  }
}

.mobile-menu {
  z-index: 100 !important;
}

@media (max-width: 768px) {
  #lang-selector {
    display: flex !important;
  }
}

// FADE TRANSITION
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease !important;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.table-analytics {
  margin-bottom: 0;
}

.no-hover {
  &:hover {
    background-color: inherit !important;
  }
}

.legende {
  font-size: 11px;
}

@media (max-height: 800px) {
  .logo-wrapper {
    display: none !important;
  }
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.modal-content {
  width: 100%;
}

@include media-breakpoint-down(xl) {
  .modal-content {
    width: 90%;
    margin: 40px auto 0;
  }
}
</style>
