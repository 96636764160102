<template>
  <div class="card">
    <div
      v-if="hasHeader"
      ref="header"
      :class="[
        'card-header',
        color ? `bg-${color} bgc-${color.split('--')[0]}` : '',
        colorShade ? `c-${colorShade}` : '',
        headerPadding ? `p-${headerPadding}` : '',
      ]"
      :style="[`color: ${headerColor}`]"
    >
      <slot name="header" />
    </div>
    <div
      class="card-body bg-white"
      :class="`p-${padding}`"
      style="
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      "
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { getContrastColor, getColor } from '../../../helpers/color';

/**
 * Card component. Has color and shade for header and slots for header content and main content
 */
export default {
  name: 'Card',
  props: {
    color: {
      type: String,
      default: 'theme--300',
    },
    colorShade: {
      type: [Number, String],
      default: '',
    },
    padding: {
      type: [Number, String],
      default: 4,
    },
    headerPadding: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      headerColor: 'text-white',
    };
  },
  computed: {
    hasHeader() {
      return !!this.$slots.header;
    },
  },
  watch: {
    color() {
      this.getHeaderTextColor();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getHeaderTextColor();
    });
  },
  methods: {
    getHeaderTextColor() {
      if (this.$refs.header) {
        this.headerColor = getContrastColor(getColor(this.color))
          .display()
          .toString();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
