<template>
  <Card :color="`${activeType.color}--dark`">
    <template #header>
      <span class="overline text-sm">{{ $t(`messages.title`) }}</span>
    </template>
    <!--    <message-list
      :type="activeType.type"
      @changeType="handleChangeType"
    />-->
    <MessageList
      :swarms="[
        { id: 0 },
        ...(user?.swarms || []),
        ...(user?.member || []),
      ]"
    />
  </Card>
</template>

<script>
import Card from '@/views/components/simple/Card';
import MessageList from '@/components/messages/MessageList';
import { mapState } from 'pinia';
import { useAuthStore } from '../stores/auth';

export default {
  name: 'MessagesCard',
  components: { MessageList, Card },
  data() {
    return {
      types: [
        {
          type: 'team',
          color: 'blue',
        },
        {
          type: 'company',
          color: 'orange-yellow',
        },
      ],
      activeTypeIndex: 0,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['user']),
    activeType() {
      return this.types[this.activeTypeIndex];
    },
  },
  methods: {
    handleChangeType(type) {
      this.activeTypeIndex = this.types.findIndex(
        (item) => item.type.toLowerCase() === type.toLowerCase()
      );
    },
  },
};
</script>

<style scoped></style>
