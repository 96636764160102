import Color from 'colorjs.io';
import colorMap from '@/assets/scss/utils/_variables.scss';
import { get } from 'lodash';

const colors = JSON.parse(colorMap.colors?.slice(1, -1) || '{}');

/**
 * Array of pickable colors
 * Used in team/checkup color picker and charts
 * @type {[string, unknown]}
 */
const pickableColors = Object.entries(colors).reduce(
  (acc, [key, value]) => {
    if (
      typeof value !== 'string' &&
      !['vitamins', 'theme'].includes(key)
    ) {
      acc.push({ key, value: value.base });
    }
    return acc;
  },
  []
);

/**
 * All colors used on scale charts
 * @type {string[]}
 */
const scaleColors = ['great', 'good', 'average', 'poor', 'bad'];
/**
 * All colors related to user clusters
 * @type {string[]}
 */
const clusterColors = [
  'coral',
  'orange--dark',
  'orange-yellow--dark',
  'green',
  'blue',
];

/**
 * Returns a contrast color for text on backgrounds according to APCA contrast algorithm
 * @param bgColor - background color
 * @returns {Color} - White/Black depending on biggest contrast
 */
function getContrastColor(bgColor) {
  const background = new Color(bgColor);
  const black = new Color(getColor('theme--800'));
  const white = new Color(getColor('theme--100'));

  const onBlack = Math.abs(Color.contrastAPCA(background, black));
  const onWhite = Math.abs(Color.contrastAPCA(background, white));

  return onWhite > onBlack ? white : black;
}

// Factor modifiers for color scale
const colorModifiers = {
  NEG: [
    'HIN',
    'ONG',
    'EMO',
    'ONZ',
    'ROA',
    'ROC',
    'BUR',
    'CON',
    'POL',
    'EXH',
    'PIE',
    'SLA',
    'CYN',
  ],
  MID: ['UIT', 'WD', 'COG', 'COM'],
};

// Map of old colors to new colors
const oldColorMap = {
  'groen-50': 'green--light',
  groen: 'green',
  'groen-200': 'green--dark',
  turkoois: 'aqua',
  paars: 'purple',
  koraalrood: 'coral',
  okergeel: 'orange-yellow',
  groengrijs: 'cambridge-blue',
  blauw: 'blue',
  'blauw-50': 'blue--light',
  'blauw-200': 'blue--dark',
};

/**
 * Retuns a color based on the color name
 * @param name - name of the color
 * @param display - if true, returns a hex value, else returns a color object
 * @param opacity - desired opacity of the color
 * @returns {string|Color} - hex string or color object
 */
function getColor(name, display = true, opacity = 1) {
  // Map old colors to new color names (if needed)
  if (!(name.split('--')[0] in colors)) {
    // console.warn(
    //   `Color ${name} not found, please update to the new color palette`
    // );

    const nameSuffix = name.split('--')[1]
      ? `--${name.split('--')[1]}`
      : '';

    name = oldColorMap[name.split('--')[0]] + nameSuffix;
  }

  // Set the variant of the color
  const variant = (
    name.lastIndexOf('--') > 0 ? name.split('--')[1] : 'base'
  ).trim();

  // Get new name of the color
  const colorName = name.slice(
    0,
    name.lastIndexOf('--') > 0 ? name.lastIndexOf('--') : name.length
  );

  let colorValue;
  const colorType = get(colors, [colorName]);
  if (typeof colorType === 'string') {
    colorValue = colorType;
  } else {
    colorValue = colorType[variant.trim() || 'base'];
  }

  const color = new Color(colorValue);
  if (opacity) {
    color.alpha = opacity;
  }

  return display
    ? color.display({ format: 'hex' }).toString()
    : color;
}

/**
 * Returns a color according to score
 * @param score - score to get color for
 * @param type - type of score being used (default scale or standard deviation)
 * @param key - factor key for color modifiers
 * @returns {string} - hex color string
 */
function getScoreColor(score, type, key = '') {
  let color = 'bad';
  if (type === 'sd') {
    if (score <= 15) {
      color = 'great';
    } else if (score <= 25) {
      color = 'average';
    } else {
      color = 'bad';
    }

    return getColor(color);
  }
  if (key) {
    if (colorModifiers.NEG.includes(key)) {
      score = 100 - score;
    }
  }

  if (colorModifiers.MID.includes(key)) {
    if (score < 5 || score > 95) {
      color = 'bad';
    } else if (score < 15 || score > 85) {
      color = 'average';
    } else {
      color = 'great';
    }
  } else {
    if (score > 75) {
      color = 'great';
    } else if (score >= 25) {
      color = 'average';
    } else {
      color = 'bad';
    }
  }
  return getColor(color);
}

export {
  getScoreColor,
  getColor,
  getContrastColor,
  pickableColors,
  oldColorMap,
  scaleColors,
  clusterColors,
};
