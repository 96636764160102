<template>
  <div
    v-if="
      $route.name !== 'login' &&
      authStore &&
      authStore.isAuthenticated &&
      authStore.user
    "
    class="position-fixed w-100 bg-white mobile-menu d-md-none"
  >
    <nav class="navbar navbar-expand-md">
      <div class="container-fluid">
        <nav
          id="lang-selector"
          :class="
            !authStore.user || !authStore.user.id ? 'flex-grow-1' : ''
          "
          class="justify-content-end top-menu d-none"
        >
          <select
            v-model="$i18n.locale"
            class="btn btn-xs bg-white"
            @change="handleChangeLocale"
          >
            <option
              v-for="locale in $i18n.availableLocales"
              :key="`locale-${locale}`"
              :value="locale"
            >
              {{ locale.split('-')[0] }}
            </option>
          </select>
        </nav>
        <img
          height="20"
          class="d-inline"
          src="@/assets/images/vitamins.svg"
          alt=""
        />

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon">
            <img
              src="@/assets/icons/icon-navigation-menu.svg"
              alt=""
            />
          </span>
        </button>
        <div
          id="navbarSupportedContent"
          class="collapse navbar-collapse"
        >
          <ul class="user-nav navbar-nav me-auto mb-2 mb-lg-0">
            <router-link
              v-if="authStore.user && authStore.hasDashboard"
              class="nav-item mb-2"
              to="/"
              @click="closeMenu"
            >
              <span class="icon">
                <img
                  src="@/assets/icons/Icon-sidebar-dashboard.svg"
                  alt=""
                />
              </span>
              &nbsp;<span class="text-sentence">{{
                $t('message.dashboard')
              }}</span>
            </router-link>

            <router-link
              class="nav-item mb-2"
              to="/vitamins"
              @click="closeMenu"
            >
              <span class="icon">
                <img
                  src="@/assets/icons/Icon-sidebar-vitamins.svg"
                  alt=""
                />
              </span>
              &nbsp;<span class="hide-collapse text-sentence">{{
                $t('message.vitamins')
              }}</span>
              <span
                v-if="activeVitamins > 0"
                class="badge bg-secondary rounded-circle"
                >{{ activeVitamins }}</span
              >
            </router-link>
            <router-link
              class="nav-item mb-2"
              to="/actions"
              @click="closeMenu"
            >
              <span class="icon">
                <img
                  src="@/assets/icons/Icon-sidebar-actions.svg"
                  alt=""
                />
              </span>
              &nbsp;<span class="hide-collapse text-sentence">{{
                $t('message.actions')
              }}</span>
            </router-link>
            <router-link
              class="nav-item"
              to="/definitions"
            >
              <span class="icon">
                <i class="fas fa-fw fa-books c-groen c-200" />
              </span>
              <span class="hide-collapse"
                >&nbsp;<span class="text-sentence">{{
                  $t('message.definitions')
                }}</span></span
              >
            </router-link>
          </ul>
          <ul class="user-nav navbar-nav me-auto mb-2 mb-lg-0">
            <router-link
              class="nav-item mb-2"
              to="/profile"
              @click="closeMenu"
            >
              <span class="icon">
                <img
                  src="@/assets/icons/Icon-sidebar-Profile.svg"
                  alt=""
                />
              </span>
              &nbsp;<span class="hide-collapse text-sentence">{{
                $t('message.profile')
              }}</span>
            </router-link>
            <router-link
              v-if="authStore.permission(['manageschedule'])"
              class="nav-item mb-2"
              to="/schedule"
              @click="closeMenu"
            >
              <span class="icon">
                <img
                  src="@/assets/icons/Icon-sidebar-schedule.svg"
                  alt=""
                />
              </span>
              &nbsp;<span class="hide-collapse text-sentence">{{
                $t('message.schedule')
              }}</span>
            </router-link>
            <router-link
              v-if="authStore.permission(['ismanager'])"
              class="nav-item mb-2"
              to="/checkups"
              @click="closeMenu"
            >
              <span class="icon">
                <img
                  src="@/assets/icons/icon-sidebar-checkups.svg"
                  alt=""
                />
              </span>
              &nbsp;<span class="hide-collapse text-sentence">{{
                $t('message.checkups')
              }}</span>
            </router-link>

            <router-link
              v-if="
                authStore.permission(['managefeedback', 'ismanager'])
              "
              class="nav-item mb-2"
              to="/feedback"
              @click="closeMenu"
            >
              <span class="icon">
                <i class="fas fa-comments-alt c-groengrijs" />
              </span>
              &nbsp;<span class="hide-collapse text-sentence">{{
                $t('message.feedback')
              }}</span>
            </router-link>
            <router-link
              v-if="
                authStore.permission([
                  'ismanager',
                  'issegmentmanager',
                  'manageteams',
                ])
              "
              class="nav-item mb-2"
              to="/tree"
              @click="closeMenu"
            >
              <span class="icon">
                <img
                  src="@/assets/icons/Icon-sidebar-tree.svg"
                  alt=""
                />
              </span>
              &nbsp;<span class="hide-collapse text-sentence">{{
                $t('message.tree')
              }}</span>
            </router-link>
            <router-link
              v-if="authStore.permission(['managesegments'])"
              class="nav-item mb-2"
              to="/segments"
              @click="closeMenu"
            >
              <span class="icon">
                <img
                  src="@/assets/icons/Icon-sidebar-segments.svg"
                  alt=""
                />
              </span>
              &nbsp;<span class="hide-collapse text-sentence">{{
                $t('message.segments')
              }}</span>
            </router-link>
            <router-link
              v-if="authStore.permission(['manageanalytics'])"
              class="nav-item mb-2"
              to="/analytics"
              @click="closeMenu"
            >
              <span class="icon">
                <img
                  src="@/assets/icons/Icon-sidebar-heat.svg"
                  alt=""
                />
              </span>
              &nbsp;<span class="hide-collapse text-sentence">{{
                $t('message.analytics')
              }}</span>
            </router-link>

            <router-link
              v-if="authStore.permission(['manageusers'])"
              class="nav-item mb-2"
              to="/users"
              @click="closeMenu"
            >
              <span class="icon">
                <img
                  src="@/assets/icons/Icon-sidebar-Users.svg"
                  alt=""
                />
              </span>
              &nbsp;<span class="hide-collapse text-sentence">{{
                $t('message.users')
              }}</span>
            </router-link>
            <router-link
              v-if="authStore.permission(['managesettings'])"
              class="nav-item"
              to="/settings"
              @click="closeMenu"
            >
              <span class="icon">
                <img
                  src="@/assets/icons/Icon-sidebar-Settings.svg"
                  alt=""
                />
              </span>
              &nbsp;<span class="hide-collapse text-sentence">{{
                $t('message.settings')
              }}</span>
            </router-link>
          </ul>
          <ul class="user-nav navbar-nav me-auto mb-2 mb-lg-0">
            <a
              href="#"
              class="nav-item"
              @click.prevent.stop="logout"
            >
              <img
                src="@/assets/icons/Icon-sidebar-log-out.svg"
                alt=""
              />
              &nbsp;<span class="hide-collapse text-sentence">{{
                $t('message.logout')
              }}</span>
            </a>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { mapStores } from 'pinia';
import { useAuthStore } from '@/stores/auth';

export default {
  computed: {
    ...mapStores(useAuthStore),
    activeVitamins() {
      return /*this.$store.getters.getActiveVitaminsCount ||*/ 0;
    },
  },
  methods: {
    logout() {
      this.authStore.logout();
      this.$router.replace('/login');
    },
    handleChangeLocale() {
      localStorage.setItem('lang', this.$i18n.locale);
      if (this.user) {
        this.axios.put('/customuser/' + this.user.id, {
          language: this.$i18n.locale,
        });
      }
      this.mitt.emit('langChanged');
    },
    closeMenu() {
      let nav = document.getElementById('navbarSupportedContent');
      if (nav.classList.contains('show')) {
        nav.classList.remove('show');
      }
    },
  },
};
</script>
