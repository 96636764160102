<script setup>
import ScaleChart from '../../../components/ScaleChart/ScaleChart';
import { getColor, getContrastColor } from '../../../helpers/color';
import {
  computed,
  inject,
  nextTick,
  onMounted,
  provide,
  ref,
} from 'vue';
// import { useAsyncState } from '@vueuse/core';

// import { useI18n } from 'vue-i18n';
import PersonalFeedback from '../../../components/PersonalFeedback/PersonalFeedback';
import { Tooltip } from 'bootstrap';

provide('hasTooltip', true);
const setOpen = inject('setOpen');

const props = defineProps({
  factor: {
    type: Object,
    required: true,
  },
  type: {
    type: String,
    required: true,
    validator(value) {
      return ['user', 'swarm', 'radar'].includes(value);
    },
  },
});

/*const axios = inject('axios');
const { state: response, execute } = useAsyncState(
  () =>
    axios
      .request({
        url: `/feedbacks/response/${props.factor.key}`,
      })
      .then((res) => res.data),
  null
);*/

const color = computed(() => {
  const score = Math.round(props.factor.score);
  if (
    ['UIT', 'WD', 'COG', 'COM'].includes(props.factor?.type) ||
    ['UIT', 'WD', 'COG', 'COM'].includes(props.factor.key)
  ) {
    if (score < 5 || score > 95) {
      return 'bad';
    } else if (score < 15 || score > 85) {
      return 'average';
    } else {
      return 'great';
    }
  }

  if (
    props.factor?.type === 'NEG' ||
    [
      'HIN',
      'EMO',
      'ONZ',
      'ROA',
      'ROC',
      'BUR',
      'CON',
      'POL',
      'ONG',
      'EXH',
      'PIE',
      'SLA',
      'CYN',
    ].includes(props.factor.key)
  ) {
    if (score > 75) {
      return 'bad';
    } else if (score < 25) {
      return 'great';
    } else {
      return 'average';
    }
  }

  if (score > 75) {
    return 'great';
  } else if (score < 25) {
    return 'bad';
  } else {
    return 'average';
  }
});

// const { te } = useI18n();
const advice = computed(() => {
  const [usertype, datatype] = props.type.split('_');
  const score = Math.round(props.factor.score);
  let type = {
    25: 'low',
    75: 'average',
    100: 'high',
  };
  if (
    usertype === 'user' &&
    ['EB', 'HIN', 'PHB', 'IM', 'ATT', 'PRES', 'ONG'].includes(
      props.factor.key
    )
  ) {
    type = {
      2.5: 'very_low',
      16: 'low',
      50: 'below_average',
      84: 'above_average',
      98: 'high',
      100: 'very_high',
    };
  }
  if (['UIT', 'WD', 'COG', 'COM'].includes(props.factor.key)) {
    type = {
      5: 'very_low',
      15: 'low',
      85: 'average',
      95: 'high',
      100: 'very_high',
    };
  }

  if (['ENG', 'WB'].includes(props.factor.key)) {
    return props.factor.body;
  }

  // get next higher score from type
  const nextScore = Object.keys(type).find((key) => key >= score);
  return `personal_feedback.${usertype}${
    datatype ? `.${datatype}` : ''
  }.${props.factor.key}.${
    type[nextScore]
  }` /*`personal_feedback.${props.type}.${props.factor.key}.negative`*/;
});

const scale = computed(() => {
  if (
    ['UIT', 'WD', 'COG', 'COM'].includes(props.factor?.type) ||
    ['UIT', 'WD', 'COG', 'COM'].includes(props.factor.key)
  ) {
    return {
      5: 'bad',
      15: 'average',
      85: 'good',
      95: 'average',
      100: 'bad',
    };
  }

  if (
    props.factor?.type === 'NEG' ||
    [
      'HIN',
      'EMO',
      'ONZ',
      'ROA',
      'ROC',
      'BUR',
      'CON',
      'POL',
      'ONG',
      'EXH',
      'PIE',
      'SLA',
      'CYN',
    ].includes(props.factor.key)
  ) {
    return { 25: 'great', 75: 'average', 100: 'bad' };
  }

  return { 25: 'bad', 75: 'average', 100: 'great' };
});

const titleTooltip = ref(null);
onMounted(() => {
  nextTick(() => {
    const tooltip = new Tooltip(titleTooltip.value, {
      placement: 'right',
    });
  });
});

/*const sendFeedbackResponse = async (response) => {
  await axios.request({
    url: `/feedbacks/response`,
    method: 'POST',
    data: {
      key: props.factor.key,
      response,
    },
  });
  execute();
};*/
</script>

<template>
  <div class="modal-content">
    <div
      class="modal-header"
      :class="'bg-' + color"
    >
      <div
        class="c-200 text-sm modal-title w-100 flex-grow-1 text-sentence"
      >
        <span
          class="text_dash overline"
          :style="[`color: ${getContrastColor(getColor(color))}`]"
          >{{ factor.title }}</span
        >
      </div>
      <button
        type="button"
        class="d-block ms-auto btn-close"
        aria-label="Close"
        @click="setOpen(false)"
      />
    </div>
    <div class="modal-body">
      <div
        class="d-flex flex-lg-row flex-column justify-content-between mb-4"
      >
        <div class="w-75 d-flex gap-1">
          <h5>{{ factor.title }}</h5>
          <span
            ref="titleTooltip"
            data-bs-toggle="tooltip"
            :data-bs-title="factor.body"
          >
            <i
              class="c-200 fa-fw fas fa-info-circle fa-lg me-2"
              :class="[`text-${color}`, 'd-inline-block']"
            />
          </span>
        </div>
        <div class="w-lg-25 w-100">
          <ScaleChart
            :value="factor.score"
            :scale="scale"
          />
        </div>
      </div>
      <p class="mb-4">
        {{ $t(advice) }}
      </p>

      <!--  v-if="['EB', 'UIT', 'HIN'].includes(factor.key)"    -->
      <Suspense v-if="factor?.factors?.length">
        <template #default>
          <PersonalFeedback
            :factor="factor"
            :type="type.split('_')[0]"
            :ids="[factor.dataId]"
          />
        </template>
        <template #fallback>
          <div class="d-flex justify-content-center">
            <div
              class="spinner-border"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </template>
      </Suspense>

      <!--      <div class="d-flex align-items-end flex-column">
        <p class="text-sm mb-0">
          {{ $t('feedback.was_this_useful') }}
        </p>
        <div class="d-flex gap-2">
          <button
            class="btn btn-sm btn-secondary"
            :class="[response === true ? 'active' : '']"
            @click="sendFeedbackResponse(true)"
          >
            {{ $t('message.yes') }}
          </button>
          <button
            class="btn btn-sm btn-secondary"
            :class="[response === false ? 'active' : '']"
            @click="sendFeedbackResponse(false)"
          >
            {{ $t('message.no') }}
          </button>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeedbackModal',
};
</script>

<style scoped lang="scss"></style>
