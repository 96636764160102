<script setup>
import { inject, defineProps } from "vue";

const context = inject("ListGroup");

if (!context) {
  throw new Error("ListGroupItem must be used within ListGroup");
}

const props = defineProps({
  icon: {
    type: String,
    default: null,
  },
  label: {
    type: String,
    default: "",
  },
});
</script>

<template>
  <li
    class="px-0 list-group-item d-flex justify-content-between align-items-center"
  >
    <div
      v-if="props.label"
      class="me-auto d-flex align-items-center"
    >
      <span
        v-if="$slots.icon"
        style="font-size: 1.4rem"
        class="me-2"
      ><slot name="icon" /></span>
      <span class="text-sentence">{{ props.label }}</span>
    </div>
    <div>
      <slot />
    </div>
  </li>
</template>

<script>
export default {
  name: "ListGroupItem",
};
</script>

<style scoped></style>
