<template>
  <div class="modal-content">
    <div
      class="modal-header c-50"
      :class="'bgc-koraalrood'"
    >
      <div
        :class="'c-koraalrood'"
        class="c-200 text-sm modal-title w-100 flex-grow-1"
      >
        <span class="text-sentence">{{ $t("message.connection_error") }}</span>
      </div>
      <button
        type="button"
        class="d-block ms-auto btn-close"
        aria-label="Close"
        @click="closeModal"
      />
    </div>
    <div class="modal-body">
      <span class="text-sentence">{{
        $t("message.connection_error_body")
      }}</span>
    </div>
  </div>
</template>
<script>
import { mapActions } from "pinia";
import { useModalStore } from "../../../stores/modal";

export default {
  props: ["err"],
  methods: {
    ...mapActions(useModalStore, ["closeModal"]),
  },
};
</script>
