<template>
  <Card>
    <template #header>
      <div
        class="w-100 d-flex justify-content-between align-items-center"
      >
        <span class="overline text-sm">{{
          $t('dashboard.active_checkups')
        }}</span>
        <ul class="nav nav-pills p-0">
          <li class="nav-item dropdown p-0">
            <button
              class="nav-link p-0 border-0 bg-transparent"
              data-bs-toggle="dropdown"
              role="button"
              aria-expanded="false"
            >
              <i class="fas fa-ellipsis-v" />
            </button>
            <ul class="dropdown-menu">
              <li>
                <button
                  class="dropdown-item"
                  @click="$emit('openCalendar')"
                >
                  <i class="far fa-fw fa-calendar me-1" />
                  <span class="text-sentence">{{
                    $t('dashboard.open_calendar')
                  }}</span>
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </template>
    <template v-if="activeCheckup">
      <div class="top d-flex justify-content-between">
        <p class="overline text-sm">
          {{
            activeCheckup.title.includes('own')
              ? $t(`vitamins.${activeCheckup.title}`)
              : activeCheckup.title
          }}
        </p>
      </div>
      <ProgressBar
        :value="activeCheckupPct"
        :color="`bgc-${activeCheckup.color} c-200`"
      />
      <div
        v-if="activeCheckup.deadline"
        class="mt-2 text-sm"
      >
        <span class="text-sentence"
          >{{ $t('message.deadline') }}:
          {{
            moment(activeCheckup.deadline).format('DD/MM/YYYY')
          }}</span
        >
      </div>
      <div class="d-grid gridcols mt-2 align-items-baseline">
        <div />
        <div
          v-show="checkups.length > 1"
          class="d-flex justify-content-center align-items-baseline"
        >
          <button
            v-for="(checkup, i) in checkups"
            :id="i"
            :key="checkup.id"
            class="rounded-circle bgc-theme border-0 c-400 mx-1"
            :class="activeCheckupKey === i ? ' bgc-theme c-700' : ''"
            style="height: 12px; width: 12px"
            @click="activeCheckupKey = i"
          />
        </div>
        <div
          class="d-flex w-100 justify-content-center justify-content-lg-end"
        >
          <router-link
            v-if="activeCheckup"
            :to="{
              name: 'checkup',
              params: {
                // checkup: checkup.entry ?? '',
                // submission:JSON.stringify(submission),
                id: activeCheckup.id,
              },
            }"
            class="d-block ms-lg-auto btn btn-link mb-n3"
            style="max-width: fit-content"
          >
            <span>{{
              activeCheckup.submission
                ? $t('message.afwerken')
                : $t('message.start')
            }}</span>
          </router-link>
          <!--          <button
            class="ms-2 btn btn-primary"
            @click="$emit('openCalendar')"
          >
            <span class="me-1"
              ><i class="far fa-calendar-alt"
            /></span>
            {{ $t('message.calendar') }}
          </button>-->
        </div>
      </div>
    </template>
    <template v-else-if="isLoading">
      <div
        class="w-100 h-100 d-flex align-items-center justify-content-center bg-white"
      >
        <div
          class="spinner-border"
          role="status"
        >
          <span class="visually-hidden"
            >{{ $t('message.loading') }}...</span
          >
        </div>
      </div>
    </template>
    <template v-else>
      <div
        class="h-100 w-100 d-flex flex-column align-items-center justify-content-center"
      >
        <p class="text-black-50 mb-1">
          {{ $t('dashboard.no_active_checkups') }}
        </p>
        <button
          class="btn btn-primary btn-sm"
          @click="$emit('openCalendar')"
        >
          <span class="me-1"><i class="far fa-calendar-alt" /></span>
          {{ $t('dashboard.open_calendar') }}
        </button>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from '@/views/components/simple/Card';
import moment from 'moment';
import ProgressBar from './ProgressBar';
import qs from 'qs';
export default {
  name: 'ActiveCheckups',
  components: { ProgressBar, Card },
  data() {
    return {
      checkups: [],
      activeCheckupKey: 0,
      isLoading: true,
      moment,
    };
  },
  computed: {
    activeCheckup() {
      return this.checkups[this.activeCheckupKey] || null;
    },
    activeCheckupPct() {
      if (this.activeCheckup.submission) {
        return Math.round(
          (this.activeCheckup.submission.submission.answeredCount /
            this.activeCheckup.questionCount) *
            100
        );
      }
      return 0;
    },
  },
  created() {
    this.fetchCheckups();
  },
  methods: {
    async fetchCheckups() {
      this.isLoading = true;
      const { data } = await this.$axios.get(
        `/checkups/own?${qs.stringify({
          start_lte: moment().startOf('day').toISOString(),
          end_gte: moment().startOf('day').toISOString(),
        })}`
      );

      this.checkups = data.filter(
        (item) =>
          !item.submission ||
          (item.submission && !item.submission.isfinished)
      );
      this.isLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.gridcols {
  grid-template-columns: 2fr 1fr 2fr;
  grid-gap: 1rem;
}

@include media-breakpoint-down(xl) {
  .gridcols {
    grid-template-columns: 2fr 1fr 2fr;
  }
}
</style>
