<template>
  <section>
    <template v-if="isLoading">
      <div class="d-flex justify-content-center">
        <div
          class="spinner-border"
          role="status"
        >
          <span class="visually-hidden"
            >{{ $t('message.loading') }}...</span
          >
        </div>
      </div>
    </template>
    <template v-else>
      <slot />
    </template>
  </section>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped></style>
