<script setup>
// Rework @/views/components/FeedbackComponent.vue

import { inject, ref, watch } from 'vue';
import Loader from '../Loader';
import FeedbackModal from '../../views/components/modals/FeedbackModal';
import Modal from '../Modal';
import {
  getScoreColor,
  getContrastColor,
  getColor,
} from '../../helpers/color';
// import ProgressChart from '../../views/components/ProgressChart';
import { useI18n } from 'vue-i18n';

const axios = inject('axios');
const { locale } = useI18n({ useScope: 'global' });

const isLoading = ref(false);
/*
const factors = Object.freeze({
  POS: [
    'AU',
    'SL',
    'SC',
    'VAR',
    'ZIN',
    'TI',
    'LEE',
    'FB',
    'SE',
    'ZE',
    'OPT',
    'VEE',
  ],
  NEG: ['EMO', 'ONZ', 'ROA', 'ROC', 'BUR', 'CON', 'POL'],
  UIT: ['WD', 'COG', 'COM'],
});
*/

const colors = Object.freeze({
  POS: 'great',
  MID: 'average',
  NEG: 'bad',
});

/*const labelList = [...factors.POS, ...factors.NEG, ...factors.UIT];*/

const props = defineProps({
  type: {
    type: String,
    required: true,
    validator(value) {
      return ['user', 'teams', 'segments'].includes(value);
    },
  },
  ids: {
    type: Array,
    required: true,
  },
  factor: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['setActive']);

const activeDataset = ref(props.activeSet);

const datasets = ref([]);
const initializeData = async () => {
  isLoading.value = true;

  const { data } = await axios.request({
    method: 'GET',
    url: '/analytics-items/percentiles',
    params: {
      _type: props.type,
      ids: props.ids,
      factorKeys: props.factor.factors.map((f) => f.key),
      _locale: locale.value,
    },
  });
  datasets.value = [];

  if (data.length > 1) {
    // map it for multi-data heatmap
    // TODO: Fix this on monday 19/09/2022
    for (const dataset of data) {
      datasets.value.push({
        title: dataset.info.title,
        isHidden: dataset.info.isHidden,
        data: dataset.data.map((item) => ({
          ...item,
          color: getScoreColor(item.score),
        })),
      });
    }
  } else {
    for (const dataset of data) {
      dataset.data = dataset.data.reduce(
        (obj, factor) => {
          /*       const type = Object.keys(props.factor.factors)[
            Object.values(props.factor.factors).findIndex((keys) =>
              keys.includes(factor.key)
            )
          ];*/
          let key;
          /*factor.type = type;*/
          if (props.factor.key === 'UIT') {
            if (factor.score < 5 || factor.score > 95) {
              key = 'NEG';
            } else if (factor.score < 15 || factor.score > 85) {
              key = 'MID';
            } else {
              key = 'POS';
            }
          } else if (['HIN', 'ONG'].includes(props.factor.key)) {
            if (factor.score <= 25) {
              key = 'POS';
            } else if (factor.score <= 75) {
              key = 'MID';
            } else {
              key = 'NEG';
            }
          } else {
            if (factor.score <= 25) {
              key = 'NEG';
            } else if (factor.score <= 75) {
              key = 'MID';
            } else {
              key = 'POS';
            }
          }

          if (!factor.score) {
            return {
              ...obj,
              [key]: [...obj[key]],
            };
          }

          return {
            ...obj,
            [key]: [...obj[key], factor],
          };
        },
        {
          POS: [],
          MID: [],
          NEG: [],
        }
      );

      dataset.data.NEG = dataset.data.NEG.reverse();
      isLoading.value = false;
    }

    datasets.value = data;
  }
  isLoading.value = false;
};

if (props.ids?.length) {
  await initializeData();
}

const setActiveSet = (index) => {
  emit('setActive', index);
  activeDataset.value = index;
};

const selectedFactor = ref(null);
const feedbackModal = ref(null);

const openFeedbackModal = (factor) => {
  selectedFactor.value = factor;
  feedbackModal.value.setOpen(true);
};

watch(
  [() => props.ids, () => locale.value],
  async ([newVal, oldVal], [locale, oldLocale]) => {
    if (
      !newVal.every((item, i) => item === oldVal[i]) ||
      locale !== oldLocale
    ) {
      await initializeData();
    }
  }
);

watch(
  () => props.activeSet,
  (val) => (activeDataset.value = val)
);
</script>

<template>
  <Modal
    ref="feedbackModal"
    size="lg"
  >
    <FeedbackModal
      :type="type === 'user' ? 'user' : 'swarm'"
      :factor="selectedFactor"
    />
  </Modal>
  <Loader :is-loading="isLoading">
    <div v-if="datasets.length > 1">
      <div class="overflow-auto pe-2">
        <table
          class="text-center table table-bordered table-responsive mb-0"
        >
          <thead>
            <tr>
              <th />
              <th
                v-for="factor in datasets[0].data"
                :key="`factor-${factor.id}`"
                class="fw-normal text-sm text-capitalize"
              >
                {{ factor.title }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(set, i) in datasets"
              :key="`set-${i}`"
            >
              <td>
                {{ set.title }}
              </td>
              <td
                v-for="factor in set.data"
                :key="`factor-data-${factor.id}`"
                class="align-middle"
                @click="openFeedbackModal(factor)"
              >
                <!--                <ProgressChart
                  v-if="!set.isHidden"
                  :pct="factor.score"
                  class="mx-auto"
                  :analytics="factor.key"
                  :type="factor.key"
                  style="width: 4rem; height: 4rem"
                />-->
                <button
                  class="rounded-pill px-4 py-1 border-0"
                  :style="[
                    `background: ${getScoreColor(
                      factor.score,
                      factor.key,
                      factor.key
                    )}`,
                    `color: ${getContrastColor(
                      getScoreColor(
                        factor.score,
                        factor.key,
                        factor.key
                      )
                    )}`,
                  ]"
                >
                  {{ Math.round(factor.score) }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else-if="datasets.length">
      <!--      <div class="row row-cols-3">-->
      <!--        <div class="col">-->
      <!--          <h5-->
      <!--            class="text-sentence pb-2 position-sticky"-->
      <!--            style="min-height: 2rem; font-size: 13px"-->
      <!--          >-->
      <!--            {{ $t('message.gunstige_score') }}-->
      <!--          </h5>-->
      <!--        </div>-->
      <!--        <div class="col">-->
      <!--          <h5-->
      <!--            class="text-sentence pb-2 position-sticky"-->
      <!--            style="min-height: 2rem; font-size: 13px"-->
      <!--          >-->
      <!--            {{ $t('message.ruimte_voor_verbetering') }}-->
      <!--          </h5>-->
      <!--        </div>-->
      <!--        <div class="col">-->
      <!--          <h5-->
      <!--            class="text-sentence pb-2 position-sticky"-->
      <!--            style="min-height: 2rem; font-size: 13px"-->
      <!--          >-->
      <!--            {{ $t('message.aandachtspunten') }}-->
      <!--          </h5>-->
      <!--        </div>-->
      <!--      </div>-->
      <div
        class="row row-cols-3 overflow-auto"
        style="max-height: 400px"
      >
        <div
          v-for="(item, key) in datasets[0].data"
          :key="item.id"
          class="col"
        >
          <!--                      @click="openFeedbackModal(factor)"-->
          <button
            v-for="(factor, i) in item"
            :key="i"
            :class="[
              'hover-button',
              `bg-${colors[key]}`,
              'mb-2 d-block w-100 shadow-sm border-0 rounded-8 overflow-hidden text-truncate text-start py-2 px-3',
            ]"
            :style="[
              `color: ${getContrastColor(getColor(colors[key]))}`,
            ]"
            @click="openFeedbackModal(factor)"
          >
            {{ factor.title }}
          </button>
        </div>
      </div>
      <div
        v-if="datasets.length > 1"
        class="mx-auto w-100 mt-4 d-inline-flex justify-content-center gap-2 align-items-center"
      >
        <button
          v-for="(set, i) in datasets"
          :id="i"
          :key="set.id"
          class="rounded-circle bgc-theme border-0 c-400 mx-1"
          :class="activeDataset === i ? ' bgc-theme c-700' : ''"
          style="height: 12px; width: 12px"
          @click="setActiveSet(i)"
        />
      </div>
      <div class="my-4 d-flex gap-4">
        <div class="d-flex gap-2 align-items-center">
          <div
            style="width: 13px; height: 13px; border-radius: 13px"
            class="bg-great"
          ></div>
          <span style="font-size: 13px">{{
            $t('message.gunstige_score')
          }}</span>
        </div>
        <div class="d-flex gap-2 align-items-center">
          <div
            style="width: 13px; height: 13px; border-radius: 13px"
            class="bg-average"
          ></div>
          <span style="font-size: 13px">{{
            $t('message.ruimte_voor_verbetering')
          }}</span>
        </div>
        <div class="d-flex gap-2 align-items-center">
          <div
            style="width: 13px; height: 13px; border-radius: 13px"
            class="bg-bad"
          ></div>
          <span style="font-size: 13px">{{
            $t('message.aandachtspunten')
          }}</span>
        </div>
      </div>
    </div>
    <div v-else>
      {{ $t('warning.no_data') }}
    </div>
  </Loader>
</template>

<script>
export default {
  name: 'PersonalFeedback',
};
</script>

<style scoped lang="scss">
.hover-button {
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
}
</style>
