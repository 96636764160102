<template>
  <div
    :class="[vertical ? 'h-100' : 'w-100']"
    :style="[vertical ? 'width: 10px;' : '']"
  >
    <main
      class="position-relative w-100"
      :style="[`${vertical ? 'width' : 'height'}: 10px`]"
    >
      <section
        ref="barContainer"
        class="rounded-pill position-absolute top-0 left-0 w-100 bg-green h-100"
      >
        <!--   Divide in sections   -->
        <div
          class="d-flex flex-column h-100 w-100 mx-0 rounded-pill overflow-hidden position-relative"
        >
          <div
            v-for="[key, item] in Object.entries(scale).reverse()"
            :key="key"
            :style="`width: ${key}%; height: 100%`"
            class="position-absolute left-0 top-0 px-0"
            :class="`bg-${item}`"
          />
        </div>
      </section>
      <div
        ref="bar_arrow_eng"
        class="position-absolute bar-indicator d-flex justify-content-center"
        style="top: 40%; width: 20px"
        data-bs-toggle="tooltip"
        :data-bs-title="`${barAmount}%`"
        :style="`left: ${arrowOffset}px`"
      >
        <span class="d-block mx-auto"
          ><i
            class="fas fa-sort-up"
            style="font-size: 25px"
        /></span>
      </div>
    </main>
    <span
      v-if="description"
      class="d-block text-sm mt-1"
      >{{ description }}</span
    >
  </div>
</template>

<script>
import { Tooltip } from 'bootstrap';
export default {
  name: 'ScaleChart',
  inject: ['hasTooltip'],
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    scale: {
      type: Object,
      default() {
        return { 25: 'bad', 75: 'average', 100: 'great' };
      },
    },
    description: {
      type: String,
      default: '',
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      arrowOffset: 0,
      ro: null,
    };
  },
  computed: {
    barAmount() {
      return Math.round(this.value);
    },
  },
  watch: {
    value(val) {
      this.setOffset(val);
      this.$nextTick(() => {
        if (this.tooltip) {
          this.tooltip.setContent({
            '.tooltip-inner': this.$t('general.scale_chart_tooltip', {
              amt: this.barAmount,
            }),
          });
        }
      });
    },
    '$i18n.locale'() {
      this.$nextTick(() => {
        if (this.tooltip) {
          this.tooltip.setContent({
            '.tooltip-inner': this.$t('general.scale_chart_tooltip', {
              amt: this.barAmount,
            }),
          });
        }
      });
    },
  },
  mounted() {
    this.ro = new ResizeObserver(this.onResize);
    this.ro.observe(this.$refs.barContainer);
    this.tooltip = new Tooltip(this.$refs.bar_arrow_eng);
  },
  beforeUnmount() {
    this.ro?.unobserve(this.$refs.barContainer);
  },
  methods: {
    onResize() {
      this.setOffset(this.value);
    },
    setOffset(val) {
      this.$nextTick(() => {
        const barPx =
          ((this.$refs.barContainer?.clientWidth || 0) / 100) * val;
        this.arrowOffset =
          barPx - this.$refs.bar_arrow_eng.clientWidth / 2;
      });
    },
  },
};
</script>

<style scoped>
.bar-indicator {
  transition: all 0.4s ease-in-out;
}
</style>
