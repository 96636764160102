<script setup>
import Card from '../../views/components/simple/Card';
import { useAuthStore } from '../../stores/auth';
import { useAsyncState } from '@vueuse/core';
import { inject, ref } from 'vue';
import _ from 'lodash';

const authStore = useAuthStore();

const axios = inject('axios');
const { state: managers } = useAsyncState(
  () =>
    axios
      .request({
        method: 'GET',
        url: '/swarms/zerglings',
        params: {
          _user: authStore.user.id,
        },
      })
      .then((res) =>
        _.uniqBy(
          res.data.map((swarm) => swarm.overlords).flat(),
          'id'
        )
      ),
  []
);
const manager = ref(null);

const emit = defineEmits(['submitModal']);
const onSubmit = () => {
  emit('submitModal', manager.value);
};
</script>

<template>
  <Card color="green">
    <template #header>
      <span class="text-uppercase text-sm">Share your checkup</span>
    </template>
    <form @submit.prevent="onSubmit">
      <label class="mb-2 d-block"
        >I want to share my checkup with the following
        manager(s)</label
      >
      <select
        v-model="manager"
        class="w-100"
      >
        <option :value="null">All my managers</option>
        <option
          v-for="manager in managers"
          :key="manager.id"
          :value="manager.id"
        >
          {{ manager.firstname }} {{ manager.lastname }}
        </option>
      </select>
      <div class="mt-4 d-flex justify-content-end">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </Card>
</template>
<script>
export default {
  name: 'ShareSubmissionModal',
};
</script>

<style scoped></style>
