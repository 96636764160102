<script setup>
import Pagination from './Pagination';
import { inject, provide, ref, watch } from 'vue';
import { useAsyncState } from '@vueuse/core';
import qs from 'qs';
import Loader from '../Loader';

const props = defineProps({
  request: {
    type: String,
    default: '',
  },
  countRequest: {
    type: [String, Number],
    default: '',
  },
  content: {
    type: Array,
    default: () => [],
  },
  options: {
    type: Object,
    default: () => ({
      limit: 10,
      allowLengthChange: true,
    }),
  },
});

const axios = inject('axios');
const page = ref(0);
const limit = ref(props.options.limit || 10);
const emit = defineEmits(['setContent']);

const { state, isLoading, execute } = useAsyncState(
  () => {
    const start = page.value * limit.value;
    if (props.request) {
      const separator = props.request.includes('?') ? '&' : '?';
      return Promise.all([
        axios.get(
          `${props.request}${separator}${qs.stringify({
            _start: start,
            _limit: limit.value,
          })}`
        ),
        isNaN(+props.countRequest)
          ? axios.get(props.countRequest)
          : Promise.resolve({ data: props.countRequest }),
      ]).then(([content, count]) => {
        emit('setContent', content.data);
        return {
          content: content.data,
          count: count.data,
        };
      });
    } else {
      emit('setContent', props.content[0]);
      const content = [props.content]
        .flat()
        .slice(start, start + limit.value);
      // If there is no content on this page, go back a page and refetch data
      if (!content.length && page.value > 0) {
        page.value = page.value -= 1;
        if (execute) {
          return execute();
        }
      }
      return Promise.resolve({
        content: content,
        count: [props.content].flat().length,
      });
    }
  },
  {
    content: [],
    count: 0,
  },
  props.options
);

const setPage = (moveBy) => {
  if (!moveBy) {
    page.value = 0;
  } else {
    page.value = page.value + moveBy;
  }
  execute();
};

watch(
  [() => limit.value, () => props.request, () => props.countRequest],
  () => {
    page.value = 0;
    execute();
  }
);

watch(
  () => props.content,
  () => {
    execute();
  }
);

provide('itemsPerPage', limit);
defineExpose({
  execute,
  page,
});
</script>

<template>
  <div>
    <!--    <pre>{{ { isLoading, content: state.content.length } }}</pre>-->
    <Loader
      v-if="isLoading && !state.content.length"
      :is-loading="isLoading"
    />
    <template v-else>
      <p
        v-if="!state.content.length"
        class="text-center"
      >
        {{ $t('warning.no_data') }}
      </p>
      <slot
        name="content"
        :content="state.content"
      />
      <Pagination
        :current-page="page"
        :total-rows="state.count"
        :allow-length-change="options.allowLengthChange"
        @change="setPage"
      >
        <template #bottom-left>
          <slot name="bottom-left" />
        </template>
      </Pagination>
    </template>
  </div>
</template>

<style scoped></style>
