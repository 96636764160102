<template>
  <label
    class="styled-checkbox"
    for=""
    @click="handleClick($event)"
  >
    <input
      :checked="modelValue"
      :disabled="disabled"
      :class="disabled ? 'disabled' : ''"
      type="checkbox"
      @change="handleChange($event)"
    />

    <img
      :class="disabled ? 'disabled' : ''"
      class="check-on"
      src="../../../assets/icons/icon-toggle-check-box.svg"
      alt=""
    />
    <img
      :class="disabled ? 'disabled' : ''"
      class="check-off"
      src="../../../assets/icons/icon-toggle-check-box_off.svg"
      alt=""
    />
    <img
      :class="disabled ? 'disabled' : ''"
      class="check-some"
      src="../../../assets/icons/icon-toggle-indeterminate-check-box.svg"
      alt=""
    />
  </label>
</template>
<script>
export default {
  props: ['modelValue', 'disabled', 'value'],
  emits: ['update:modelValue', 'change'],
  methods: {
    handleChange(e) {
      this.$emit('update:modelValue', e.target.checked);
      this.$emit('change', e);
    },
    handleClick() {
      this.$el.querySelector('input').click();
    },
  },
};
</script>
<style lang="scss">
.styled-checkbox {
  cursor: pointer;

  img.disabled {
    opacity: 0.4;
  }

  input[type='checkbox'] {
    display: none;

    ~ img {
      &.check-on {
        display: none;
      }

      &.check-some {
        display: none;
      }

      &.check-off {
        display: block;
      }
    }

    &:checked {
      ~ img {
        &.check-on {
          display: block;
        }

        &.check-off {
          display: none;
        }
      }

      &.some {
        &.check-some {
          display: block;
        }

        &.check-off {
          display: none;
        }
      }
    }
  }
}
</style>
