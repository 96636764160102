<template>
  <form
    class="card login-form"
    action=""
    @submit.prevent="submitForm"
  >
    <div class="card-header bgc-groen c-50">
      <div class="overline text-sm">
        {{ $t('message.please_log_in') }}
      </div>
    </div>
    <div class="card-body">
      <p>
        <label
          for="username"
          class="text-sentence"
          >{{ $t('message.email') }}</label
        >
        <input
          id="username"
          v-model="email"
          class="w-100"
          name="username"
          type="text"
        />
      </p>
      <div class="mt-2">
        <label
          class="text-sentence"
          for="password"
          >{{ $t('message.password') }}</label
        >
        <div class="position-relative w-100">
          <input
            id="password"
            v-model="password"
            class="w-100"
            name="password"
            :type="showPassword ? 'text' : 'password'"
          />
          <button
            v-if="password.length"
            type="button"
            class="btn btn-link rounded-1 no-hover position-absolute top-0 bottom-0 end-0 d-flex align-items-center justify-content-center"
            @click="showPassword = !showPassword"
          >
            <span v-if="showPassword"
              ><i class="fas fa-eye-slash"></i
            ></span>
            <span v-else><i class="fas fa-eye"></i></span>
          </button>
        </div>
      </div>
      <p
        v-if="error"
        class="c-koraalrood c-200"
      >
        {{ error }}
      </p>
      <p
        style="margin-top: 37px"
        class="mb-0 d-flex justify-content-between align-items-center"
      >
        <a
          type="button"
          class="text-sm text-sentence"
          @click="$emit('forgot')"
          >{{ $t('message.forgot_password') }}</a
        >
        <button
          v-if="!isLoading"
          class="btn btn-primary"
          type="submit"
        >
          <span>{{ $t('message.log_in') }}</span>
        </button>
        <button
          v-else
          class="btn disabled d-flex"
          type="button"
          disabled
        >
          <div
            style="height: 12px; width: 12px"
            class="spinner-border"
            role="status"
          >
            <span class="visually-hidden"
              >{{ $t('message.loading') }}...</span
            >
          </div>
          <span class="ms-2">{{ $t('message.logging_in') }}</span>
        </button>
      </p>
    </div>
  </form>
</template>
<script>
import { mapActions } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import _ from 'lodash';

export default {
  data() {
    return {
      email: '',
      password: '',
      showPassword: false,
      valid: true,
      isLoading: false,
      error: null,
    };
  },
  methods: {
    ...mapActions(useAuthStore, ['login']),
    async submitForm() {
      this.errors = [];
      this.valid = true;
      if (
        this.email === '' ||
        !this.password /* || this.password.length < 6*/
      ) {
        this.valid = false;
        this.error = this.$t('message.fill_in_all_fields');
        return;
      }
      try {
        this.isLoading = true;
        await this.login({
          email: this.email,
          password: this.password,
        });
        const redirectUrl = '/' + (this.$route.query.redirect || '');
        await this.$router.replace(redirectUrl);
      } catch (err) {
        const error = _.get(
          err,
          'response.data.message[0].messages[0].id'
        );
        this.error =
          this.$t(error) || err.message || 'Authentication failure';
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
.login-page-wrapper {
  height: calc(100vh - 150px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  width: 320px;
}

.no-hover:hover {
  background-color: transparent !important;
}
</style>
