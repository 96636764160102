<script setup></script>
<template>
  <Card
    :color="color"
    :color-shade="shade"
    :padding="4"
  >
    <template #header>
      <div class="w-100 d-flex justify-content-between">
        <div>
          <span class="overline text-sm">{{ title }}</span>
          <!--          <span
            v-if="headerTooltip"
            ref="headerTooltip"
            class="ms-2 text-sm"
            data-bs-toggle="tooltip"
            :data-bs-title="headerTooltip"
            ><i class="far fa-info-circle"
          /></span>-->
        </div>

        <ul
          v-if="hasDropdown"
          class="nav nav-pills p-0"
        >
          <li class="nav-item dropdown p-0">
            <button
              class="nav-link p-0 border-0 bg-transparent"
              data-bs-toggle="dropdown"
              role="button"
              aria-expanded="false"
            >
              <i class="fas fa-ellipsis-v" />
            </button>
            <ul class="dropdown-menu">
              <slot name="dropdown-items" />
            </ul>
          </li>
        </ul>
      </div>
    </template>
    <div v-if="data && isValidData">
      <section
        class="d-flex align-items-start"
        :class="multipleData ? 'flex-column' : ''"
      >
        <template v-if="!multipleData">
          <!--                   :color="color"   -->
          <template v-if="type === 'battery'">
            <BatteryComponent :score="data[0].value" />
            <div class="ms-3">
              <VerticalScale :score="data[0].value" />
            </div>
          </template>
          <template v-else>
            <progress-chart
              class="me-2"
              style="width: 96px; height: 96px"
              :pct="data[0].value"
              border-width="6"
              f-size="lg"
            />
          </template>

          <div
            class="text-sm"
            style="margin-start: 25px"
          >
            {{ headerTooltip }}
          </div>
        </template>
        <ScaleChartList
          v-else
          :data="data"
          :class="[multipleData ? 'd-block' : 'd-none d-md-block']"
        />
      </section>
    </div>
    <div
      v-else
      class="w-100 h-100 d-flex justify-content-center align-items-center"
    >
      <p class="text-black-50 text-center">
        {{ $t('warning.data_is_processing') }}
      </p>
    </div>
  </Card>
</template>

<script>
import ProgressChart from '@/views/components/ProgressChart';
import Card from '@/views/components/simple/Card';
import ScaleChartList from '@/components/ScaleChart/ScaleChartList';
import { Tooltip } from 'bootstrap';
import BatteryComponent from '../../components/BatteryComponent';

import VerticalScale from '../../components/ScaleChart/VerticalScale';

export default {
  name: 'StatCard',
  components: {
    VerticalScale,
    BatteryComponent,
    ScaleChartList,
    Card,
    ProgressChart,
  },
  provide() {
    return {
      hasTooltip: this.tooltip,
    };
  },
  props: {
    size: {
      type: String,
      validator(val) {
        return /(([A-z]{2,3})-){2}([0-9])*/g.test(val);
      },
    },
    data: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: 'message.stats',
    },
    color: {
      type: String,
      default: 'theme',
    },
    shade: {
      type: [String, Number],
      default: '',
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
    headerTooltip: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'battery',
    },
  },
  computed: {
    hasDropdown() {
      return !!this.$slots['dropdown-items'];
    },
    multipleData() {
      return this.data.length > 1;
    },
    isValidData() {
      return (
        this.data &&
        this.data.length &&
        this.data.reduce((total, cur) => total + cur.value, 0)
      );
    },
  },
  mounted() {
    if (this.$refs.headerTooltip) {
      this.headerTooltipItem = new Tooltip(this.$refs.headerTooltip);
    }
  },
};
</script>

<style scoped></style>
