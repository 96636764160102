import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia, mapStores } from 'pinia';
import mitt from 'mitt';
import {
  plugin as axiosPlugin,
  axiosInstance,
  loadConfig,
} from './axios-auth';
import { useAuthStore } from '@/stores/auth';
import { debounce } from 'lodash';
import { PiniaDebounce } from '@pinia/plugin-debounce';
import './assets/scss/main.scss';
import { getColor } from './helpers/color';
import i18n from './helpers/i18n';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

if (process.env.VUE_APP_BUGSNAG_KEY) {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_KEY,
    plugins: [new BugsnagPluginVue()],
  });
}

loadConfig().then(() => {
  const pinia = createPinia();
  pinia.use(({ store }) => {
    store.axios = axiosInstance;
    store.i18n = i18n;
  });
  pinia.use(PiniaDebounce(debounce));

  const emitter = mitt();

  // TODO: Clean up this mixin
  const mixins = {
    methods: {
      unsignedRandom(maxval, i) {
        return Math.abs(
          (Math.floor(Math.random() * Math.pow(2, 32)) ^
            (this.eventseed[i % 4] >>> i % 23)) %
            (maxval + 1)
        );
      },

      randomString(length, chars) {
        var result = '';
        for (var i = length; i > 0; --i)
          result += chars[this.unsignedRandom(chars.length - 1, i)];
        return result;
      },
      setOpacity(rgb, opacity) {
        rgb.a = opacity;
        let rgba = `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`;
        return rgba;
      },
      hexToRgb(hex) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
          return r + r + g + g + b + b;
        });

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
          hex
        );
        return result
          ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
              a: 1,
            }
          : null;
      },
      adjustColor(color, amount) {
        return (
          '#' +
          color
            .replace(/^#/, '')
            .replace(/../g, (color) =>
              (
                '0' +
                Math.min(
                  255,
                  Math.max(0, parseInt(color, 16) + amount)
                ).toString(16)
              ).substr(-2)
            )
        );
      },
      getColorByVitamin(vitamin) {
        let color = 'koraalrood';
        switch (vitamin.toLowerCase()) {
          case 'energiebronnen':
          case 'energy sources':
          case 'job resources':
          case 'ressources énergétiques':
          case 'eb':
            color = 'green';
            break;
          case 'uitdagingen':
          case 'challenges':
          case 'défis':
          case 'uit':
            color = 'turkoois';
            break;
          case 'hinderpalen':
          case 'obstacles':
          case 'hin':
            color = 'coral';
            break;
          case 'persoonlijkehulpbronnen':
          case 'pers. hulpbronnen':
          case 'pers. bronnen':
          case 'persoonlijke hulpbronnen':
          case 'personal resources':
          case 'Ressources personnelles':
          case 'phb':
            color = 'okergeel';
            break;
          case 'motivatie':
          case 'motivation':
          case 'mot':
          case 'im':
            color = 'paars';
            break;
          case 'attitudes':
          case 'at':
          case 'att':
            color = 'blauw';
            break;
          case 'prestatie':
          case 'performance':
          case 'pr':
          case 'pres':
            color = 'magenta';
            break;
          case 'unhealthiness':
          case 'insalubrité':
          case 'ongezondheid':
          case 'ong':
            color = 'groengrijs';
            break;
          case 'betrokkenheid':
          case 'engagement':
          case 'eng':
            color = 'blauw-50';
            break;
          case 'welzijn':
          case 'wellbeing':
          case 'wb':
            color = 'groen-200';
            break;
        }
        return color;
      },
      getColorCode(colorName) {
        let color = 'theme--300';
        switch (colorName) {
          //! ALL THESE CASES ARE LEGACY COLORS
          case 'groen':
            color = 'green';
            break;
          case 'groen-50':
            color = 'green--light';
            break;
          case 'groen-200':
            color = 'green--dark';
            break;
          case 'turkoois':
            color = 'aqua';
            break;
          case 'turkoois-50':
            color = 'aqua--light';
            break;
          case 'turkoois-200':
            color = 'aqua--dark';
            break;
          case 'paars':
            color = 'purple';
            break;
          case 'paars-50':
            color = 'purple--light';
            break;
          case 'paars-200':
            color = 'purple--dark';
            break;
          case 'magenta':
            color = 'magenta';
            break;
          case 'magenta-25':
            color = 'magenta--light';
            break;
          case 'magenta-50':
            color = 'magenta--light';
            break;
          case 'magenta-200':
            color = 'magenta--dark';
            break;
          case 'blauw':
            color = 'blue';
            break;
          case 'blauw-50':
            color = 'blue--light';
            break;
          case 'blauw-200':
            color = 'blue--dark';
            break;
          case 'koraalrood':
            color = 'coral';
            break;
          case 'koraalrood-50':
            color = 'coral--light';
            break;
          case 'koraalrood-200':
            color = 'coral--dark';
            break;
          case 'okergeel':
            color = 'orange-yellow';
            break;
          case 'okergeel-25':
            color = 'orange-yellow--light';
            break;
          case 'okergeel-50':
            color = 'orange-yellow--light';
            break;
          case 'okergeel-200':
            color = 'orange-yellow--dark';
            break;
          case 'groengrijs':
            color = 'cambridge-blue';
            break;
          case 'groengrijs-50':
            color = 'cambridge-blue--light';
            break;
          case 'groengrijs-200':
            color = 'cambridge-blue--dark';
            break;
          default:
            // Get colors from the new color palette
            if (colorName.charAt(0) === '#') {
              return color;
            }
            color = colorName;
        }
        return getColor(color);
      },
    },
    computed: {
      ...mapStores(useAuthStore),
      // isAuthenticated() {
      //   //needs to go through store because localstorage is not reactive
      //   return this.$store.getters.isAuthenticated;
      // },
      /*      mainCompany() {
        return this.$store.getters.getMainCompany || {};
      },*/
      // user() {
      //   return this.$store.getters.getUser;
      // },
    },
    data() {
      return {
        seedevents: 0,
        eventseed: [
          ((new Date().getTime() * 41) ^
            (Math.random() * Math.pow(2, 32))) >>>
            0,
          ((new Date().getTime() * 41) ^
            (Math.random() * Math.pow(2, 32))) >>>
            0,
          ((new Date().getTime() * 41) ^
            (Math.random() * Math.pow(2, 32))) >>>
            0,
          ((new Date().getTime() * 41) ^
            (Math.random() * Math.pow(2, 32))) >>>
            0,
        ],
        mitt: emitter,
        sitesettings: {
          minimumSegmentCount: 3,
        },
        colors: [
          'blauw',
          'turkoois',
          'groengrijs',
          'groen',
          'paars',
          'magenta',
          'koraalrood',
          'okergeel',
        ],
      };
    },
  };

  const bugsnagVue = Bugsnag.getPlugin('vue');
  const app = createApp(App);
  app.mixin(mixins);
  app.provide('axios', axiosInstance);
  app.provide('mitt', emitter);
  // app.provide('i18n', i18n);
  app
    .use(pinia)
    .use(axiosPlugin)
    .use(router)
    .use(i18n)
    .use(bugsnagVue)
    .mount('#app');

  app.config.unwrapInjectedRef = true;

  if (localStorage.lang) {
    i18n.global.locale.value = localStorage.lang;
  } else {
    i18n.global.locale.value = 'en';
  }
});
