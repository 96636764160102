import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core/index';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    /**
     * Have a reactive link with the token property in localstorage
     */
    token: useStorage('token', ''),
    /**
     * Have a reactive link with the user property in localstorage
     * ! This brings issues with the user object not updating when the user is updated in the database
     * ? Use vue-query to invalidate user fetch on change?
     */
    user: useStorage('user', {}, undefined, {
      serializer: {
        read: (v) => (v ? JSON.parse(v) : null),
        write: (v) => JSON.stringify(v),
      },
    }),
    isAuthenticated: false,
  }),
  getters: {
    // Check for permission of user, keep everything inside return!
    permission() {
      return (permissionList) => {
        if (!this.user) return false;
        if (
          this.user?.managecompany ||
          permissionList.some((permission) => !!this.user[permission])
        ) {
          return true;
        }
        // for (const perm of permissionList) {
        //   if (this.user[perm]) return true;
        // }
        return false;
      };
    },
    fullName: (state) =>
      state.user
        ? `${state.user.firstname} ${state.user.lastname}`
        : '',
    hasDashboard: (state) => {
      /*       const userswarms = [
        ...(state.user?.member || []),
        ...(state.user?.swarms || []),
        ...(state.user?.viewables || []),
      ]; */
      return state?.user.member.length
        ? state.user?.member.some((swarm) => !swarm.hideDashboard)
        : true;
    },
  },
  actions: {
    /**
     * Log in user and fetch data for user
     * @param {string} email user email
     * @param {string} password user password
     * @returns {Promise<void>}
     */
    async login({ email, password }) {
      // Test if token is valid
      if (this.token) {
        try {
          const user = await this.axios.get('/users/me', {
            headers: { Authorization: `Bearer ${this.token}` },
          });
          this.axios.defaults.headers = {
            Authorization: `Bearer ${this.token}`,
          };

          this.i18n.global.locale = user.data.language || 'en';
        } catch (error) {
          this.token = null;
          this.user = {};
          throw new Error('Failed to authenticate token');
        }
      } else if (email && password) {
        // If token is invalid or no token is present, log in with email and password
        const { data } = await this.axios.post('/auth/local', {
          identifier: email,
          password,
        });

        this.token = data.jwt;
        this.axios.defaults.headers = {
          Authorization: `Bearer ${data.jwt}`,
        };
        this.user = data.user;
        this.i18n.global.locale = data.user?.language || 'en';
      } else {
        return;
      }

      this.user.ismanager = [
        ...(this.user.swarms || []),
        ...(this.user.viewables || []),
      ].some((item) => !item.issegment && item.visibility);
      this.user.issegmentmanager = this.user.swarms.some(
        (item) => item.issegment && item.visibility
      );
      this.isAuthenticated = !!this.token;
    },

    /**
     * Log out user by resetting token and clearing user
     */
    logout() {
      this.token = '';
      this.user = null;
      this.isAuthenticated = !!this.token;
    },
  },
});
