<script setup>
import { defineProps, inject } from 'vue';
// Props
defineProps({
  color: {
    type: String,
    required: true,
  },
});

// Variables
const vitamins = inject('vitamins');
const localize = inject('localize');
</script>
<template>
  <div
    class="nav h-100"
    :class="[`bg-${color}--light bgc-${color}`, 'c-50']"
  >
    <ul class="nav nav-pills h-100">
      <li class="nav-item dropdown h-100">
        <a
          class="card=header-nav-btn d-flex align-items-center justify-content-center nav-link p-0"
          style="height: 100%"
          data-bs-toggle="dropdown"
          href="#"
          role="button"
          aria-expanded="false"
        >
          <img
            src="../../assets/icons/icon-navigation-expand.svg"
            alt=""
          />
        </a>
        <ul class="dropdown-menu">
          <li
            v-for="(vitamin, index) in vitamins.vitamins"
            :key="vitamin"
          >
            <a
              href="#"
              class="dropdown-item"
              @click.prevent="$emit('goToVitamin', index)"
              >{{ localize(vitamin, 'title', $i18n.locale) }}</a
            >
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'VitaminsDropDown',
};
</script>

<style scoped></style>
