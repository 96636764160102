<template>
  <form @submit.prevent="submitMessage">
    <select
      v-if="type === 'team' && !currentTeam"
      id=""
      v-model="teamid"
      class="w-100 mb-3"
      name=""
    >
      <option
        disabled
        value=""
      >
        {{ $t('message.choose_team') }}
      </option>
      <option
        v-for="team in teams"
        :key="team"
        :value="team.id"
      >
        {{ team.title }}
      </option>
    </select>
    <tiptap
      v-model="body"
      class="mb-4"
    />
    <!--    <textarea placeholder="Description ..." name="" id="" cols="30" rows="5" v-model="body"-->
    <!--      class="w-100 mb-4"></textarea>-->
    <div class="mb-4 d-flex justify-content-end align-items-center">
      <div class="col-auto">
        <div
          v-for="tag in tags"
          :key="tag.id"
          class="mx-2 d-inline-block bgc-theme c-200 rounded-pill py-2 px-4"
        >
          {{ tag.tagtext }}
          <button
            class="border-0 bg-transparent"
            @click="removeTag(tag.id)"
          >
            <img
              src="../../assets/icons/icon-navigation-close.svg"
              alt=""
            />
          </button>
        </div>
      </div>
      <select-search
        class="mx-2 py-2"
        :ajax="true"
        :query="tagQuery"
        :label="'tagtext'"
        :value="'id'"
        :placeholder="$t('message.add_tag')"
        @selectOption="addTag"
      />
      <!--                  <button class="btn-sm btn me-3">
                          <img src="../assets/icons/icon-navigation-add.svg" alt="">
                          Tag
                        </button>-->
      <button
        class="btn btn-primary"
        :class="!isValid && 'disabled'"
        :disabled="!isValid"
      >
        <div
          v-show="loading"
          style="height: 12px; width: 12px"
          class="spinner-border"
          role="status"
        >
          <span class="visually-hidden"
            >{{ $t('message.loading') }}...</span
          >
        </div>
        <span
          v-show="!loading"
          class="text-sentence"
          >{{ $t('message.save') }}</span
        >
      </button>
    </div>
  </form>
</template>

<script>
// import qs from "qs";
import SelectSearch from '@/views/components/simple/SelectSearch';
import Tiptap from '@/views/components/simple/Tiptap';
import { mapState } from 'pinia';
import { useAuthStore } from '../../stores/auth';

export default {
  components: { Tiptap, SelectSearch },
  props: {
    availableTeams: Array,
    type: {
      default() {
        return 'team';
      },
    },
    defer: {
      default() {
        return false;
      },
    },
    id: null,
    checkup: null,
    currentTeam: null,
  },
  emits: ['submitMessageForm'],
  data() {
    return {
      loading: false,
      teams: [],
      body: '',
      teamid: '',
      tags: [],
    };
  },
  created() {
    this.teamid = this.availableTeams[0].id.toString();
    if (this.availableTeams.length) {
      this.teams = this.availableTeams;
    } else {
      this.getManagedTeams();
    }

    if (this.id) {
      this.getMessage();
    }
  },
  computed: {
    ...mapState(useAuthStore, ['user']),
    isValid() {
      return (
        this.replacedbody &&
        (this.type == 'team' ? this.teamid : true)
      );
    },
    replacedbody() {
      return this.body.replace(/(<(?!\/)[^>]+>)+(<\/[^>]+>)+/, '');
    },
    isbase() {
      return this.type === 'company';
    },
    tagIDs() {
      return this.tags.map((entity) => entity.id);
    },
    tagQuery() {
      let params = [];
      for (const id of this.tagIDs) {
        params.push('id_nin=' + id);
      }

      return '/tags?' + params.join('&');
    },
  },
  methods: {
    addTag(e) {
      this.tags.push(e);
    },
    removeTag(id) {
      const index = this.tagIDs.indexOf(id);
      this.tags.splice(index, 1);
    },
    async getManagedTeams() {
      this.teams = this.user.swarms.filter((swarm) => {
        return swarm.visibility && !swarm.issegment;
      });
    },
    async getMessage() {
      const { data: message } = await this.$axios.get(
        '/messages/' + this.id
      );
      this.body = message.body;
      this.teamid = message.teamid;
      this.tags = message.tags;
    },
    async submitMessage() {
      this.loading = true;
      if (!this.body.length) {
        return;
      }
      const message = {
        body: this.body,
        tags: this.tagIDs,
        isbase: this.isbase,
        teamid:
          this.type === 'company' ? '0' : this.teamid.toString(),
        checkupentry: this.checkup,
      };
      let result;
      if (this.id) {
        result = await this.$axios.put(
          '/messages/' + this.id,
          message
        );
      } else {
        result = await this.$axios.post('/messages', message);
      }
      this.loading = false;
      this.body = '';
      this.tags = [];
      this.$emit('submitMessageForm', result.data);
    },
  },
};
</script>
