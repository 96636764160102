export const TOAST_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};

export const TOAST_CLASSES = {
  SUCCESS: 'bg-green--dark',
  ERROR: 'bg-coral--dark',
  WARNING: 'bg-warning text-white',
  INFO: 'bg-blue',
};

export const MODAL_TYPES = Object.freeze({
  TEAM: 'team',
  SEGMENT: 'segment',
});

export const MULTI_SEPARATOR = ','; // Used to separate multiple values in a csv import
