<template>
  <section class="d-flex flex-column w-100 h-100">
    <template v-if="data.length">
      <ScaleChart
        v-for="(item, i) in data"
        :key="`scale-${i}`"
        :value="item.value"
        :description="data.length > 1 ? item.description : ''"
        :scale="
          item.scale || { 25: 'bad', 75: 'average', 100: 'great' }
        "
        class="my-2"
        :vertical="vertical"
      />
    </template>
    <template v-else>
      {{ $t('warning.data_is_processing') }}
    </template>
  </section>
</template>

<script>
import ScaleChart from '@/components/ScaleChart/ScaleChart';
export default {
  name: 'ScaleChartList',
  components: { ScaleChart },
  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped></style>
