<template>
  <div
    id="page-wrapper"
    class="login-page-wrapper"
  >
    <user-auth
      v-show="!forgot"
      @forgot="forgot = true"
    />
    <user-forgot-password
      v-show="forgot"
      @forgot="forgot = false"
    />
  </div>
</template>

<script>
import UserAuth from '@/views/components/UserAuth';
import UserForgotPassword from '@/views/components/UserForgotPassword';
export default {
  components: { UserAuth, UserForgotPassword },
  data() {
    return {
      showPass: false,
      errors: [],
      email: '',
      password: '',
      forgot: false,
    };
  },
  mounted() {
    this.mitt.emit('loadcomplete', { complete: true });
  },
  methods: {
    async forgotPassword(e) {
      const el = e.target;
      el.classList.add('loading');
      this.errors = [];
      this.$store
        .dispatch('forgotPassword', { email: this.email })
        .then(() => {
          this.errors.push(this.$t('message.reset_mail_sent'));
          el.classList.remove('loading');
        })
        .catch((e) => {
          el.classList.remove('loading');
          const messages = e.response.data.message;
          messages.forEach((messages) => {
            messages.messages.forEach((message) => {
              this.errors.push(message.message);
            });
          });
        });
    },
    async onSubmit() {
      this.errors = [];
      const formData = {
        email: this.email,
        password: this.password,
      };

      if (this.validateLogin()) {
        await this.$store
          .dispatch('signin', {
            email: formData.email,
            password: formData.password,
            vm: this,
          })
          .then(() => {})
          .catch((e) => {
            const messages = e.response.data.message;
            messages.forEach((messages) => {
              messages.messages.forEach((message) => {
                if (message.id === 'Auth.form.error.invalid') {
                  this.errors.push(
                    this.$t('message.email_or_password_invalid')
                  );
                }
                if (
                  message.id === 'Auth.form.error.password.provide'
                ) {
                  this.errors.push(
                    this.$t('message.please_provide_password')
                  );
                }
              });
            });
          });
      }
    },
  },
};
</script>

<style>
#company-logo {
  max-height: 50px;
  width: auto;
}
</style>
