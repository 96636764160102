<script setup>
import {
  computed,
  defineEmits,
  defineProps,
  nextTick,
  ref,
  watch,
} from 'vue';
import { TransitionRoot } from '@headlessui/vue';
import { onClickOutside, refDebounced } from '@vueuse/core';

const props = defineProps({
  size: {
    type: String,
    default: '',
    validator(value) {
      return ['', 'lg', 'sm'].includes(value);
    },
  },
  placeholder: {
    type: String,
    default: 'message.search',
  },
  keepOpen: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update:modelValue']);

const input = ref(null);
const inputField = ref(null);
const isOpen = ref(props.keepOpen || false);
const searchValue = ref(props.modelValue);
const query = refDebounced(searchValue, 400);

const allowClose = computed(() => {
  return !props.keepOpen && searchValue.value?.length < 1;
});

/**
 * Watch for query changes because it is debounced.
 */
watch(query, (value) => {
  emit('update:modelValue', value);
});

/**
 * Put focus in input when opened;
 */
watch(isOpen, (value) => {
  if (value) {
    nextTick(() => {
      inputField.value.focus();
    });
  }
});

// Functions

/**
 * Handle the click of the search button
 */
const handleSearchClick = () => {
  isOpen.value = !(isOpen.value && allowClose.value);
  emit('update:modelValue', query.value);
};

/**
 * Handle click outside the search input
 */
onClickOutside(input, () => {
  if (allowClose.value) {
    isOpen.value = false;
  }
});
</script>

<template>
  <div
    ref="input"
    class="input-group flex-nowrap d-flex mb-0"
  >
    <TransitionRoot
      :show="isOpen"
      enter-from="closed"
      :enter-to="`open${props.size ? `-${props.size}` : ''}`"
      :leave-from="`open${props.size ? `-${props.size}` : ''}`"
      leave-to="closed"
      enter="input-transition"
      leave="input-transition"
    >
      <input
        id=""
        ref="inputField"
        v-model="searchValue"
        type="text"
        name=""
        :class="props.size ? `input-${props.size}` : 'input'"
        :placeholder="$t(placeholder)"
      />
    </TransitionRoot>
    <button
      class="input-group-text btn btn-secondary p-0"
      :class="props.size ? `btn-${props.size}` : ''"
      @click="handleSearchClick"
    >
      <i class="far fa-search" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
};
</script>

<style scoped lang="scss">
.closed {
  width: 0;
  padding: 0;
  overflow: hidden;
}

input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.open {
  width: calc(100% - 32px);

  &-lg {
    width: calc(100% - 42px);
  }

  &-sm {
    width: calc(100% - 28px);
  }
}

.input-transition {
  transition: all 0.2s ease;
  overflow: hidden;
}

.input-group-text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  &.btn-lg {
    width: 42px;
    height: 42px;
  }
}
</style>
