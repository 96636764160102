<script setup>
import { useCheckupStore } from '../../stores/checkup';
import { computed, defineProps, inject, provide, ref } from 'vue';
// import Card from '../../views/components/simple/Card';
import { useWindowSize } from '@vueuse/core/index';
import VitaminsDropDown from './VitaminsDropDown';
import CheckupNavigation from './CheckupNavigation';
import { onBeforeRouteLeave } from 'vue-router';
import DoubleCard from '../../views/components/simple/DoubleCard';
import { useMainStore } from '../../stores';

// Props
defineProps({
  hasAnsweredAll: {
    type: Boolean,
    default: false,
  },
});

// Variables
const { width } = useWindowSize(); // Get the current window width
const checkupStore = useCheckupStore();
const mainStore = useMainStore();
const showNotes = ref(false); // Show or hide the notes on a mobile screen
const vitamins = inject('vitamins');
const activeVitamin = computed(() => {
  return vitamins.value.vitamins[checkupStore.activeIndex.vitamin];
}); // Get the active vitamin
const activeFactor = computed(() => {
  return activeVitamin.value.factors[checkupStore.activeIndex.factor];
}); // Get the active factor
const activeQuestion = computed(() => {
  return activeFactor.value.questions[
    checkupStore.activeIndex.question
  ];
}); // Get the active question
const activeNote = computed(() => {
  return checkupStore.notes.find(
    (note) =>
      note.index ===
      `${checkupStore.activeIndex.vitamin}#${checkupStore.activeIndex.factor}`
  );
}); // Get the active note
const card = ref(null);

// Functions
/**
 * Get the localized term from an object
 * @param item - Object to get the localization from
 * @param key - Key to localize
 * @param locale - Locale to use
 * @returns {String} - Localized term
 */
const localize = (item, key, locale) => {
  if (locale.includes('en')) {
    return item[key];
  }
  return item[key + '_' + locale];
};
provide('localize', localize);

/**
 * Skip to a selected vitamin
 * @param index
 */
const goToVitamin = (index) => {
  showNotes.value = false;
  checkupStore.setActiveIndex({
    vitamin: index,
    factor: 0,
    question: 0,
  });
};

/**
 * Create an index of the current factor and vitamin to check if notes need to be shown
 * @param index
 * @returns {number}
 */
const notesIndex = (index) =>
  parseInt((index.vitamin * 10).toString() + index.factor.toString());

/**
 * On mobile, move to the next question if the user selected an answer
 */
const handleMobileAnswer = () => {
  if (width.value < 768) {
    goToQuestion(checkupStore.getNextIndex);
  }
};

/**
 * Go to next or previous question
 * @param index
 */
const goToQuestion = (index) => {
  // Submit the current state to the server
  checkupStore.submit();
  /**
   * If the user goes to a different vitamin or factor, show the notes on a mobile screen
   */
  if (
    checkupStore.activeIndex.factor !== index.factor &&
    !showNotes.value &&
    width.value <= 768
  ) {
    /**
     * Move the index if the user goes back a vitamin/factor
     */
    if (
      notesIndex(checkupStore.activeIndex) > notesIndex(index) &&
      Object.values(index).every((i) => i >= 0)
    ) {
      checkupStore.setActiveIndex(index);
    }
    card.value.setOpen(true);
    showNotes.value = true;
  } else {
    if (showNotes.value) {
      /**
       * Move the index after the notes if the user goes forward a vitamin/factor
       */
      if (
        notesIndex(checkupStore.activeIndex) < notesIndex(index) ||
        Object.values(index).some((i) => i < 0)
      ) {
        checkupStore.setActiveIndex(index);
      }
      showNotes.value = false;
      card.value.setOpen(false);
      return;
    }
    /**
     * If there is no next index, go to the end card
     */
    if (Object.values(index).every((i) => i < 0)) {
      checkupStore.isdone = true;
      return;
    }
    checkupStore.setActiveIndex(index);
  }
};

/**
 * Submit before leaving the page
 */
onBeforeRouteLeave(() => {
  checkupStore.submit();
});
</script>

<template>
  <DoubleCard
    v-if="vitamins"
    ref="card"
    :color="activeVitamin.color"
  >
    <template #leftHeader>
      <div
        class="d-flex align-items-center"
        :style="`margin-left: -1rem; height: 40px`"
      >
        <VitaminsDropDown
          :color="activeVitamin.color"
          @goToVitamin="goToVitamin"
        />
        <span class="ps-3 text-sm overline"
          >{{
            $t('vitamins.active_title', {
              index: checkupStore.activeIndex.vitamin + 1,
              total: vitamins.vitamins.length,
              title: localize(activeVitamin, 'title', $i18n.locale),
            })
          }}
        </span>
      </div>
    </template>
    <template #left>
      <!--      <div class="col-md-6">
        <div class="btn-group d-flex mb-2 w-100">
          <button
            class="btn btn-sm btn-secondary w-100 text-sm"
            :class="!checkupStore.skipAnswered && 'active'"
            @click="checkupStore.skipAnswered = false"
          >
            {{ $t('message.all_questions') }}
          </button>
          <button
            class="btn btn-sm w-100 btn-secondary text-sm"
            :class="checkupStore.skipAnswered && 'active'"
            @click="checkupStore.skipAnswered = true"
          >
            {{ $t('message.unanswered_only') }}
          </button>
        </div>
      </div>-->
      <h5
        :class="[`c-${activeVitamin.color}`, 'c-200 text-sentence']"
      >
        {{ localize(activeFactor, 'title', $i18n.locale) }}
      </h5>
      <p style="min-height: 4rem">
        {{
          $t('vitamins.checkup_question', {
            index: checkupStore.activeIndex.question + 1,
            question: localize(activeQuestion, 'title', $i18n.locale),
          })
        }}
      </p>
      <ul
        class="checkup-question-options list-group list-group-flush"
      >
        <template
          v-for="scale in activeFactor.scalegroup.scaleoptions"
          :key="scale.id"
        >
          <li class="d-block w-100 px-0 py-0 list-group-item">
            <label
              class="cursor-pointer w-100 text-md py-3 mb-0 text-sentence"
            >
              <input
                v-model="
                  checkupStore.answers[checkupStore.getActiveAnswer]
                    .value
                "
                type="radio"
                class="me-2"
                :name="`question-${activeQuestion.id}`"
                :value="scale.value"
                @change="handleMobileAnswer"
              />
              <span class="text-sentence">{{
                $t(`message.${scale.title}`)
              }}</span>
            </label>
          </li>
        </template>
      </ul>
      <CheckupNavigation
        :show-previous="
          Object.values(checkupStore.activeIndex).some(
            (item) => item > 0
          )
        "
        @previous="goToQuestion(checkupStore.getPreviousIndex)"
        @next="goToQuestion(checkupStore.getNextIndex)"
      />
    </template>
    <template #right>
      <p
        class="text-sentence"
        style="height: 6rem"
      >
        {{
          $t(
            'message.wil_je_voor_jezelf_iets_noteren_in_verband_met',
            {
              title: localize(activeFactor, 'title', $i18n.locale),
            }
          )
        }}
      </p>
      <textarea
        v-model="activeNote.content"
        class="w-100"
        cols="30"
        rows="10"
        :placeholder="$t('message.noteer_hier')"
      />
      <!--    Overcomplicated checkbox    -->
      <template v-if="!mainStore.setting('hidefeedback')">
        <input
          id="notePrivacy"
          v-model="activeNote.isShared"
          class="invisible styled-checkbox"
          type="checkbox"
          name="notePrivacy"
        />
        <label
          for="notePrivacy"
          class="d-inline-block cursor-pointer text-sentence"
        >
          <img
            v-show="activeNote.isShared"
            src="../../assets/icons/icon-toggle-check-box.svg"
            alt=""
          />
          <img
            v-show="!activeNote.isShared"
            src="../../assets/icons/icon-toggle-check-box_off.svg"
            alt=""
          />
        </label>
        <span class="text-sm text-sentence">{{
          $t(
            'message.ik_wil_deze_notities_delen_en_bespreken_met_mijn_manager'
          )
        }}</span>
      </template>

      <!--   End overcomplicated checkbox     -->
      <CheckupNavigation
        class="d-md-none"
        :show-previous="
          Object.values(checkupStore.activeIndex).some(
            (item) => item > 0
          )
        "
        @previous="goToQuestion(checkupStore.getPreviousIndex)"
        @next="goToQuestion(checkupStore.getNextIndex)"
      />
    </template>
  </DoubleCard>
</template>

<script>
export default {
  name: 'Questions',
};
</script>

<style scoped lang="scss">
@media all and (max-width: 767px) {
  .checkup-question-options {
    .list-group-item {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
</style>
