<template>
  <div
    class="modal-content"
    style="z-index: 1"
  >
    <div class="modal-header bgc-groengrijs">
      <div
        class="title col overline text-sm d-flex align-items-center"
      >
        <span class="mx-4">{{ $t('message.calendar') }}</span>
      </div>
      <div class="nav col-auto">
        <ul class="nav nav-pills p-0">
          <li class="nav-item dropdown p-0">
            <a
              class="nav-link p-0"
              data-bs-toggle="dropdown"
              href="#"
              role="button"
              aria-expanded="false"
            >
              <img
                src="../../../assets/icons/Icon-navigation-More-vertical.svg"
                alt=""
              />
            </a>
            <ul class="dropdown-menu">
              <li>
                <button
                  type="button"
                  class="dropdown-item"
                  @click="createICSFile"
                >
                  <i class="far fa-calendar" />
                  <span class="text-sentence">{{
                    $t('message.addToPersonalCalendar')
                  }}</span>
                  (.ics)
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <button
        type="button"
        class="d-block ms-auto btn-close"
        aria-label="Close"
        @click="closeModal"
      />
    </div>
    <div class="modal-body bg-white card card-rounded calendar-card">
      <Calendar />
    </div>
  </div>
  <div
    class="w-100 h-100 d-flex position-fixed"
    style="
      background-color: rgba(177, 177, 177, 0.25);
      top: 0;
      left: 0;
    "
  />
</template>

<script>
import Calendar from '@/components/Calendar';
import qs from 'qs';
import moment from 'moment';
import { mapActions } from 'pinia';
import { useModalStore } from '../../../stores/modal';

export default {
  name: 'CalendarModal',
  components: {
    Calendar,
  },
  methods: {
    ...mapActions(useModalStore, ['closeModal']),
    getCalEvent(checkup) {
      return {
        id: checkup.id,
        title: checkup.title,
        display: 'block',
        allDay: true,
        body: checkup.body,
        grace: checkup.graceperiod,
        duration: {
          days: checkup.duration /*+ checkup.graceperiod*/,
        },
        backgroundColor: this.getColorCode(checkup.color),
        borderColor: this.getColorCode(checkup.color),
        checkup: checkup,
      };
    },
    async getCalendarEvents(start, end) {
      let _this = this;
      const q = qs.stringify({
        _where: {
          _or: [
            {
              start_lt: end,
              end_gte: start,
            },
            {
              start_gte: start,
              start_lte: end,
            },
          ],
        },
      });
      const get = await this.$axios.get('/checkupentries?' + q);
      const entries = get.data;
      const events = entries.map((entry) => {
        let event = _this.getCalEvent(entry.checkup);
        event.start = entry.start;
        event.end = entry.end;
        return event;
      });
      return events;
    },
    async createICSFile() {
      // Get all events for the rest of the year
      const events = await this.getCalendarEvents(
        moment().startOf('day').toISOString(),
        moment().endOf('year').toISOString()
      );
      // Start ICS file
      let file =
        'BEGIN:VCALENDAR\r\n' +
        'CALSCALE:GREGORIAN\r\n' +
        'METHOD:PUBLISH\r\n' +
        'PRODID:-//Vitamins//EN\r\n' +
        'VERSION:2.0\r\n';

      for (const event of events) {
        // Get correct dates
        const start = moment(event.start)
          .utc(true)
          .toISOString()
          .split('T')[0]
          .replace(/[-]/g, '');
        const end = moment(event.end)
          .startOf('day')
          .utc(true)
          .toISOString()
          .split('T')[0]
          .replace(/[-]/g, '');
        // Add events to ICS file
        file +=
          'BEGIN:VEVENT\r\n' +
          `UID:vitamin-${event.id}\r\n` +
          'DTSTART;VALUE=DATE:' +
          start +
          '\r\n' +
          'DTEND;VALUE=DATE:' +
          end +
          '\r\n' +
          'SUMMARY:Checkup: ' +
          event.title +
          '\r\n' +
          'DESCRIPTION:' +
          event.body +
          '\r\n' +
          'END:VEVENT\n';
      }

      // End ICS file
      file += 'END:VCALENDAR';

      // Create file and download it
      let data = new File([file], 'vitamins.ics', {
        type: 'text/plain',
      });
      const icsFile = window.URL.createObjectURL(data);

      const link = document.createElement('a');
      link.setAttribute('href', icsFile);
      link.setAttribute('download', 'vitamins.ics');
      document.body.appendChild(link);
      link.click();

      // Remove file after download
      window.URL.revokeObjectURL(icsFile);
      document.body.removeChild(link);
    },
  },
  data() {
    return {};
  },
  // props: [
  //   'color'
  // ],
};
</script>

<style scoped></style>
