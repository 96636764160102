<script setup>
import { provide } from "vue";

provide("ListGroup", Symbol("ListGroup"));
</script>

<template>
  <ul class="list-group list-group-flush">
    <slot />
  </ul>
</template>

<script>
export default {
  name: "ListGroup",
};
</script>

<style scoped></style>
