<script setup>
import Paginated from '@/components/pagination/Paginated';
import { computed, inject, watch } from 'vue';
import { useAsyncState } from '@vueuse/core';
import { useModalStore } from '../../stores/modal';
import NewMessageModal from '../../views/components/modals/NewMessageModal';
import ActionModal from '../../views/components/modals/ActionModal';
import moment from 'moment';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/vue';
import ConfirmDelete from '../../views/components/modals/ConfirmDelete';
import { useI18n } from 'vue-i18n';
import { useMainStore } from '../../stores';

const props = defineProps({
  swarms: {
    type: Array,
    required: true,
  },
  canCreate: {
    type: Boolean,
    default: false,
  },
});

const store = useMainStore();
const axios = inject('axios');
const { state, execute } = useAsyncState(
  () =>
    axios
      .request({
        url: '/messages',
        method: 'GET',
        params: {
          teamid_in: props.swarms.map((swarm) => swarm.id),
        },
      })
      .then((res) => res.data),
  []
);

const isCompany = computed(() =>
  props.swarms.every((swarm) => swarm.isCompany)
);

const modalStore = useModalStore();
const openMessageModal = (item) => {
  modalStore.openModal({
    component: NewMessageModal,
    properties: {
      id: item?.id || null,
      teams: props.swarms,
      type: isCompany.value ? 'company' : 'team',
    },
    options: {},
    events: {
      submitModal: execute,
    },
  });
};

const openActionModal = (item) => {
  modalStore.openModal({
    component: ActionModal,
    properties: {
      id: item?.id || null,
      teams: props.swarms,
      type: isCompany.value ? 'company' : 'team',
    },
    options: {},
    events: {
      submitModal: execute,
    },
  });
};

const changeStatus = async (action) => {
  await axios.request({
    url: `/actions/${action.id}`,
    method: 'PUT',
    data: {
      completed: !action.completed,
    },
  });
  execute();
};

const { t } = useI18n();
const confirmDelete = async (type, item) => {
  modalStore.openModal({
    component: ConfirmDelete,
    properties: {
      id: item.id,
      action: 'message.delete',
      title:
        type === 'message'
          ? t('messages.this_message')
          : t('actions.this_action'),
    },
    options: {},
    events: {
      submitModal: () => {
        deleteItem(type, item.id);
      },
    },
  });
};
const deleteItem = async (type, id) => {
  await axios.request({
    url: `/${type == 'message' ? 'messages' : 'actions'}/${id}`,
    method: 'DELETE',
  });
  execute();
};

watch(() => props.swarms, execute);
</script>

<template>
  <div
    v-if="canCreate"
    class="d-flex justify-content-end gap-2"
  >
    <button
      class="btn btn-sm btn-primary"
      @click="openActionModal"
    >
      {{ $t('tree.new', { type: $t('message.action') }) }}
    </button>
    <button
      class="btn btn-sm btn-primary"
      @click="openMessageModal"
    >
      {{ $t('tree.new', { type: $t('message.message') }) }}
    </button>
  </div>
  <Paginated
    :content="state"
    :count-request="state.length"
  >
    <template #content="{ content }">
      <div
        v-for="item in content"
        :key="item.id"
        class="border rounded-3 px-4 py-2 my-2"
      >
        <div class="d-flex justify-content-between">
          <component
            :is="item.completed ? 'del' : 'div'"
            :class="[item.completed && 'text-theme--500']"
          >
            <div
              class="message-content"
              v-html="item.body"
            />
          </component>
          <div
            v-if="canCreate"
            class="position-relative"
          >
            <Menu>
              <MenuButton class="btn btn-link p-0">
                <i class="fa-fw fas fa-ellipsis-v" />
              </MenuButton>
              <MenuItems
                class="position-absolute bg-white border shadow mt-1 p-1 d-flex gap-1 flex-column align-items-end"
                style="right: 0; z-index: 12"
              >
                <MenuItem
                  v-if="item.type === 'action'"
                  v-slot="{ active }"
                >
                  <button
                    :class="[
                      active
                        ? 'bg-theme--200 text-theme--800'
                        : 'bg-transparent',
                    ]"
                    class="w-100 border-0 text-nowrap px-2 py-1 rounded"
                    style="text-align: start"
                    @click="changeStatus(item)"
                  >
                    {{
                      item.completed
                        ? $t('actions.mark_as_incomplete')
                        : $t('actions.mark_as_completed')
                    }}
                  </button>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <button
                    :class="[
                      active
                        ? 'bg-theme--200 text-theme--800'
                        : 'bg-transparent',
                    ]"
                    class="w-100 border-0 text-nowrap px-2 py-1 rounded"
                    style="text-align: start"
                    @click="
                      item.type === 'action'
                        ? openActionModal(item)
                        : openMessageModal(item)
                    "
                  >
                    {{ $t('message.edit') }}
                  </button>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <button
                    :class="[
                      active
                        ? 'bg-theme--200 text-theme--800'
                        : 'bg-transparent',
                    ]"
                    class="w-100 border-0 text-nowrap px-2 py-1 rounded"
                    style="text-align: start"
                    @click="confirmDelete(item.type, item)"
                  >
                    {{ $t('message.delete') }}
                  </button>
                </MenuItem>
              </MenuItems>
            </Menu>
          </div>
        </div>
        <div class="d-flex align-items-center gap-1 text-theme--500">
          <span
            class="text-sm"
            :class="[
              +item.teamid
                ? 'text-blue--dark'
                : 'text-orange-yellow--dark',
            ]"
            >{{ item.author.firstname }}
            {{ item.author.lastname }}</span
          >
          &bull;<span class="text-sm">{{
            item.swarm || store.companyName
          }}</span
          >&bull;<span class="text-sm">{{
            $t(`message.${item.type}`)
          }}</span
          >&bull;<span class="overline text-sm">{{
            moment(item.created_at).format('DD/MM/YYYY HH:mm')
          }}</span>
        </div>
      </div>
    </template>
  </Paginated>
</template>

<script>
export default {
  name: 'MessageList',
};
</script>

<style lang="scss">
p + p {
  margin-top: 0.5rem;
}
p {
  margin-bottom: 0 !important;
}
</style>
