<template>
  <div
    id="container"
    class="w-100 row m-0"
  >
    <Card
      :color="`${color}--dark`"
      :color-shade="200"
      :padding="padding[0]"
      class="px-0 card-container"
      :class="{
        'd-none d-xl-block': !stacked && open,
        'mb-xl-0 stacked': stacked,
        [`col-xl-${size} col-12 border-end hasEnd`]: $slots.right,
        ['col-xl-12 col-12']: !$slots.right,
      }"
    >
      <template #header>
        <slot name="leftHeader">
          <span class="overline text-sm">{{ title }}</span>
        </slot>
      </template>
      <slot
        name="left"
        :methods="{ setOpen }"
      />
    </Card>
    <Card
      v-if="$slots.right"
      :color="`${color}--light`"
      :color-shade="50"
      :padding="padding[1]"
      class="rounded-end w-100 px-0 card-container"
      :class="{
        'd-none d-xl-block': !stacked && !open,
        stacked: stacked,
        [`col-xl col-auto`]: true,
        hasEnd: $slots.right,
      }"
    >
      <template #header>
        <template v-if="stacked">
          <slot name="rightHeader">
            <span class="overline text-sm">{{ rightTitle }}</span>
          </slot>
        </template>
        <template v-else>
          <span
            class="overline"
            @click="setOpen(false)"
          >
            <span class="d-xl-none">
              <button class="btn btn-link p-0">
                &lt;
                <span class="text-sentence">{{
                  $t('message.goBack')
                }}</span>
              </button>
            </span>
          </span>
        </template>
      </template>
      <slot name="right" />
    </Card>
  </div>
</template>

<script>
import Card from '@/views/components/simple/Card';
/**
 * Double card. Used on pages whith a book-like layout (left card and right card) e.g. /tree, /feedback.
 * Has props for header title and color. Uses dark shade on the left side and light shade on the right
 */
export default {
  name: 'DoubleCard',
  components: { Card },
  provide() {
    return {
      cardApi: {
        setOpen: this.setOpen,
      },
    };
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    rightTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'groen',
    },
    size: {
      type: Number,
      default: 6,
      validator(val) {
        return val >= 1 && val <= 12;
      },
    },
    padding: {
      type: Array,
      default() {
        return [4, 4];
      },
    },
    stacked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    setOpen(state) {
      this.open = state;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-up(xl) {
  #container :deep(.card) {
    &.hasEnd {
      &:first-child {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;

        & .card-header:first-child {
          border-bottom-right-radius: 0 !important;
          border-top-right-radius: 0 !important;
        }
      }

      &:last-child {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;

        & .card-header:first-child {
          border-bottom-left-radius: 0 !important;
          border-top-left-radius: 0 !important;
        }
      }
    }
  }
}

.card-container {
  box-shadow: none !important;
}

#container {
  box-shadow: 0 0.5rem 1rem rgba($black, 0.15) !important;

  :deep(.card) {
    &.stacked {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

@include media-breakpoint-down(xl) {
  .card-container {
    box-shadow: 0 0.5rem 1rem rgba($black, 0.15) !important;
  }

  #container {
    box-shadow: none !important;
  }
}
</style>
