<script setup>
import { useAsyncState } from '@vueuse/core';
import { useRoute, useRouter } from 'vue-router';
import { computed, inject, provide, watch } from 'vue';
import { useCheckupStore } from '../stores/checkup';
import moment from 'moment';
import Questions from '../components/checkup/Questions';
import CheckupProgress from '../components/checkup/CheckupProgress';
import CheckupEndCard from '../components/checkup/CheckupEndCard';
import { useAuthStore } from '../stores/auth';
import CheckupFeedback from '../components/checkup/CheckupFeedback';

const route = useRoute();
const router = useRouter();
const checkupStore = useCheckupStore();
const authStore = useAuthStore();
const axios = inject('axios');

/**
 * Fetch the current checkup and submission from the server
 */
const { state, isReady, isLoading } = useAsyncState(() => {
  const { id } = route.params;
  return Promise.all([
    axios.get(`/checkups/${id}/vitamins`).then((res) => res.data),
    axios
      .get(`/checkupsubmissions?checkupentry=${id}`)
      .then((res) => res.data),
  ]).then(([vitamins, submissions]) => {
    // Do not return data if checkup is finished
    if (!vitamins || moment(vitamins.grace).isBefore(moment())) {
      return false;
    }
    // Set the submission store
    checkupStore.setSubmission(submissions[0], vitamins, id);
    return vitamins;
  });
}, {});

// Watch state to see if redirect is needed
watch(state, () => {
  if (!state.value) {
    router.push('/vitamins');
  }
});

// Set computed values
const hasAnsweredAll = computed(() => {
  return (
    state?.value?.questionCount ===
      checkupStore.getAnsweredQuestions?.length || false
  );
});

// Provide vitamins to child components
provide('vitamins', state);

// Function to go back to questions to finish
const goToQuestions = () => {
  checkupStore.skipAnswered = true;
  checkupStore.isdone = false;
  checkupStore.setActiveIndex(checkupStore.getUnansweredIndex);
};
</script>

<template>
  <div
    v-if="isLoading"
    class="w-100 h-100 d-flex justify-content-center align-items-center"
  >
    <div
      class="spinner-border"
      role="status"
    >
      <span class="visually-hidden"
        >{{ $t('message.loading') }}...</span
      >
    </div>
  </div>
  <div v-else>
    <div
      v-if="isReady"
      class="page-checkup mb-3"
    >
      <div
        class="mb-md-3 checkup-top d-flex justify-content-between align-items-center"
      >
        <i18n-t
          keypath="vitamins.title"
          tag="h2"
          class="d-none d-md-block text-sentence"
        >
          <template #for>
            <em>{{ $t('message.for') }}</em>
          </template>
          <template #user>
            <span>{{ authStore.fullName }}</span>
          </template>
        </i18n-t>
        <CheckupProgress class="w-25 d-none d-md-block" />
      </div>
      <Questions
        v-if="!checkupStore.isdone"
        ref="questionBlock"
        :has-answered-all="hasAnsweredAll"
      />
      <div
        v-else-if="hasAnsweredAll && checkupStore.postfinish"
        class="row"
      >
        <!--   Checkup feedback     -->
        <div class="col-md-6">
          <CheckupFeedback />
        </div>
      </div>
      <div
        v-else
        class="row"
      >
        <div class="col-md-6">
          <CheckupEndCard
            :has-answered-all="hasAnsweredAll"
            @finish-questions="goToQuestions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckupForm',
};
</script>

<style scoped></style>
