<script setup>
import Toast from './Toast';
import { useMainStore } from '../../stores';

const store = useMainStore();
</script>

<template>
  <Teleport to="body">
    <transition-group
      name="fade-list"
      tag="div"
      class="position-fixed bottom-0 end-0 me-4 ms-auto my-4 col-4"
      style="z-index: 8"
    >
      <div
        v-for="(item, i) in store.toasts"
        :key="`toast-${i}`"
        class="fade-list-item"
      >
        <Toast :message="item" />
      </div>
    </transition-group>
  </Teleport>
</template>

<style scoped lang="scss">
.fade-list-enter,
.fade-list-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

.fade-list-leave,
.fade-list-enter-to {
  opacity: 1;
  transform: scale(1);
}

.fade-list-item {
  margin-bottom: 8px;
  z-index: 9999;

  &:last-child {
    margin-bottom: 0;
  }
}

.fade-list-item,
.fade-list-leave-active,
.fade-list-enter-active {
  transition: all 0.3s ease-in-out;
}

.fade-list-move {
  transition: transform 0.3s ease-in-out;
}
</style>
