<template>
  <div class="modal-content">
    <Card
      color="groengrijs"
      :color-shade="50"
    >
      <template #header>
        <span>{{
          $t(
            isManager
              ? 'message.plan_meeting'
              : 'message.vraag_gesprek_aan'
          )
        }}</span>
        <button
          tabindex="-1"
          type="button"
          class="d-block ms-auto btn-close"
          aria-label="Close"
          @click.prevent="close"
        />
      </template>
      <form @submit.prevent="onSubmit">
        <div class="d-block mb-4">
          <label for="onderwerp">{{ $t('message.subject') }}</label>
          <input
            id="onderwerp"
            v-model="form.subject"
            required
            type="text"
            class="w-100 input-lg"
          />
        </div>
        <div class="d-flex justify-content-between mb-4">
          <div class="w-100 me-2">
            <label for="user">{{ $t('message.user') }}</label>
            <input
              v-if="form.user"
              id="user"
              required
              type="text"
              class="w-100 input-lg"
              disabled
              :value="`${form.user.firstname} ${form.user.lastname}`"
            />
          </div>
          <div class="w-100">
            <label for="manager">{{ $t('message.manager') }}</label>
            <select
              id="manager"
              v-model="form.manager"
              class="w-100 input-lg"
              required
            >
              <option
                v-for="manager in availableManagers"
                :key="`manager-${manager.id}`"
                :value="manager.id"
              >
                {{ `${manager.firstname} ${manager.lastname}` }}
              </option>
            </select>
          </div>
        </div>
        <div
          v-if="isManager"
          class="mb-4"
        >
          <label for="date">{{ $t('message.date') }}</label>
          <input
            id="date"
            v-model="form.date"
            required
            type="datetime-local"
            class="w-100 input-lg"
          />
        </div>
        <div class="d-flex justify-content-end">
          <!--          <button-->
          <!--            @click="cancelMeeting"-->
          <!--            type="button"-->
          <!--            data-bs-dismiss="modal"-->
          <!--            aria-label="Cancel"-->
          <!--            class="btn btn-link text-danger me-2"-->
          <!--          >-->
          <!--            {{ $t("message.cancel") }}-->
          <!--          </button>-->
          <button
            :disabled="isLoading"
            class="btn btn-primary"
          >
            <div
              v-if="isLoading"
              style="height: 12px; width: 12px"
              class="spinner-border"
              role="status"
            >
              <span class="visually-hidden">{{
                $t('message.loading')
              }}</span>
            </div>
            {{ $t('message.save') }}
          </button>
        </div>
      </form>
    </Card>
  </div>
</template>
<script>
import { mapActions, mapState } from 'pinia';
import { useModalStore } from '../../../stores/modal';
import Card from '../simple/Card';
import { useAuthStore } from '../../../stores/auth';
import moment from 'moment';
import _ from 'lodash';

/**
 * Modal used to plan a meeting based on a checkup submission or spontaneous
 * TODO: Spontaneous meeting request
 */
export default {
  name: 'PlanningModal',
  components: { Card },
  props: [
    'meetingUser',
    'submission',
    'meeting',
    'isManager',
    'overrideClose',
  ],
  emits: ['submitModal', 'closeModal'],
  data() {
    return {
      form: {
        subject: '',
        manager: '',
        user: this.meetingUser || null,
        date: null,
      },
      isLoading: false,
      availableManagers: [],
    };
  },
  computed: {
    ...mapState(useAuthStore, ['user']),
  },
  async mounted() {
    if (!this.meetingUser?.id) {
      const { data: user } = await this.$axios.get(
        `/users/${this.meetingUser}`
      );
      this.form.user = user;
    }
    if (this.meeting) {
      this.form = {
        ...this.meeting,
        manager: this.meeting.manager.id,
        date: moment(this.meeting.date).format('YYYY-MM-DDTHH:mm'),
      };
    } else if (this.submission) {
      this.form.subject =
        this.submission?.checkupentry?.checkup?.title || '';
    }
    await this.getAvailableManagers();

    if (this.isManager) {
      this.form.manager = this.availableManagers.find(
        (manager) => manager.id === this.user.id
      ).id;
    }
  },
  methods: {
    ...mapActions(useModalStore, ['closeModal']),
    close() {
      if (this.overrideClose) {
        this.$emit('closeModal');
      } else {
        this.closeModal();
      }
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        this.form.isRequest = this.meeting ? !this.form.date : true;
        await this.$axios.request({
          method: this.form.id ? 'put' : 'post',
          url: this.form.id
            ? `/meetings/${this.form.id}`
            : '/meetings',
          data: {
            ...this.form,
            checkupsubmission: this.submission?.id || null,
          },
        });
        this.$emit('submitModal');
        this.close();
      } catch (error) {
        console.error(error);
      }
    },
    async getAvailableManagers() {
      const { data } = await this.$axios.get(
        `/swarms/zerglings?_user=${this.form.user.id}`
      );
      this.availableManagers = _.uniqBy(
        data.flatMap((item) => item.overlords),
        'id'
      );
    },
  },
};
</script>

<style scoped></style>
