<script setup>
import Card from '../../views/components/simple/Card';
import { reactive, ref, inject, onMounted } from 'vue';

const props = defineProps({
  checkup: {
    type: String,
    required: true,
  },
  refId: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
});

const answerOptions = {
  1: 'Helemaal oneens',
  2: 'Oneens',
  3: 'Eerder oneens',
  4: 'Noch eens, noch oneens',
  5: 'Eerder eens',
  6: 'Eens',
  7: 'Helemaal eens',
};

const questions = ['useful', 'recognise', 'understand'];

const submission = reactive({
  useful: null,
  recognise: null,
  understand: null,
});

const axios = inject('axios');
onMounted(async () => {
  const { data: result } = await axios.get('/feedbacks/response', {
    params: {
      checkup: props.checkup,
      refId: props.refId,
      type: props.type,
    },
  });

  if (result) {
    submission.useful = result.useful;
    submission.recognise = result.recognise;
    submission.understand = result.understand;
  }
});

const isSubmitting = ref(false);

const setOpen = inject('setOpen');
const submitForm = async () => {
  isSubmitting.value = true;
  try {
    await axios.post('/feedbacks/response', {
      ...props,
      ...submission,
    });
    setOpen(false);
  } catch (error) {
    console.error(error);
  } finally {
    isSubmitting.value = false;
  }
};
</script>

<template>
  <Card color="cambridge-blue--light">
    <template #header>
      <span>{{ $t('dashboard.send_feedback') }}</span>
    </template>
    <form
      class=""
      @submit.prevent="submitForm"
    >
      <div
        v-for="(question, index) in questions"
        :key="`question-${index}`"
        class="mb-4"
      >
        <span class="mb-1">{{
          $t(`personal_feedback.questions.${question}`)
        }}</span>
        <div>
          <label
            v-for="(option, key) in answerOptions"
            :key="`answer-${index}-${key}`"
            class="d-flex align-items-center gap-2"
            ><input
              id=""
              v-model="submission[question]"
              type="radio"
              :name="`answer-${index}`"
              class="input"
              :value="key"
              required
            /><span>{{ $t(`message.${option}`) }}</span></label
          >
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button class="btn btn-primary">
          {{ $t('message.send') }}
        </button>
      </div>
    </form>
  </Card>
</template>
