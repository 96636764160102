<script setup>
import ProgressChart from '@/views/components/ProgressChart';
import { computed, inject } from 'vue';
import ListGroupItem from '../../components/ListGroup/ListGroupItem';
import ListGroup from '../../components/ListGroup/ListGroup';
import moment from 'moment';
import { useModalStore } from '../../stores/modal';
import SubmissionModal from './modals/SubmissionModal';
import ConfirmDelete from './modals/ConfirmDelete';
import { useI18n } from 'vue-i18n';
import ShareSubmissionModal from '../../components/modal/ShareSubmissionModal';
import { useAuthStore } from '../../stores/auth';
import { useMainStore } from '../../stores';

const props = defineProps({
  checkup: {
    type: Object,
    required: true,
  },
  submission: {
    type: Object,
    default() {
      return null;
    },
  },
  size: {
    type: String,
    default: 'sm',
    validator(value) {
      return ['sm', 'lg'].includes(value);
    },
  },
});

const emit = defineEmits(['refreshVitamins']);

const axios = inject('axios');
const { t } = useI18n();
const modalStore = useModalStore();

/**
 * Return if vitamin is linked to a checkup or not
 * @type {ComputedRef<boolean>}
 */
const hasCheckup = computed(() => {
  return !props.checkup.id.toString().includes('own');
});

const hasFinished = computed(() => {
  return props.submission?.isfinished || false;
});

const isClosed = computed(() => {
  return hasCheckup.value
    ? !moment().isBetween(
        moment(props.checkup.start),
        moment(props.checkup.deadline)
      )
    : false;
});

const completedPct = computed(() => {
  if (props.submission?.isfinished) {
    return 100;
  }
  return (
    (props?.submission?.submission.answeredCount /
      props.checkup.questionCount) *
      100 || 0
  );
});

const deleteSubmission = async (id) => {
  await axios.delete(`/checkupsubmissions/${id}`);
  emit('refreshVitamins');
};

const resetSubmission = async (id) => {
  await axios.put(`/checkupsubmissions/${id}/reset`);
  emit('refreshVitamins');
};

const confirmDelete = () => {
  modalStore.openModal({
    component: ConfirmDelete,
    options: {
      centered: true,
    },
    properties: {
      id: props.submission.id,
      title: t('vitamins.this_submission'),
    },
    events: {
      submitModal: deleteSubmission,
    },
  });
};

const confirmReset = () => {
  modalStore.openModal({
    component: ConfirmDelete,
    options: {
      centered: true,
    },
    properties: {
      id: props.submission.id,
      action: t('message.reset'),
      title: t('vitamins.this_submission'),
    },
    events: {
      submitModal: resetSubmission,
    },
  });
};

// const rejectPulse = async (id) => {
//   await axios.put(`/checkupentries/${id}/reject`);
//   emit('refreshVitamins');
// };

// const confirmSkip = () => {
//   modalStore.openModal({
//     component: ConfirmDelete,
//     options: {
//       centered: true,
//     },
//     properties: {
//       id: props.checkup.id,
//       action: t('message.skip'),
//       title: props.checkup.title,
//     },
//     events: {
//       submitModal: rejectPulse,
//     },
//   });
// };

const openSubmissionModal = () => {
  modalStore.openModal({
    component: SubmissionModal,
    options: {
      size: 'lg',
    },
    properties: {
      submissionId: props.submission.id,
    },
    events: {},
  });
};

const authStore = useAuthStore();
const togglePrivacy = async (state) => {
  if (!props.submission) return;
  if (
    !state &&
    authStore.user.member.filter(
      (swarm) => swarm.visibility && !swarm.issegment
    ).length
  ) {
    modalStore.openModal({
      component: ShareSubmissionModal,
      options: {
        centered: true,
        size: 'sm',
      },
      properties: {
        submissionId: props.submission.id,
      },
      events: {
        submitModal: async (manager) => {
          await axios.put(
            `/checkupsubmissions/${props.submission.id}`,
            {
              private: state,
              manager: manager,
            }
          );
          emit('refreshVitamins');
          modalStore.closeModal();
        },
      },
    });
  } else {
    await axios.put(`/checkupsubmissions/${props.submission.id}`, {
      private: state,
      manager: null,
    });
    emit('refreshVitamins');
  }
};

const mainStore = useMainStore();
</script>

<template>
  <div
    class="card h-100 checkup-submission-card"
    :class="isClosed ? '' : 'closed'"
  >
    <div
      class="card-header pe-1"
      :class="[
        isClosed
          ? `bg-${props.checkup.color}--light`
          : `bg-${props.checkup.color}`,
        `bgc-${props.checkup.color}`,
        isClosed && 'c-50',
      ]"
    >
      <div class="text-sm title flex-grow-1 overline">
        <template v-if="hasCheckup">
          <span v-if="moment().isAfter(props.checkup.start)"
            >{{ $t('message.checkupEnds') }}
            {{
              moment(props.checkup.deadline).format('DD/MM/YYYY')
            }}</span
          >
          <span v-else>
            {{ $t('message.checkupStarts') }}
            {{ moment(props.checkup.start).format('DD/MM/YYYY') }}
          </span>
        </template>

        <span v-else>{{
          moment(props.submission.updated_at).format('DD/MM/YYYY')
        }}</span>
      </div>
      <div
        v-if="
          submission &&
          (!hasCheckup ||
            (props.checkup.deadline
              ? moment().isBefore(moment(props.checkup.deadline))
              : false))
        "
        class="nav"
      >
        <ul class="nav nav-pills p-0">
          <li class="nav-item dropdown p-0">
            <a
              class="nav-link p-0"
              data-bs-toggle="dropdown"
              href="#"
              role="button"
              aria-expanded="false"
            >
              <img
                src="../../assets/icons/Icon-navigation-More-vertical.svg"
                alt=""
              />
            </a>
            <ul class="dropdown-menu">
              <li
                v-if="
                  submission &&
                  (props.checkup.deadline
                    ? moment().isBefore(
                        moment(props.checkup.deadline)
                      )
                    : true)
                "
              >
                <a
                  class="dropdown-item"
                  href="#"
                  @click="confirmReset"
                  ><i class="fas fa-undo me-2" /><span>{{
                    $t('vitamins.reset_submission')
                  }}</span></a
                >
              </li>
              <li v-if="!hasCheckup">
                <a
                  class="dropdown-item"
                  href="#"
                  @click="confirmDelete"
                  ><i class="far fa-trash-alt me-2" /><span>{{
                    $t('vitamins.delete_submission')
                  }}</span></a
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-body">
      <!--    Top body content for large checkup card  -->
      <template v-if="size === 'lg'">
        <h3
          class="text-center"
          :class="isClosed && 'text-disabled'"
        >
          {{
            hasCheckup
              ? props.checkup.title
              : $t('vitamins.own_vitamin')
          }}
        </h3>
        <div class="mx-auto checkup-progress-lg mb-4">
          <progress-chart
            class="h-100 w-100"
            :pct="completedPct"
            :border-width="6"
            :color="props.checkup.color"
            :f-size="'lg'"
          />
        </div>
        <p
          class="checkup-description"
          :class="isClosed && 'text-disabled'"
        >
          {{ props.checkup.description }}
        </p>
      </template>
      <!--     Top body content for small checkup card -->
      <template v-else>
        <h5
          class="checkup-title"
          :class="isClosed ? 'text-disabled' : ''"
        >
          {{
            hasCheckup
              ? props.checkup.title
              : $t('vitamins.own_vitamin')
          }}
        </h5>
      </template>
      <ListGroup
        class="checkup-details"
        :class="[isClosed ? 'text-disabled' : '']"
      >
        <template v-if="size === 'lg'">
          <ListGroupItem
            v-if="!isClosed"
            :label="$t('message.geschatte_duur')"
          >
            <template #icon>
              <i class="far fa-stopwatch" />
            </template>
            {{ props.checkup.duration }}'
          </ListGroupItem>
          <template
            v-if="props.submission && !hasFinished && !isClosed"
          >
            <ListGroupItem :label="$t('message.vragen_ingevuld')">
              <template #icon>
                <i class="fas fa-check-square" />
              </template>
              {{ props.submission.submission.answeredCount }}
            </ListGroupItem>
            <ListGroupItem :label="$t('message.nog_in_te_vullen')">
              <template #icon>
                <i class="far fa-square" />
              </template>
              {{
                props.checkup.questionCount -
                props.submission.submission.answeredCount
              }}
            </ListGroupItem>
          </template>
          <ListGroupItem
            v-if="props.submission && hasFinished"
            :label="$t('message.volledig_ingevuld')"
          >
            <template #icon>
              <i class="fas fa-check-square" />
            </template>
          </ListGroupItem>
          <ListGroupItem
            v-else-if="!props.submission"
            :label="$t('message.amt_vragen')"
          >
            <template #icon>
              <img
                src="../../assets/icons/Icon-survey-List.svg"
                alt=""
              />
            </template>
            {{ props.checkup.questionCount }}
          </ListGroupItem>
        </template>
        <template v-else>
          <ListGroupItem
            v-if="!isClosed && !hasFinished"
            :label="$t('message.nog_geen_inzendingen_gedetecteerd')"
          />
          <ListGroupItem
            v-else-if="isClosed && !hasFinished"
            :label="$t('message.deadline_voorbij_zonder_inzending')"
          />
        </template>
        <ListGroupItem
          v-if="
            props.submission && !mainStore.setting('hidefeedback')
          "
          :label="
            $t(
              props.submission.private
                ? 'message.je_antwoorden_zijn_prive'
                : 'message.je_antwoorden_zijn_zichtbaar'
            )
          "
        >
          <template #icon>
            <button
              v-if="props.submission.private"
              class="bg-transparent border-0 p-0"
              style="color: inherit"
              @click="togglePrivacy(false)"
            >
              <i
                class="fas fa-lock"
                style="font-size: 1.5rem"
              />
            </button>
            <button
              v-else
              class="bg-transparent border-0 p-0"
              style="color: inherit"
              @click="togglePrivacy(true)"
            >
              <i
                class="fas fa-unlock"
                style="font-size: 1.5rem"
              />
            </button>
          </template>
        </ListGroupItem>
        <ListGroupItem
          v-if="hasFinished"
          :label="$t('message.ingevuld_op')"
        >
          <template #icon>
            <i class="fas fa-calendar-check" />
          </template>
          {{
            moment(props.submission.updated_at).format('DD/MM/YYYY')
          }}
        </ListGroupItem>
        <ListGroupItem
          v-if="hasCheckup && moment().isAfter(props.checkup.start)"
          :label="
            $t(
              isClosed
                ? 'message.afgesloten'
                : 'message.resterende_dagen'
            )
          "
        >
          <template #icon>
            <i class="fas fa-calendar" />
          </template>
          <span v-if="!isClosed">{{
            moment(props.checkup.grace).diff(moment(), 'days')
          }}</span>
        </ListGroupItem>
        <ListGroupItem
          v-else-if="hasCheckup"
          :label="$t('message.starts')"
        >
          <template #icon>
            <i class="fas fa-calendar" />
          </template>
          <span>{{
            moment(props.checkup.start).format('DD/MM/YYYY')
          }}</span>
        </ListGroupItem>
      </ListGroup>
      <div
        class="d-flex align-items-center mt-2 gap-2"
        :class="
          size === 'lg'
            ? 'justify-content-center'
            : 'justify-content-between'
        "
      >
        <div
          v-if="size !== 'lg'"
          class="checkup-progress"
        >
          <progress-chart
            class="h-100 w-100"
            :pct="completedPct"
            :border-width="4"
            :f-size="'sm'"
            :color="props.checkup.color"
          />
        </div>
        <div class="d-flex gap-2">
          <router-link
            v-if="
              !isClosed &&
              (!props.submission || !props.submission.isfinished)
            "
            :to="{
              name: 'checkup',
              params: {
                id: props.checkup.id,
                checkup: JSON.stringify(props.checkup),
                submission: JSON.stringify(props.submission),
              },
            }"
          >
            <button
              class="btn btn-cta"
              :class="[
                isClosed ? 'btn-disabled' : 'btn-primary',
                `btn-cta-${props.checkup.color}`,
                size === 'lg' ? 'btn-lg' : '',
              ]"
            >
              <span
                v-if="
                  !isClosed &&
                  props.submission &&
                  !props.submission.isfinished
                "
                >{{ $t('message.afwerken') }}</span
              >
              <span v-else-if="!isClosed && !props.submission">{{
                $t('message.start')
              }}</span>
            </button>
          </router-link>
          <button
            v-else-if="
              authStore.hasDashboard &&
              props.submission &&
              props.submission.isfinished
            "
            class="btn btn-cta"
            :class="[
              isClosed ? 'btn-disabled' : 'btn-primary',
              `btn-cta-${props.checkup.color}`,
              size === 'lg' ? 'btn-lg' : '',
            ]"
            @click="openSubmissionModal"
          >
            <span>{{ $t('message.bekijken') }}</span>
          </button>
          <span
            v-else-if="
              !authStore.hasDashboard &&
              props.submission &&
              props.submission.isfinished
            "
            >{{ $t('message.thanks_for_submission') }}</span
          >
          <button
            v-else
            class="btn btn-link disabled"
            :class="[size === 'lg' ? 'btn-lg' : '']"
          >
            <i class="far fa-frown" />
          </button>
          <!-- <button
            v-if="
              !props.submission && props.checkup.isPulse && !isClosed
            "
            class="btn btn-lg rounded-lg btn-link text-danger"
            @click="confirmSkip"
          >
            {{ $t('message.skip') }}
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.checkup-progress-lg {
  width: 114px;
  height: 114px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkup-progress {
  height: 64px;
  width: 64px;
}
</style>
