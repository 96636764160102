<template>
  <div class="action-list">
    <div class="action-filters row">
      <div class="col-md-auto col-12 mb-md-4 mb-2">
        <!--        <search @search="handleSearch"></search>-->
        <SearchInput v-model="search" />
      </div>
      <div class="col-md-auto col-12 mb-md-4 mb-2">
        <div
          class="w-100 btn-group"
          role="group"
          aria-label="Basic example"
        >
          <button
            :class="status === 'all' ? 'active' : ''"
            type="button"
            class="btn btn-sm btn-secondary"
            @click="getActions('all')"
          >
            <span>{{ $t('message.all') }}</span>
          </button>
          <button
            :class="status === 'active' ? 'active' : ''"
            type="button"
            class="btn btn-sm btn-secondary"
            @click="getActions('active')"
          >
            <span>{{ $t('message.active') }}</span>
          </button>
          <button
            :class="status === 'completed' ? 'active' : ''"
            type="button"
            class="btn btn-sm btn-secondary"
            @click="getActions('completed')"
          >
            <span>{{ $t('message.completed') }}</span>
          </button>
        </div>
      </div>
      <div class="ms-auto col-auto mb-md-4 mb-2">
        <button
          class="btn btn-primary btn-sm"
          @click="openActionModal(null)"
        >
          <img
            class="hide-dark"
            src="../../assets/icons/icon-navigation-add-cropped.svg"
            alt=""
          />
          <img
            class="hide-light"
            src="../../assets/icons/icon-navigation-add-cropped-light.svg"
            alt=""
          />
          &nbsp;<span>{{ $t('message.action') }}</span>
        </button>
      </div>
    </div>
    <div class="actions-items">
      <paginated
        style="min-height: 150px"
        :request="request"
        :count-request="totalRows"
      >
        <template #content="{ content: actions }">
          <!--        <div v-if="actions.length < 1">-->
          <!--          <p class="text-center text-sentence d-block">{{$t('message.no_actions_available')}}</p>-->
          <!--        </div>-->
          <div
            v-for="action in actions"
            :key="action"
            class="rounded-8 action-item bgc-theme c-200 p-3 mb-2"
          >
            <div class="row">
              <div class="col-auto flex-shrink-0">
                <checkbox
                  v-model="action.completed"
                  @change="updateAction($event, action.id)"
                />
              </div>
              <div
                class="col"
                style="white-space: pre-line"
              >
                {{ action.body }}
              </div>
              <div
                v-if="action.visible"
                class="col-auto tip tip-shared"
              >
                <span class="text-sm tip-content text-sentence">
                  {{ $t('message.action_shared_with_team') }}
                </span>
                <i class="fas fa-user-friends" />
              </div>
              <div class="col-auto flex-shrink-0">
                <ul
                  v-if="
                    action.authorid == user.id ||
                    (user.manageteams && action.teamid) ||
                    user.managecompany
                  "
                  class="nav nav-pills p-0"
                >
                  <li class="nav-item dropdown p-0">
                    <a
                      class="nav-link p-0"
                      data-bs-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-expanded="false"
                    >
                      <img
                        src="../../assets/icons/Icon-navigation-More-vertical.svg"
                        alt=""
                      />
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item text-sentence"
                          href="#"
                          @click.prevent="openActionModal(action.id)"
                          >{{ $t('message.edit') }}</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item text-sentence"
                          href="#"
                          @click.prevent="handleDeleteAction(action)"
                          >{{ $t('message.delete') }}</a
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
                <div
                  v-else
                  style="width: 24px"
                />
              </div>
            </div>
          </div>
        </template>
      </paginated>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/views/components/simple/Checkbox';
import ActionModal from '@/views/components/modals/ActionModal';
import Paginated from '@/components/pagination/Paginated';
import NewMessageModal from '@/views/components/modals/NewMessageModal';
import ConfirmDelete from '@/views/components/modals/ConfirmDelete';
import { mapActions, mapState } from 'pinia';
import { useModalStore } from '@/stores/modal';
import { useAuthStore } from '../../stores/auth';
import SearchInput from '../../components/SearchInput';
import qs from 'qs';

export default {
  components: { SearchInput, Paginated, Checkbox },
  inject: {
    teams: {
      from: 'teams',
      default: [],
    },
  },
  props: ['type'],
  watch: {
    teams() {
      this.getActions();
    },
    type() {
      this.getActions();
    },
    search() {
      this.getActions();
    },
  },
  created() {
    this.getActions('active');
  },
  computed: {
    ...mapState(useAuthStore, ['user']),
    request() {
      return `/actions${
        this.type !== 'personal' ? `/${this.type}` : ''
      }?${qs.stringify(
        {
          [this.status === 'active' ? 'completed_ne' : 'completed']:
            this.status !== 'all' ? true : null,
          teamid_in: this.teams.length
            ? this.teams.map((team) => team.id)
            : null,
          body_contains: this.search || null,
        },
        { arrayFormat: 'repeat', skipNulls: true }
      )}`;
    },
  },
  methods: {
    ...mapActions(useModalStore, ['openModal']),
    async handleDeleteAction(action) {
      this.openModal({
        component: ConfirmDelete,
        options: { centered: true },
        properties: {
          title: this.$t('actions.this_action'),
          id: action.id,
        },
        events: {
          submitModal: this.deleteAction,
        },
      });
    },
    async deleteAction(id) {
      await this.$axios.delete('/actions/' + id);
      await this.getActions(this.status);
    },
    async updateAction(e, id) {
      await this.$axios.put('/actions/' + id, {
        completed: e.target.checked,
      });
      await this.getActions(this.status);
    },
    async getActions(status = this.status) {
      this.totalRows = 0;
      this.status = status;

      const count = await this.$axios.get(
        (this.request = `/actions${
          this.type !== 'personal' ? `/${this.type}` : ''
        }/count?${qs.stringify(
          {
            [this.status === 'active' ? 'completed_ne' : 'completed']:
              this.status !== 'all' ? true : null,
            teamid_in: this.teams.length
              ? this.teams.map((team) => team.id)
              : null,
            body_contains: this.search || null,
          },
          { arrayFormat: 'repeat', skipNulls: true }
        )}`)
      );
      this.totalRows = count.data;
      this.$emit('actionsRefresh');
    },
    async handleModalSubmit() {
      await this.getActions(this.status);
    },
    openActionModal(id = null) {
      this.openModal({
        component: ActionModal,
        properties: {
          type: this.type,
          id: id,
          teams: this.teams,
        },
        options: {
          centered: true,
        },
        events: {
          submitModal: this.handleModalSubmit,
        },
      });
    },
    openMessageModal(id = null) {
      this.openModal({
        component: NewMessageModal,
        properties: {
          type: this.type,
          id,
        },
        options: {
          centered: true,
        },
        events: {
          submitModal: this.handleModalSubmit,
        },
      });
    },
  },

  data() {
    return {
      search: '',
      totalRows: 0,
      actions: [],
      status: 'active',
    };
  },
};
</script>
