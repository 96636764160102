<template>
  <Definitions />
</template>

<script>
import Definitions from './components/analytics/Definitions';
export default {
  name: 'DefinitionsPage',
  components: { Definitions },
};
</script>

<style scoped></style>
