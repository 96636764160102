<script setup>
import { getScoreColor } from '../helpers/color';
import { nextTick, onMounted, ref, watch } from 'vue';
import { TransitionRoot } from '@headlessui/vue';

const props = defineProps({
  score: {
    type: Number,
    required: true,
  },
});

const bgColor = ref(getScoreColor(props.score, 'absolute', 'WB'));
const segments = ref(
  Array.from({
    length: Math.ceil(props.score / 12.5),
  }).map((_, i) => false)
);

const showSegments = () => {
  let i = 0;
  const interval = setInterval(() => {
    segments.value[i] = true;
    i++;
    if (i === segments.value.length) {
      clearInterval(interval);
    }
  }, 500 / segments.value.length);
};

watch(
  () => props.score,
  (score) => {
    segments.value = Array.from({
      length: Math.ceil(score / 12.5),
    }).map((_, i) => false);

    nextTick(() => {
      showSegments();
      bgColor.value = getScoreColor(score, 'absolute', 'WB');
    });
  }
);

onMounted(() => {
  nextTick(() => {
    showSegments();
  });
});
</script>
<template>
  <div class="d-flex h-100 gap-2 align-items-stretch">
    <div class="bt-container">
      <div class="bt-top" />
      <div class="bt">
        <TransitionRoot
          v-for="(segment, i) in segments"
          :key="i"
          :show="segment"
          enter="transition"
          enter-from="enter-from"
          enter-to="enter-to"
          leave="transition"
          leave-from="enter-to"
          leave-to="enter-from"
        >
          <div
            class="bt-item"
            :style="{
              background: bgColor,
            }"
            :data-index="i"
          />
        </TransitionRoot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BatteryComponent',
};
</script>

<style scoped lang="scss">
.enter-to {
  opacity: 1;
}

.enter-from {
  opacity: 0;
}
.enter,
.leave {
  transition: opacity 0.5s ease;
}

@keyframes battery {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.bt-top {
  border-radius: 4px 4px 0 0;

  border: 2px solid #4a4a4a;

  border-bottom: 0;
  height: 8px;
  width: 40%;
  margin: 0 auto;
  z-index: 10;
  background: white;
}
.bt {
  width: 50px;
  height: 100px;
  z-index: 1;
  background: white;

  border: 2px solid #4a4a4a;

  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: flex;
  flex-direction: column-reverse;
  gap: 2px;
  padding: 3px;
}

.bt-item {
  height: 9px;
  border-radius: 2px;
}
</style>
