<script setup>
import { useCheckupStore } from "../../stores/checkup";
import { computed, inject, defineProps } from "vue";
import ProgressBar from "../ProgressBar";

defineProps({
  as: {
    type: String,
    default: "div",
  },
});

const vitamins = inject("vitamins");
const checkupStore = useCheckupStore();

/**
 * Calculate the progress of the checkup
 * @type {ComputedRef<number>}
 */
const completedPct = computed(() => {
  return Math.round(
    (checkupStore.getAnsweredQuestions.length / vitamins.value.questionCount) *
      100
  );
});
</script>

<template>
  <ProgressBar :value="completedPct" />
</template>

<script>
export default {
  name: "CheckupProgress",
};
</script>

<style scoped></style>
