<script setup>
import { defineProps } from "vue";

const props = defineProps({
  value: {
    type: Number,
    required: true,
  },
  as: {
    type: String,
    default: "div",
  },
  color: {
    type: String,
    default: "bgc-theme c-600",
  },
});
</script>

<template>
  <component :is="props.as">
    <div class="text-sm">
      {{ $t("vitamins.pct", { pct: props.value }) }}
    </div>
    <div class="progress">
      <div
        class="progress-bar"
        :class="props.color"
        role="progressbar"
        :style="`width: ${props.value}%`"
        :aria-valuenow="props.value"
        aria-valuemin="0"
        aria-valuemax="100"
      />
    </div>
  </component>
</template>

<script>
export default {
  name: "ProgressBar",
};
</script>

<style scoped></style>
