<script setup>
import Card from './simple/Card';
import PlanningModal from './modals/PlanningModal';
import Modal from '@/components/Modal';
import { inject, ref } from 'vue';
import { useAuthStore } from '../../stores/auth';
import { useAsyncState } from '@vueuse/core';
import Loader from '../../components/Loader';
import qs from 'qs';
import moment from 'moment';

const planningModal = ref(null);
const authStore = useAuthStore();

const axios = inject('axios');
const { state, isLoading } = useAsyncState(
  () =>
    axios
      .request({
        method: 'GET',
        url: '/meetings',
        params: {
          user: authStore.user?.id,
          _where: {
            _or: [
              [
                {
                  date_null: true,
                },
                {
                  isCancelled_null: true,
                },
              ],
              [
                {
                  date_gte: moment().startOf('day').toISOString(),
                },
                {
                  isCancelled_null: true,
                },
              ],
              [
                {
                  date_null: true,
                },
                { isCancelled_ne: true },
              ],
              [
                {
                  date_gte: moment().startOf('day').toISOString(),
                },
                { isCancelled_ne: true },
              ],
            ],
          },
          _sort: 'date:desc',
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            skipNulls: true,
          });
        },
      })
      .then((res) => res.data),
  []
);

const activeKey = ref(0);
</script>

<template>
  <Modal ref="planningModal">
    <PlanningModal
      :meeting-user="authStore.user"
      :override-close="true"
      @close-modal="planningModal.setOpen(false)"
    />
  </Modal>
  <Card
    color="cambridge-blue"
    class="h-100"
  >
    <template #header>
      <span class="overline text-sm">{{
        $t('message.meetings')
      }}</span>
    </template>
    <Loader
      :is-loading="isLoading"
      class="h-100"
    >
      <div
        v-if="state.length"
        class="h-100 d-flex justify-content-between flex-column"
      >
        <div>
          <p class="fw-bold">
            {{ state[activeKey].subject }}
          </p>
          <p class="text-sm">
            {{
              state[0].date
                ? moment(state[activeKey].date).format(
                    'DD/MM/YYYY HH:mm'
                  )
                : $t('meeting.is_requested')
            }}&nbsp;&bull;&nbsp;{{
              state[activeKey].manager.firstname
            }}
            {{ state[activeKey].manager.lastname }}
          </p>
        </div>
        <div
          v-if="state.length > 1"
          class="w-100 my-3 d-flex justify-content-center"
        >
          <button
            v-for="(meeting, i) in state"
            :id="i"
            :key="meeting.id"
            class="rounded-circle bgc-theme border-0 c-400 mx-1"
            :class="activeKey === i ? ' bgc-theme c-700' : ''"
            style="height: 12px; width: 12px"
            @click="activeKey = i"
          />
        </div>
      </div>
      <div
        v-else
        class="d-flex h-100 w-100 justify-content-center align-items-center flex-column"
      >
        <p class="text-black-50 mb-1">
          {{ $t('warning.no_upcoming_meetings') }}
        </p>
        <button
          class="btn btn-sm btn-primary"
          @click="planningModal.setOpen(true)"
        >
          {{ $t('message.vraag_gesprek_aan') }}
        </button>
      </div>
    </Loader>
  </Card>
  <!--  <main>
    <Modal
      id="requestModal"
      ref="requestModal"
      size="lg"
    >
      <Card
        class="modal-content"
        color="groengrijs"
      >
        <template #header>
          <span
            class="text-sm modal-title w-100 flex-grow-1 overline"
            >{{ $t('message.vraag_gesprek_aan') }}</span
          >
          <button
            type="button"
            class="d-block ms-auto btn-close"
            aria-label="Close"
            @click="$refs.requestModal.setOpen(false)"
          />
        </template>
        <div>
          <form @submit.prevent="submitForm">
            <div class="block mb-4">
              <label for="onderwerp">{{
                $t('message.subject')
              }}</label>
              <input
                id="onderwerp"
                v-model="form.subject"
                type="text"
                class="input-lg w-100"
              />
            </div>
            <div class="block mb-4">
              <label for="manager">{{ $t('message.manager') }}</label>
              <select
                id="manager"
                v-model="form.manager"
                name="manager"
                class="input-lg w-100"
              >
                <option
                  value=""
                  disabled
                >
                  {{ $t('general.select') }}
                </option>
                <option
                  v-for="manager in availableManagers"
                  :key="`manager-${manager.id}`"
                  :value="manager.id"
                >
                  {{ manager.firstname }} {{ manager.lastname }}
                </option>
              </select>
            </div>
            <div class="block mb-4">
              <label for="details">{{ $t('general.info') }}</label>
              <textarea
                id="details"
                v-model="form.info"
                class="w-100 input-lg"
              />
            </div>
            <div class="d-flex justify-content-end">
              <button
                class="btn btn-primary"
                :disabled="loading"
              >
                <div
                  v-if="loading"
                  style="height: 12px; width: 12px"
                  class="spinner-border me-2"
                  role="status"
                >
                  <span class="visually-hidden">{{
                    $t('message.loading')
                  }}</span>
                </div>
                {{ $t('message.save') }}
              </button>
            </div>
          </form>
        </div>
      </Card>
    </Modal>
    &lt;!&ndash;    Trigger for modal &ndash;&gt;
    <template v-if="availableManagers.length">
      <p
        v-if="form.id && !form.isCancelled && form.date"
        class="mb-0"
      >
        <span>{{
          $t('message.meeting_on', {
            date: $d(
              new Date(form.date),
              'shortTime',
              $i18n.locale === 'en' ? 'en-GB' : $i18n.locale
            ),
          })
        }}</span
        ><br /><span class="text-sm text-end"
          >{{ form.manager.firstname }}
          {{ form.manager.lastname }}</span
        >
      </p>

      <p
        v-else-if="form.id && !form.isCancelled && !form.date"
        class="py-1 px-2"
      >
        {{
          $t('dashboard.meeting_requested', {
            user: `${form.manager.firstname} ${form.manager.lastname}`,
          })
        }}
      </p>
      <button
        v-else
        type="button"
        class="cursor-pointer btn btn-secondary"
        @click="$refs.requestModal.setOpen(true)"
      >
        {{ $t('message.vraag_gesprek_aan') }}
      </button>
    </template>
  </main>-->
</template>

<script>
/*import Modal from '@/components/Modal';
import Card from '@/views/components/simple/Card';
import { mapActions, mapState } from 'pinia';
import { useAuthStore } from '../../stores/auth';
import _ from 'lodash';
import qs from 'qs';
import { useMainStore } from '../../stores';
import { TOAST_TYPES } from '../../helpers/constants';

export default {
  name: 'RequestMeeting',
  components: { Modal, Card },
  data() {
    return {
      availableManagers: [],
      loading: false,
      form: {
        subject: '',
        manager: '',
        info: '',
      },
    };
  },
  created() {
    this.getMeeting();
  },
  mounted() {
    this.getManagers();
  },
  computed: {
    ...mapState(useAuthStore, ['user']),
  },
  methods: {
    ...mapActions(useMainStore, ['addToast']),
    async getMeeting() {
      const { data: meetings } = await this.$axios.get(
        /!*`/meetings?user=${this.user?.id}&isCancelled_ne=true&_sort=date:asc`*!/
        `/meetings?${qs.stringify({
          user: this.user?.id,
          _where: {
            _or: [
              { isCancelled_ne: true },
              { isCancelled_null: true },
            ],
          },
          _sort: 'date:desc',
        })}`
      );
      if (meetings.length) {
        this.form = meetings[0];
      }
    },
    async getManagers() {
      const { data } = await this.$axios.get('/swarms/zerglings');
      this.availableManagers = _.uniqBy(
        data.flatMap((item) => item.overlords),
        'id'
      );
    },
    async submitForm() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post('/meetings', {
          ...this.form,
          isCancelled: false,
          isRequest: true,
          user: this.user?.id,
        });
        const manager = this.availableManagers.find(
          (manager) => manager.id === this.form.manager
        );
        this.form = data;
        this.$refs.requestModal.setOpen(false);
        this.addToast({
          content: this.$t('feedback.meetings.request_sent', {
            manager: `${manager.firstname} ${manager.lastname}`,
          }),
          type: TOAST_TYPES.SUCCESS,
        });
      } catch (e) {
        this.addToast({
          content: this.$t(
            'feedback.meetings.cannot_request_meeting'
          ),
          type: TOAST_TYPES.SUCCESS,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};*/
</script>

<style scoped></style>
