import { defineStore } from 'pinia';

const DEFAULT_ANSWER = 'none';
const INDEX_SEPARATOR = '#';
const DIRECTIONS = {
  UP: 1,
  DOWN: -1,
};

export const useCheckupStore = defineStore('checkup', {
  state: () => ({
    id: null,
    activeIndex: {
      vitamin: 0,
      factor: 0,
      question: 0,
    },
    skipAnswered: false,
    answers: [],
    notes: [],
    isdone: false,
    postfinish: false,
    isprivate: false,
    manager: null,
    isfinished: false,
  }),
  getters: {
    /**
     * Count all answered questions
     * @returns {*[]}
     */
    getAnsweredQuestions() {
      return (
        this.answers.filter(
          (answer) => answer.value != DEFAULT_ANSWER
        ) || []
      );
    },
    /**
     * Get the index of the previous question
     * @returns {(function(*): {question: number, factor: number, vitamin: number})|*}
     */
    getPreviousIndex() {
      return this.getMovedIndex(DIRECTIONS.DOWN);
    },
    /**
     * Compute the next or previous index
     * @returns {(function(*): ({question: number, factor: number, vitamin: number}))|*}
     */
    getMovedIndex() {
      return (direction) => {
        const activeIndex = Object.values(this.activeIndex).join(
          INDEX_SEPARATOR
        );
        const movedIndex =
          this.answers.findIndex(
            (answer) => answer.index == activeIndex
          ) + direction;
        let newIndex;
        /*if (unanswered) {
          newIndex = this.answers
            .find((answer) => answer.value == DEFAULT_ANSWER)
            ?.index.split(INDEX_SEPARATOR);
        } else {*/
        newIndex =
          this.answers[movedIndex]?.index.split(INDEX_SEPARATOR);
        /* }*/

        if (newIndex) {
          return {
            vitamin: parseInt(newIndex[0]),
            factor: parseInt(newIndex[1]),
            question: parseInt(newIndex[2]),
          };
        }

        return {
          vitamin: -1,
          factor: -1,
          question: -1,
        };
      };
    },
    /**
     * Get the index of the next question
     * @returns {(function(*): ({question: number, factor: number, vitamin: number}))|*}
     */
    getNextIndex() {
      return this.getMovedIndex(DIRECTIONS.UP);
    },

    getUnansweredIndex() {
      const newIndex = this.answers
        .find((answer) => answer.value == DEFAULT_ANSWER)
        ?.index.split(INDEX_SEPARATOR);

      if (newIndex) {
        return {
          vitamin: parseInt(newIndex[0]),
          factor: parseInt(newIndex[1]),
          question: parseInt(newIndex[2]),
        };
      }
    },
    /**
     * Get the index for the active question in the current submission
     * @returns {number}
     */
    getActiveAnswer() {
      let activeIndex = Object.values(this.activeIndex).join(
        INDEX_SEPARATOR
      );
      return this.answers.findIndex(
        (answer) => answer.index == activeIndex
      );
    },
  },
  actions: {
    /**
     * Initialize submission data
     * @param submission - existing submission
     * @param vitamins - vitamins with factors and questions
     * @param id - checkupentry id
     * @returns {Promise<void>}
     */
    async setSubmission(submission, vitamins, id) {
      this.$reset();

      if (!submission) {
        // Flatten questions for intial submission values
        for (const [i, vitamin] of vitamins.vitamins.entries()) {
          for (const [j, factor] of vitamin.factors.entries()) {
            for (const [k, question] of factor.questions.entries()) {
              this.answers.push({
                index: `${i}#${j}#${k}`,
                value: DEFAULT_ANSWER,
                questionId: question.id,
                scalefactor: factor.scalegroup.id,
                factorid: factor.id,
                vitaminid: vitamin.id,
                questionkey: question.key,
              });
            }
            this.notes.push({
              index: `${i}#${j}`,
              factorid: factor.id,
              isShared: false,
              content: '',
            });
          }
        }

        // Create new submission with initial values
        let { data: newSubmission } = await this.axios.post(
          `/checkupsubmissions`,
          {
            checkupentry: isNaN(id) ? null : id,
            hasEntry: !isNaN(id),
            isfinished: false,
            submission: {
              questions: this.answers,
              notes: this.notes,
              answeredCount: 0,
              isFinished: false,
            },
            sharedsubmission: {
              questions: [],
              notes: [],
            },
          }
        );
        submission = newSubmission;
      }

      // Set data in state
      this.id = submission.id;
      const questions = [
        ...submission.submission.questions,
        ...(submission.extraSubmission || []),
      ];
      const { notes } = submission.submission;
      this.answers = questions;
      this.notes = notes;
      this.isfinished = submission.isfinished;
      this.isprivate = submission.private;
      this.manager = submission.manager?.id || null;
      this.postfinish = false;
      this.setActiveIndex();
    },
    /**
     * Move active index to a new index, or find index if no new index is given
     * @param {Array<Number>|null} index
     */
    setActiveIndex(index = null) {
      if (index) {
        if (Object.values(index).every((i) => i >= 0)) {
          this.activeIndex = index;
        } else {
          this.isdone = true;
        }
        return;
      } else {
        const foundIndex = this.answers
          .find((answer) => answer.value == DEFAULT_ANSWER)
          ?.index?.split(INDEX_SEPARATOR);
        if (foundIndex) {
          const [vitamin, factor, question] = foundIndex;
          this.activeIndex = {
            vitamin: parseInt(vitamin),
            factor: parseInt(factor),
            question: parseInt(question),
          };
        } else {
          this.isdone = true;
        }
      }
    },
    /**
     * Submit the current checkup submission to backend
     * @returns {Promise<void>}
     */
    async submit() {
      const { answers, extraAnswers } = this.answers.reduce(
        (acc, item) => {
          if (item.questionkey?.includes('EXT')) {
            acc['extraAnswers'] = [
              ...(acc?.extraAnswers || []),
              item,
            ];
          } else {
            acc['answers'] = [...(acc?.answers || []), item];
          }

          return acc;
        },
        {}
      );
      await this.axios.put(`/checkupsubmissions/${this.id}`, {
        submission: {
          questions: answers,
          notes: this.notes,
          answeredCount: this.getAnsweredQuestions.length,
        },
        sharedsubmission: {
          questions: this.isprivate ? [] : answers,
          notes: this.notes.filter((item) => item.isShared),
        },
        isfinished: this.isfinished,
        private: this.isprivate,
        extraSubmission: extraAnswers,
      });
    },

    async save() {
      const { answers, extraAnswers } = this.answers.reduce(
        (acc, item) => {
          if (item.questionkey?.includes('EXT')) {
            acc['extraAnswers'] = [
              ...(acc?.extraAnswers || []),
              item,
            ];
          } else {
            acc['answers'] = [...(acc?.answers || []), item];
          }

          return acc;
        },
        {}
      );

      await this.axios.put(`/checkupsubmissions/${this.id}`, {
        submission: {
          questions: answers,
          notes: this.notes,
          answeredCount: this.getAnsweredQuestions.length,
        },
        sharedsubmission: {
          questions: this.isprivate ? [] : answers,
          notes: this.notes.filter((item) => item.isShared),
        },
        isfinished: this.isfinished,
        private: this.isprivate,
        manager: this.manager,
        extraSubmission: extraAnswers,
      });
    },
  },
  /**
   * Debounce submit function
   */
  debounce: {
    submit: 500,
  },
});
