import { defineStore } from 'pinia';
import { useMainStore } from './index';
import { TOAST_TYPES } from '../helpers/constants';
import { pick } from 'lodash';

export const useSettingsStore = defineStore('settings', {
  state: () => ({
    settings: null,
  }),
  actions: {
    async fetchSettings() {
      const { data: settings } = await this.axios.get('/settings');
      this.settings = settings;
    },
    async saveSettings() {
      const mainStore = useMainStore();
      const { data: settings } = await this.axios.put(
        '/settings/1',
        this.settings
      );
      this.settings = settings;
      mainStore.publicSettings = pick(
        settings.data,
        Object.keys(mainStore.publicSettings)
      );
      mainStore.addToast({
        type: TOAST_TYPES.SUCCESS,
        content: this.i18n.global.t('settings.settings_saved'),
      });
    },
  },
});
