<script setup>
// import { useModalStore } from '../../../stores/modal';
import { inject } from 'vue';

// const modalStore = useModalStore();
const props = defineProps({
  message: {
    type: String,
    required: false,
    default: '',
  },
  title: {
    type: String,
    required: false,
    default: '',
  },
  action: {
    type: String,
    default: 'message.delete',
  },
  id: {
    type: [String, Number],
    required: true,
  },
});

const emit = defineEmits(['submitModal']);

const setOpen = inject('setOpen');

// Functions

/**
 * Confirm the action and send event to parent.
 * Close modal after emitting event
 */
const confirmAction = () => {
  emit('submitModal', props.id);
  // modalStore.closeModal();
  setOpen(false);
};
</script>

<template>
  <form
    class="modal-content rounded-16 overflow-hidden"
    @submit.prevent="confirmAction"
  >
    <div
      class="modal-header c-50"
      :class="'bgc-koraalrood'"
    >
      <div
        :class="'c-koraalrood'"
        class="c-200 text-sm modal-title w-100 flex-grow-1"
      >
        <span class="text-sentence">{{ $t('message.confirm') }}</span>
      </div>
      <button
        type="button"
        class="d-block ms-auto btn-close"
        aria-label="Close"
        @click="setOpen(false)"
      />
    </div>
    <div class="modal-body">
      <span
        v-if="message"
        class="text-sentence"
        >{{ message }}</span
      >
      <span
        v-else
        class="text-sentence"
        >{{
          $t('message.confirm_action', {
            action: $t(action).toLowerCase(),
            title,
          })
        }}</span
      >
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        @click="setOpen(false)"
      >
        <span class="text-sentence">{{ $t('message.cancel') }}</span>
      </button>
      <button class="btn btn-danger">
        <span class="text-sentence">{{ $t(action) }}</span>
      </button>
    </div>
  </form>
</template>
<script>
export default {};
</script>
