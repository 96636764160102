<script setup>
import { computed, ref } from 'vue';
import Paginated from '../components/pagination/Paginated';
import qs from 'qs';
import CheckupCard from './components/CheckupCard';

const types = [
  {
    id: 'vitamins',
    title: 'message.vitamins',
  },
  {
    id: 'pulse',
    title: 'vitamins.pulse',
  },
];

const pagination = ref(null);
const activeType = ref(types[0]);

const request = computed(() => {
  return `/checkups/own?${qs.stringify(
    {
      [`checkup.isPulse${
        activeType.value.id === 'pulse' ? '' : '_ne'
      }`]: true,
    },
    { skipNulls: true }
  )}`;
});

const countRequest = computed(() => {
  return `/checkups/owncount?${qs.stringify(
    {
      [`checkup.isPulse${
        activeType.value.id === 'pulse' ? '' : '_ne'
      }`]: true,
    },
    { skipNulls: true }
  )}`;
});

const refreshContent = () => {
  pagination.value.execute();
};
</script>

<template>
  <div
    class="mb-3 checkup-top row justify-content-between align-items-baseline"
  >
    <div class="col-md-auto">
      <div class="btn-group">
        <button
          v-for="type in types"
          :key="`page-${type.id}`"
          class="btn btn-secondary btn-sm position-relative"
          :class="[activeType.id === type.id ? 'active' : '']"
          @click="activeType = type"
        >
          <span>{{ $t(type.title) }}</span>
        </button>
      </div>
    </div>
    <div class="col-md-auto px-0 d-flex align-items-stretch">
      <div class="me-2">
        <router-link
          to="/vitamins/checkup/own"
          class="btn btn-sm btn-primary"
        >
          <i class="fas fa-plus me-2" />
          {{ $t('vitamins.start_own_vitamin') }}
        </router-link>
      </div>
    </div>
  </div>
  <Paginated
    ref="pagination"
    :request="request"
    :count-request="countRequest"
    :options="{
      limit: 5,
      allowLengthChange: false,
    }"
  >
    <template #content="{ content: checkups }">
      <div class="row checkup-row">
        <div
          v-if="pagination.page === 0 && checkups.length > 0"
          class="latest-checkup col-md-4 mb-gutter"
        >
          <checkup-card
            :submission="checkups[0].submission"
            :checkup="checkups[0]"
            size="lg"
            @refresh-vitamins="refreshContent"
          />
        </div>
        <div
          class="past-vitamins"
          :class="pagination.page === 0 ? 'col-md-8' : 'col-md-12'"
        >
          <div
            v-if="pagination.page === 0 && checkups.length > 0"
            class="row"
          >
            <template
              v-for="checkup in checkups.slice(1)"
              :key="checkup"
            >
              <div class="col-md-6 mb-gutter">
                <checkup-card
                  :submission="checkup.submission"
                  :checkup="checkup"
                />
              </div>
            </template>
          </div>
          <div
            v-else
            class="row"
          >
            <template
              v-for="checkup in checkups"
              :key="checkup"
            >
              <div class="col-md-4 mb-gutter">
                <checkup-card
                  :submission="checkup.submission"
                  :checkup="checkup"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
  </Paginated>
</template>
