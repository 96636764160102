import { defineStore } from "pinia";
import { markRaw } from "vue";

export const useModalStore = defineStore("modal", {
  state: () => ({
    modal: null,
    modalContent: null,
    modalProps: null,
    modalEvents: {},
    modalOptions: {},
  }),
  actions: {
    setModal({ element }) {
      this.modal = element;
    },
    /**
     *
     * @param component
     * @param { Object } properties
     * @param { Object } options
     * @param { Object } events
     */
    openModal({ component, properties, options, events = null }) {
      this.modalProps = properties;
      this.modalEvents = events || {};
      if (options) {
        this.modalOptions = options;
      }
      this.modalContent = markRaw(component);
      this.modal.setOpen(true);
      // const el = document.querySelector("#primaryModal");
      // this.setModal({
      //   element: el,
      // });
      //
      // this.modal.show();
    },
    closeModal() {
      this.modal.setOpen(false);
      setTimeout(() => {
        this.modalContent = null;
        this.modalEvents = {};
        this.modalOptions = {};
        this.modalProps = null;
      }, 400);
    },
  },
});
