<script setup>
import {
  computed,
  inject,
  nextTick,
  onMounted,
  provide,
  ref,
  watch,
} from 'vue';

import { useI18n } from 'vue-i18n';
import { useMainStore } from '../../stores';
import Card from '../../views/components/simple/Card';
import RadarChart from '../charts/RadarChart';
// import ScaleChartList from '../ScaleChart/ScaleChartList';
import {
  clusterColors,
  getColor,
  getScoreColor,
  pickableColors,
} from '../../helpers/color';
import Pagination from '../pagination/Pagination';
import Modal from '../Modal';
import FeedbackModal from '../../views/components/modals/FeedbackModal';
import QuestionModal from '@/components/modal/QuestionModal';
import ScaleChart from '../ScaleChart/ScaleChart';

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  type: {
    type: String,
    required: true,
    validator(value) {
      return ['user', 'swarm'].includes(value);
    },
  },
  dataId: {
    type: [String, Array],
    required: true,
  },
  cluster: {
    type: Number,
    default: null,
  },
  isProcessed: {
    type: Boolean,
    default: false,
  },
  activeKey: {
    type: Number,
    default: 0,
  },
});

const { locale } = useI18n();
const axios = inject('axios');
const keys = computed(() =>
  props.data[0]?.data.map((item) => item.key)
);

provide('hasTooltip', true);

const store = useMainStore();

const factors = ref([]);
const getFactors = async () => {
  factors.value = await Promise.all([
    axios.request({
      url: '/vitamins',
      params: {
        key_in: keys.value,
        _locale: locale.value,
      },
    }),
    axios.request({
      url: '/factors',
      params: {
        key_in: keys.value,
        _locale: locale.value,
      },
    }),
  ]).then(([vitamins, factors]) => {
    const availableFactors = [...vitamins.data, ...factors.data];

    let datasets = [];

    let i = 0;
    for (const set of props.data) {
      datasets.push({
        label: set.title || `set ${i + 1}`,
        data: keys.value.map((key) => {
          let adviceType = {
            2.5: 'very_low',
            16: 'low',
            50: 'below_average',
            84: 'above_average',
            98: 'high',
            100: 'very_high',
          };
          if (props.type === 'swarm') {
            adviceType = {
              25: 'low',
              75: 'average',
              100: 'high',
            };
          }
          if (['UIT', 'WD', 'COG', 'COM'].includes(key)) {
            adviceType = {
              5: 'very_low',
              15: 'low',
              85: 'average',
              95: 'high',
              100: 'very_high',
            };
          }
          const factor = availableFactors.find((f) => f.key === key);
          const score = set.data.find(
            (item) => item.key === key
          ).score;
          return {
            ...factor,
            score,
            dataId: set.id,
            color: store.vitaminColors.find((item) =>
              item.keys.includes(key)
            )?.color,
            advice: `personal_feedback.${props.type}.radar.${
              factor.key
            }.${
              adviceType[
                Object.keys(adviceType).find((k) => k > +score)
              ]
            }`,
          };
        }),
      });

      i++;
    }
    return datasets;
  });
};

await getFactors();

watch(
  [() => locale.value, () => props.data.length],
  () => {
    getFactors();
  },
  {
    deep: true,
  }
);
const getPointColor = (set, context) => {
  return getScoreColor(
    set.data[context.dataIndex],
    '',
    keys.value[context.dataIndex]
  );
};

const pickedKeys = ref(new Set(['purple']));
const allKeys = computed(() => {
  return pickableColors
    .slice()
    .filter((item) => {
      return !Array.from(pickedKeys.value).includes(item);
    })
    .map((item) => item.key);
});

const radarData = computed(() => {
  let color = 'purple';
  if (props.type === 'user' && props.cluster) {
    color = clusterColors[props.cluster - 1];
  }

  if (!factors.value.length) {
    return {
      labels: [],
      datasets: [],
    };
  }

  return {
    labels: [...factors.value[0].data.map((f) => f.title)],
    datasets: factors.value.map((set, i) => {
      pickedKeys.value.clear();
      pickedKeys.value.add('purple');
      if (i > 0) {
        do {
          const randomIndex = Math.floor(
            Math.random() * allKeys.value.length
          );
          color = allKeys.value[randomIndex];
        } while (pickedKeys.value.has(color));

        pickedKeys.value.add(color);
      }

      /*      color =
        i < 1
          ? color
          : pickableColors[
              Math.floor(Math.random() * pickableColors.length)
            ].key;*/
      return {
        label: set.label,
        pointBackgroundColor: (context) =>
          getPointColor(
            { data: [...set.data.map((item) => item.score)] },
            context
          ),
        pointBorderColor: (context) =>
          getPointColor(
            { data: [...set.data.map((item) => item.score)] },
            context
          ),
        pointRadius: 6,
        pointHoverRadius: 8,
        data: [...set.data.map((item) => item.score)],
        backgroundColor: getColor(color, true, 0.2),
        borderColor: getColor(color, true, 1),
      };
    }),
  };
});

const getScale = (type) => {
  if (['UIT', 'WD', 'COG', 'COM'].includes(type)) {
    return {
      5: 'bad',
      15: 'average',
      85: 'great',
      95: 'average',
      100: 'bad',
    };
  }

  if (
    type === 'NEG' ||
    [
      'HIN',
      'EMO',
      'ONZ',
      'ROA',
      'ROC',
      'BUR',
      'CON',
      'POL',
      'ONG',
      'EXH',
      'PIE',
      'SLA',
      'CYN',
    ].includes(type)
  ) {
    return { 25: 'great', 75: 'average', 100: 'bad' };
  }

  return { 25: 'bad', 75: 'average', 100: 'great' };
};

const activeIndex = ref(props.activeKey || 0);
watch(
  () => props.activeKey,
  (value) => {
    activeIndex.value = value;
  }
);

provide('itemsPerPage', 1);

const content = ref(null);
const stackCard = ref(props.type === 'swarm');

let observer = null;
onMounted(() => {
  nextTick(() => {
    observer = new ResizeObserver((item) => {
      for (const entry of item) {
        const cr = entry.contentRect;
        stackCard.value =
          props.type === 'swarm' ? true : cr.width < 1024;
      }
    });
    if (content.value) {
      observer.observe(content.value);
    }
  });
});

const activeFactor = ref(null);
const feedbackModal = ref(null);
const onClick = (activeElements) => {
  if (Array.isArray(activeElements)) {
    if (!activeElements.length) {
      return;
    }
    activeFactor.value =
      factors.value[activeElements[0].datasetIndex].data[
        activeElements[0].index
      ];
  } else {
    if (!activeElements < 0) {
      return;
    }
    activeFactor.value = factors.value[0].data[activeElements];
  }

  feedbackModal.value.setOpen(true);
};

const emit = defineEmits(['pageChange']);
const changePage = (move) => {
  activeIndex.value += move;
  emit('pageChange', activeIndex.value);
};

const questionModal = ref(null);
const openQuestionModal = () => {
  questionModal.value.setOpen(true);
};
</script>

<template>
  <Modal
    ref="feedbackModal"
    size="lg"
  >
    <FeedbackModal
      :type="`${type}_radar`"
      :factor="activeFactor"
    />
  </Modal>
  <Modal ref="questionModal">
    <QuestionModal
      :ref-id="dataId"
      :type="type"
      :checkup="data[activeIndex].id"
    />
  </Modal>
  <div
    v-if="
      factors.length &&
      factors.every((item) =>
        item.data.every((data) => +data.score > 0)
      )
    "
    ref="content"
    class="row"
  >
    <div
      v-if="type === 'swarm'"
      :class="[stackCard ? 'col-12 mb-4' : 'col-xl-4']"
    >
      <div class="row">
        <div :class="[stackCard ? 'col-12 mb-4' : 'col-12 mb-4']">
          <Card
            color="cambridge-blue--light
            "
            class="h-100"
          >
            <template #header>
              <span class="text-uppercase text-sm">{{
                $t(`personal_feedback.${type}.map`)
              }}</span>
            </template>
            <Suspense>
              <RadarChart
                :type="type"
                :data="radarData"
                :hide-labels="type !== 'swarm'"
                :cluster="cluster"
                @click="onClick"
              />
            </Suspense>
          </Card>
        </div>
      </div>
    </div>
    <div :class="[stackCard ? 'col-xl-12' : 'col-xl-8']">
      <div
        v-if="type === 'swarm'"
        class="d-flex justify-content-between align-items-center mb-4"
      >
        <h3 class="mb-0">
          {{
            type === 'user'
              ? $t('dashboard.personal_feedback')
              : `${$t('dashboard.feedback')} - ${
                  factors[activeIndex].label
                }`
          }}
        </h3>
        <Pagination
          v-if="factors.length > 1"
          :current-page="activeIndex"
          :total-rows="factors.length"
          :allow-length-change="false"
          @change="changePage"
        />
      </div>

      <p class="mb-3">
        {{ $t(`personal_feedback.${type}.feedback_intro`) }}
      </p>
      <template
        v-for="(item, i) in factors[activeIndex].data"
        :key="item.id"
      >
        <div
          class="d-md-grid w-100 justify-content-between align-items-center mb-2"
          :style="{ gridTemplateColumns: '1fr 1fr' }"
        >
          <h5
            class="cursor-pointer mb-0"
            @click="
              onClick([{ datasetIndex: activeIndex, index: i }])
            "
          >
            <span class="text-theme--300">
              <i class="fas fa-info-circle me-1" /></span
            >{{ item.title }}
          </h5>
          <div
            class="w-100 w-md-50 d-flex justify-content-end flex-wrap gap-2"
          >
            <div
              v-for="(set, i) in factors"
              :key="set.id"
              style="width: 30%"
            >
              <ScaleChart
                :value="
                  set.data.find((f) => f.key === item.key).score
                "
                :description="set.label"
                :scale="getScale(item.key)"
                :style="{
                  filter: `opacity(${i === activeIndex ? 1 : 0.5})`,
                }"
              />
            </div>
          </div>
        </div>
        <p class="mb-3">{{ $t(item.advice) }}</p>
      </template>
      <div
        v-if="type === 'user'"
        class="d-flex justify-content-end pb-2"
      >
        <button
          class="btn btn-sm btn-primary"
          @click="openQuestionModal"
        >
          {{ $t('dashboard.submit_feedback') }}
        </button>
      </div>
    </div>
    <div
      v-if="type !== 'swarm'"
      :class="[stackCard ? 'col-12' : 'col-xl-4']"
    >
      <div
        class="row position-sticky"
        :style="{ top: '1rem' }"
      >
        <div :class="[stackCard ? 'col-12 mb-4' : 'col-12 mb-4 ']">
          <Card
            color="cambridge-blue--light"
            class="h-100"
          >
            <template #header>
              <span class="text-uppercase text-sm">{{
                $t(`personal_feedback.${type}.map`)
              }}</span>
            </template>
            <Suspense>
              <RadarChart
                :type="type"
                :data="radarData"
                :hide-labels="type !== 'swarm'"
                :cluster="cluster"
                @click="onClick"
              />
            </Suspense>
          </Card>
        </div>
        <!--<div :class="[stackCard ? 'd-none' : 'col-12']">
          <Card
            color="cambridge-blue--light"
            class="h-100"
          >
            <template #header>
              <span class="text-uppercase text-sm">{{
                $t(`personal_feedback.${type}.profile`)
              }}</span>
            </template>
            <ScaleChartList
              :data="
                factors
                  .map((item) => {
                    return item?.data?.map((factor) => {
                      return {
                        description:
                          factors.length > 1
                            ? `${factor.title} - ${item.label}`
                            : factor.title,
                        value: factor.score,
                        scale: getScale(factor.key),
                      };
                    });
                  })
                  .flat()
              "
            />
          </Card>
        </div>-->
      </div>
    </div>
  </div>
  <div v-else>{{ $t('warning.data_is_processing') }}</div>
</template>

<script>
export default {
  name: 'CheckupFeedback',
};
</script>

<style scoped></style>
