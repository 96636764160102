<template>
  <Card
    :color="`${activeType.color}--dark`"
    :color-shade="200"
  >
    <template #header>
      <div
        class=""
        :style="`margin-left: -1rem; height: 40px`"
      >
        <div
          v-if="canChangeType.length && availableTypes.length > 1"
          class="card-nav-main nav"
          :class="`bg-${activeType.color}`"
        >
          <ul class="nav nav-pills p-0">
            <li class="nav-item dropdown py-0">
              <button
                class="card-header-nav-btn nav-link p-0"
                style="height: 40px"
                data-bs-toggle="dropdown"
                role="button"
                aria-expanded="false"
              >
                <img
                  src="../assets/icons/icon-navigation-expand-light.svg"
                  alt=""
                />
              </button>
              <ul class="dropdown-menu positioned">
                <li
                  v-for="(item, i) in typeList"
                  :key="`type-${item.type}`"
                >
                  <button
                    :class="{
                      'd-none': i === activeTypeIndex,
                      [`bg-${item.color}`]: true,
                    }"
                    class="border-0 dropdown-item overline text-sm"
                    @click="activeTypeIndex = i"
                  >
                    {{ $t(`actions.${item.type}`) }}
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <span class="ms-4 overline text-sm">{{
        $t(`actions.${activeType.type}`)
      }}</span>
    </template>
    <action-list :type="activeType.type" />
  </Card>
</template>

<script>
import { mapState } from 'pinia';
import Card from '@/views/components/simple/Card';
import ActionList from '@/views/components/ActionList';
import { useAuthStore } from '@/stores/auth';

export default {
  name: 'ActionsCard',
  components: { ActionList, Card },
  props: {
    availableTypes: {
      type: Array,
      default: () => {
        return ['personal', 'team', 'company'];
      },
    },
  },
  data() {
    return {
      types: [
        {
          type: 'personal',
          color: 'purple',
        },
        {
          type: 'team',
          color: 'blue',
        },
        {
          type: 'company',
          color: 'orange-yellow',
        },
      ],
      activeTypeIndex: 0,
    };
  },
  mounted() {
    this.activeTypeIndex = this.typeList.findIndex(
      (item) => item.type === this.availableTypes[0]
    );
  },
  computed: {
    ...mapState(useAuthStore, ['permission']),
    activeType() {
      return this.typeList[this.activeTypeIndex];
    },
    typeList() {
      return this.types.filter((type) => {
        return this.availableTypes.includes(type.type);
      });
    },
    canChangeType() {
      const permissions = [];

      if (this.permission(['ismanager', 'manageteams'])) {
        permissions.push('team');
      }

      if (this.permission(['managecompany'])) {
        permissions.push('company');
      }

      return permissions;
    },
  },
};
</script>

<style scoped>
.positioned {
  transform: translate(0, 40px) !important;
}
</style>
