<template>
  <div
    v-if="authStore && authStore.isAuthenticated"
    class="page-home"
  >
    <header
      class="mb-4 title d-flex justify-content-between align-items-center flex-wrap"
    >
      <i18n-t
        keypath="dashboard.title"
        tag="h2"
        class="text-sentence"
      >
        <template #for>
          <em>{{ $t('message.for') }}</em>
        </template>
        <template #user>
          <span>{{ authStore ? authStore.fullName : '' }}</span>
        </template>
      </i18n-t>
      <div
        v-if="submissions.length > 1"
        class="d-flex gap-2 align-items-center"
      >
        <select
          v-model="submissionKey"
          class="input"
        >
          <option
            v-for="(submission, i) in submissions"
            :key="submission.id"
            :value="i"
          >
            {{
              submission.title === 'own_vitamin'
                ? $t('vitamins.own_vitamin')
                : submission.title
            }}
          </option>
        </select>
        <Pagination
          style="margin-top: 0 !important"
          :current-page="submissionKey"
          :total-rows="submissions.length"
          :allow-length-change="false"
          :per-page="1"
          @change="(moveBy) => (submissionKey += moveBy)"
        />
      </div>
      <!--      <RequestMeeting />-->
    </header>
    <template v-if="setting('hidedashboard')">
      <div class="row">
        <div
          class="mb-4 mb-xl-0"
          :class="[
            authStore?.user?.member?.filter(
              (swarm) => swarm.visibility && !swarm.issegment
            )?.length
              ? 'col-xl-3'
              : 'col-xl-6',
          ]"
        >
          <ActiveCheckups
            class="h-100"
            @openCalendar="openCalendarModal"
          />
        </div>
        <template v-if="!setting('hidefeedback')">
          <div
            v-if="
              authStore?.user?.member?.filter(
                (swarm) => swarm.visibility && !swarm.issegment
              )?.length
            "
            class="col-xl-3"
          >
            <RequestMeeting />
          </div>
          <div class="col-xl-6">
            <Card
              color="orange-yellow"
              class="flex-grow-1"
            >
              <template #header>
                <span class="overline text-sm">{{
                  $t('dashboard.feedback')
                }}</span>
              </template>
              <UserFeedbackForm />
            </Card>
          </div>
        </template>
      </div>
    </template>
    <template v-else-if="hasData">
      <section class="row mb-4">
        <div
          v-if="!setting('hidewbeng')"
          class="col-md-6 mb-4 mb-xl-0"
          :class="[setting('hidefeedback') ? 'col-xl-6' : 'col-xl-3']"
        >
          <StatCard
            class="h-100"
            :title="$t('message.WB')"
            :data="[
              {
                description: 'wellbeing',
                value: activeSubmission.wb,
              },
            ]"
            color="green--light"
            :shade="50"
            :header-tooltip="
              ew.find((vit) => vit.key === 'WB')?.body || ''
            "
          >
            <template #dropdown-items>
              <li>
                <button
                  class="dropdown-item"
                  @click="openSubmissionModal"
                >
                  {{ $t('dashboard.view_submission') }}
                </button>
              </li>
            </template>
          </StatCard>
        </div>
        <div
          v-if="!setting('hidewbeng')"
          class="col-md-6 mb-4 mb-xl-0"
          :class="[setting('hidefeedback') ? 'col-xl-6' : 'col-xl-3']"
        >
          <StatCard
            class="h-100"
            title="engagement"
            :data="[
              {
                description: 'engagement',
                value: activeSubmission.eng,
              },
            ]"
            color="blue--light"
            :shade="50"
            :header-tooltip="
              ew.find((vit) => vit.key === 'ENG')?.body || ''
            "
          >
            <template #dropdown-items>
              <li>
                <button
                  class="dropdown-item"
                  @click="openSubmissionModal"
                >
                  {{ $t('dashboard.view_submission') }}
                </button>
              </li>
            </template>
          </StatCard>
        </div>
        <template v-if="!setting('hidefeedback')">
          <div
            class="mb-4 mb-xl-0"
            :class="[
              authStore?.user?.member?.filter(
                (swarm) => swarm.visibility && !swarm.issegment
              )?.length
                ? 'col-xl-3'
                : 'col-xl-6',
            ]"
          >
            <!--          <ActiveCheckups
            class="h-100"
            @openCalendar="openCalendarModal"
          />-->
            <Card
              color="orange-yellow"
              class="flex-grow-1 w-100 h-100"
            >
              <template #header>
                <span class="overline text-sm">{{
                  $t('dashboard.feedback')
                }}</span>
              </template>
              <div
                class="w-100 h-100 d-flex flex-column justify-content-center align-items-center"
              >
                <span class="text-black-50 mb-1 text-center">{{
                  $t('dashboard.send_feedback')
                }}</span>
                <button
                  class="btn btn-sm btn-primary"
                  @click.prevent="openUserFeedbackModal"
                >
                  {{ $t('dashboard.submit_feedback') }}
                </button>
              </div>
            </Card>
          </div>
          <div
            v-if="
              authStore?.user?.member?.filter(
                (swarm) => swarm.visibility && !swarm.issegment
              )?.length
            "
            class="col-xl-3"
          >
            <RequestMeeting />
          </div>
        </template>
      </section>
      <section
        v-if="!setting('hideuserprofile')"
        class="row mb-4"
      >
        <div class="col-12">
          <Card
            color="cambridge-blue--light"
            class="flex-grow-1"
          >
            <template #header>
              <span class="overline text-sm">{{
                $t('dashboard.personal_report')
              }}</span>
            </template>
            <Suspense>
              <template #fallback>
                <div style="width: 100%; aspect-ratio: 3/4">
                  <Loader is-loading />
                </div>
              </template>
              <CheckupFeedback
                :data-id="activeSubmission.authorid"
                :data="
                  [
                    activeSubmission.isprocessed
                      ? {
                          title: activeSubmission.title,
                          id: activeSubmission.id,
                          data: personalLabels.map((label, i) => ({
                            key: label,
                            score: radarData[i],
                          })),
                        }
                      : null,
                  ].filter(Boolean)
                "
                type="user"
                :cluster="activeSubmission.cluster"
              />
            </Suspense>
          </Card>
        </div>
      </section>
    </template>
    <!--   Loading component -->
    <template v-else-if="!loaded">
      <div
        class="d-flex justify-content-center align-items-center"
        style="height: 50vh"
      >
        <div
          class="spinner-border"
          role="status"
        >
          <span class="visually-hidden"
            >{{ $t('message.loading') }}...</span
          >
        </div>
      </div>
    </template>
    <!--    No data available -->
    <template v-else>
      <div
        class="d-flex flex-column justify-content-center align-items-center mt-4 rounded-8"
        style="height: 50vh; border: 1px #ccc dashed"
      >
        <p class="text-black-50 text-center">
          {{ $t('warning.no_data') }}
        </p>
        <p class="text-black-50 text-center mb-2">
          {{ $t('warning.check_calendar') }}
        </p>
        <button
          class="btn btn-primary"
          @click="openCalendarModal"
        >
          {{ $t('dashboard.open_calendar') }}
        </button>
      </div>
    </template>
    <div
      v-if="setting('actionsenabled')"
      class="row mt-4 pb-4"
    >
      <section class="col-xl-6">
        <ActionsCard
          :available-types="['personal']"
          class="h-100"
        />
      </section>
      <section class="col-xl-6 mt-4 mt-xl-0">
        <MessagesCard class="h-100" />
      </section>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import SubmissionModal from '@/views/components/modals/SubmissionModal';
import CalendarModal from './components/modals/CalendarModal';
import RequestMeeting from '@/views/components/RequestMeeting';
import ActionsCard from '@/components/ActionsCard';
import MessagesCard from '@/components/MessagesCard';
import StatCard from '@/views/components/StatCard';
import Card from '@/views/components/simple/Card';
import UserFeedbackForm from '@/views/components/UserFeedbackForm';
import ActiveCheckups from '@/components/ActiveCheckups';
import { mapActions, mapState } from 'pinia';
import { useModalStore } from '@/stores/modal';
// import PersonalFeedback from '../components/PersonalFeedback/PersonalFeedback';
import { useMainStore } from '../stores';

import CheckupFeedback from '../components/PersonalFeedback/CheckupFeedback';
import Pagination from '../components/pagination/Pagination.vue';
import qs from 'qs';

export default {
  name: 'Home',
  components: {
    Pagination,
    CheckupFeedback,
    // PersonalFeedback,
    ActiveCheckups,
    UserFeedbackForm,

    Card,
    StatCard,
    MessagesCard,
    ActionsCard,
    RequestMeeting,
  },
  data() {
    return {
      loaded: false,
      hasData: false,
      ew: [],
      wbVal: null,
      engVal: null,
      // submission: null,
      normering: null,
      // subID: null,
      // subTitle: '',
      selectedItem: null,
      // New submission handling
      submissions: [],
      submissionKey: 0,
      types: {
        EB: 'energiebronnen',
        UIT: 'uitdagingen',
        HIN: 'hinderpalen',
        PHB: 'hulpbronnen',
        IM: 'motivatie',
        ATT: 'attitudes',
        PRES: 'prestaties',
        ONG: 'ongezondheid',
      },
      personalLabels: [
        'EB',
        'UIT',
        'HIN',
        'PHB',
        'IM',
        'ATT',
        'PRES',
        'ONG',
      ],
      moment: moment,
    };
  },
  created() {
    this.initDash();
  },
  mounted() {
    this.initDash();
  },
  methods: {
    ...mapActions(useModalStore, ['openModal']),
    /**
     * Open the calendar from any calendar button on the page
     */
    openCalendarModal() {
      this.openModal({
        component: CalendarModal,
        options: {
          centered: true,
          clickOutside: true,
          size: 'lg',
        },
        properties: {},
        events: {},
      });
    },
    /**
     * Open the submission modal
     */
    openSubmissionModal() {
      this.openModal({
        component: SubmissionModal,
        options: { centered: false, size: 'lg' },
        properties: {
          submissionId: this.activeSubmission.id,
        },
      });
    },
    /**
     * Initialize the dashboard by preparing data for display
     * @returns {Promise<void>}
     */
    async initDash() {
      /*  if (this.setting('hideuserprofile')) {
        this.hasData = false;
        this.mitt.emit('loadcomplete', { complete: true });
        this.loaded = true;
        return;
      } */
      let [
        { data: normeringen },
        { data: latestSubmission },
        { data: ew },
      ] = await Promise.all([
        this.$axios.get(
          '/analytics-items?isactive=true&type=normering&_sort=subtype:asc'
        ),
        // TODO: This is not right!
        this.$axios.get(
          `/checkupsubmissions?${qs.stringify({
            isfinished: true,
            _sort: 'updated_at:desc',
          })}`
        ),
        this.$axios.get(
          `/vitamins?key_in[]=WB&key_in[]=ENG&_locale=${this.$i18n.locale}`
        ),
      ]);

      latestSubmission = latestSubmission.filter((submission) => {
        return (
          !submission?.checkupentry ||
          submission.checkupentry?.checkup?.checkupblocks?.[0]
            .title === 'Vitamins block'
        );
      });

      this.normering = normeringen.filter((item) =>
        [
          ...Object.values(this.types).map((item) =>
            item.toLowerCase()
          ),
          'wellbeing',
          'engagement',
        ].includes(item.subtype.toLowerCase())
      );

      this.ew = ew;
      /**
       * If there is a submission, get submission data and ONLY KEEP REQUIRED KEYS
       */
      if (latestSubmission.length) {
        /*const latestProcessed = latestSubmission.find(
          (submission) => submission.isprocessed
        );*/
        this.submissions = latestSubmission.map((submission) => ({
          id: submission.id,
          title: `${
            submission?.checkupentry?.checkup?.title ||
            this.$t('vitamins.own_vitamin')
          } - ${moment(submission.created_at).format('DD/MM/YYYY')}`,
          wb: submission.isprocessed
            ? this.getNormering('wellbeing', submission.data.data.WB)
            : 0,
          isprocessed: submission.isprocessed,
          wbVal: submission.data.data.WB,
          engVal: submission.data.data.ENG,
          authorid: submission.authorid,
          eng: submission.isprocessed
            ? this.getNormering(
                'engagement',
                submission.data.data.ENG
              )
            : 0,
          cluster: submission.data.cluster,
          data: submission.isprocessed
            ? Object.keys(submission.data.data)
                .filter(
                  (key) =>
                    this.personalLabels
                      .map((item) => item.toLowerCase())
                      .includes(key.toLowerCase()) // This filters out unnecessary keys to reduce bloated objects
                )
                .reduce((cur, key) => {
                  return Object.assign(cur, {
                    [key]: submission.data.data[key],
                  });
                }, {})
            : {},
        }));
        this.hasData = true;
      }

      this.mitt.emit('loadcomplete', { complete: true });
      this.loaded = true;
    },
    getAdvice(key, value) {
      let type = {
        25: 'low',
        75: 'average',
        100: 'high',
      };
      if (['UIT', 'WD', 'COG', 'COM'].includes(key)) {
        type = {
          5: 'very_low',
          15: 'low',
          85: 'average',
          95: 'high',
          100: 'very_high',
        };
      }

      // get next higher score from type
      const nextScore = Object.keys(type).find((key) => key > value);

      return `personal_feedback.user.radar.${key}.${type[nextScore]}`;
    },

    getNormering(type, val) {
      const norm = this.normering.find(
        (n) => n.subtype.toLowerCase() === type.toLowerCase()
      );

      if (norm && Object.keys(norm.data).length) {
        //! REMOVE THIS WHEN NORMERING IS FIXED, THIS IS A TEMP FIX
        const closest = Object.keys(norm.data).reduce(
          (prev, curr) => {
            return Math.abs(curr - val) < Math.abs(prev - val)
              ? curr
              : prev;
          }
        );
        return norm.data[closest];
      } else {
        return 0;
      }

      // return norm?.data[val] || 0;
    },

    async openFeedbackModal(key, score) {
      const { data: items } = await this.$axios.request({
        url: '/vitamins',
        params: {
          key,
        },
      });

      this.selectedItem = {
        dataId: this.subID,
        ...items[0],
        score,
      };
      this.$refs.feedbackModal.setOpen(true);
    },

    openUserFeedbackModal() {
      this.openModal({
        component: UserFeedbackForm,
        options: {
          centered: true,
          clickOutside: true,
          size: 'lg',
        },
        properties: {},
        events: {},
      });
    },
  },
  computed: {
    ...mapState(useMainStore, ['setting', 'vitaminColors']),
    activeSubmission() {
      return this.submissions[this.submissionKey];
    },
    wellbeing() {
      if (!this.activeSubmission.data) return null;
      return Math.round(
        this.normering
          ? this.getNormering('wellbeing', this.activeSubmission.wb)
          : /*(this.wbVal / 7) * 100*/ 0
      );
    },
    engagement() {
      if (!this.activeSubmission.data) return null;
      return Math.round(
        this.normering
          ? this.getNormering('engagement', this.activeSubmission.eng)
          : /*(this.engVal / 7) * 100*/ 0
      );
    },
    radarData() {
      if (!this.activeSubmission.data) return [];

      let radar = [];
      for (const key in this.types) {
        const data = this.activeSubmission.data[key];
        radar.push(this.getNormering(this.types[key], data));
      }

      return radar;
    },
  },
  watch: {
    '$i18n.locale'() {
      this.initDash();
    },
  },
};
</script>
<style lang="scss"></style>
