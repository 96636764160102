<template>
  <Card color="orange-yellow">
    <template #header>
      <div
        class="w-100 d-flex justify-content-between align-items-center"
      >
        <span class="text-uppercase text-sm">Submit feedback</span>
        <button
          type="button"
          class="d-block ms-auto btn-close"
          aria-label="Close"
          @click="closeModal"
        />
      </div>
    </template>
    <div>
      <form @submit.prevent="submitFeedback">
        <div class="d-flex flex-lg-row flex-column mb-4">
          <p class="mb-lg-0 mb-2 d-flex align-items-center mx-1">
            {{ $t('message.fb_wat_kan') }}
          </p>
          <select
            id="cars"
            v-model="form.teamId"
          >
            <option :value="0">
              {{ companyName }}
            </option>
            <!--          <option value="team">team</option>-->
            <option
              v-for="swarm in availableTeams"
              :key="swarm.id"
              :value="swarm.id"
            >
              {{ swarm.title }}
            </option>
          </select>
          <p
            class="mb-0 mt-2 mt-lg-0 text-lowercase d-flex align-items-center mx-1"
          >
            {{ $t('message.fb_voor_u_doen') }}
          </p>
        </div>
        <textarea
          v-model="form.body"
          class="w-100"
          style="height: 145px"
        />
        <slot
          name="feedback-submit"
          :form="form"
        >
          <button class="btn btn-primary float-end">
            <div
              v-if="isLoading"
              class="spinner-border me-1"
              role="status"
              style="height: 1rem; width: 1rem"
            >
              <span class="visually-hidden"
                >{{ $t('message.loading') }}...</span
              >
            </div>
            {{ $t('message.send') }}
          </button>
        </slot>
      </form>
    </div>
  </Card>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useMainStore } from '@/stores';
import { TOAST_TYPES } from '../../helpers/constants';
import Card from './simple/Card';
import { useModalStore } from '../../stores/modal';

export default {
  name: 'UserFeedbackForm',
  components: { Card },
  data() {
    return {
      isLoading: false,
      form: {
        teamId: 0,
        body: '',
      },
    };
  },
  computed: {
    ...mapState(useMainStore, ['companyName']),
    availableTeams() {
      return (
        this.authStore.user.member?.filter(
          (item) => item.visibility && !item.issegment
        ) || []
      );
    },
  },
  methods: {
    ...mapActions(useMainStore, ['addToast']),
    ...mapActions(useModalStore, ['closeModal']),
    resetForm() {
      this.form = {
        teamId: 0,
        body: '',
      };
    },
    async submitFeedback() {
      try {
        if (!this.form.body) {
          return this.addToast({
            type: TOAST_TYPES.ERROR,
            content: this.$t('message.feedback_empty'),
          });
        }
        this.isLoading = true;
        await this.$axios.post('/feedbacks', this.form);
        this.addToast({
          type: TOAST_TYPES.SUCCESS,
          content: this.$t('message.feedback_sent'),
        });
        this.resetForm();
        this.closeModal();
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.title_dash {
  font-size: 12px;
}

.pos-neg-block {
  box-shadow: 0 0.25rem 0.35rem rgba(0, 0, 0, 0.075);
}

.pos-neg-block:hover {
  box-shadow: none;
}
</style>
