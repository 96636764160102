<template>
  <div class="chart position-relative">
    <canvas
      ref="chartCanvas"
      class="chart-canvas"
    />
  </div>
</template>
<script>
import { Chart, DoughnutController, ArcElement } from 'chart.js';
import { getColor, getScoreColor } from '../../helpers/color';
Chart.register(DoughnutController, ArcElement);

export default {
  props: {
    pct: {
      default() {
        return 0;
      },
    },
    invertColors: {
      type: Boolean,
      default() {
        return false;
      },
    },
    borderWidth: {
      default() {
        return 1;
      },
    },
    fSize: {
      default() {
        return 'lg';
      },
    },
    color: {
      default() {
        return false;
      },
    },
    type: {
      default() {
        return '';
      },
    },
    analytics: {
      default: '',
    },
  },
  data() {
    return {
      activeColor: null,
      firstLoad: true,
      i: 0,
    };
  },
  computed: {
    roundedPct() {
      return isNaN(Math.round(this.pct)) ? 0 : Math.round(this.pct);
    },
  },
  hideAnimation: {
    default() {
      return false;
    },
  },
  watch: {
    pct: function (newval, oldval) {
      if (newval != oldval && this.myChart) {
        this.myChart.data.datasets[0].percent = this.roundedPct;
        this.myChart.data.datasets[0].backgroundColor = [
          this.getColor(),
          '#ebebeb',
        ];
        this.myChart.update();
      }
    },
    color: function (newval, oldval) {
      if (newval !== oldval) {
        this.myChart.data.datasets[0].percent = this.roundedPct;
        this.myChart.data.datasets[0].backgroundColor = [
          this.getColor(),
          '#ebebeb',
        ];
        this.myChart.update();
      }
    },
  },
  created() {
    if (this.firstLoad) {
      this.mitt.on('hiddenLoader', (e) => this.handleLoaded(e));
      this.firstLoad = false;
    }
  },
  mounted() {
    if (!this.firstLoad) {
      this.handleLoaded();
    }
  },
  beforeUnmount() {
    if (this.myChart) {
      this.myChart.destroy();
    }
  },
  methods: {
    getColor() {
      if (!this.color) {
        return getScoreColor(
          this.roundedPct,
          this.type,
          this.analytics
        );
      }
      return getColor(this.color);
    },
    handleLoaded() {
      if (!this.$refs.chartCanvas || !this.$el) return;
      if (this.myChart) {
        this.myChart.update();
      } else {
        // const w = this.$el.clientWidth;
        // const b = w / 2 - this.borderWidth;
        this.myChart = new Chart(this.$refs.chartCanvas, {
          type: 'doughnut',
          data: {
            datasets: [
              {
                label: 'Progress',
                percent: this.roundedPct,
                backgroundColor: [this.getColor(), '#ebebeb'],
              },
            ],
          },
          /**
           * Plugins to get the center text in the graph
           */
          plugins: [
            {
              beforeInit: (chart) => {
                const dataset = chart.data.datasets[0];
                chart.data.labels = [dataset.label];
                dataset.data = [
                  dataset.percent,
                  100 - dataset.percent,
                ];
              },
            },
            {
              beforeUpdate: (chart) => {
                const dataset = chart.data.datasets[0];
                chart.data.labels = [dataset.label];
                dataset.data = [
                  dataset.percent,
                  100 - dataset.percent,
                ];
              },
            },
            {
              beforeDraw: (chart) => {
                const width = chart.width;
                const height = chart.height;
                const ctx = chart.ctx;
                ctx.restore();
                const fontSize = (height / 40).toFixed(2);
                ctx.font = `normal normal bold ${fontSize}em Nunito Sans, sans-serif`;
                ctx.fillStyle = `#232323`;
                ctx.textBaseline = 'middle';
                const text = chart.data.datasets[0].percent;
                const textX = Math.round(
                  (width - ctx.measureText(text).width) / 2
                );
                const textY = height / 2;
                ctx.fillText(text, textX, textY - height / 18);
                ctx.font = `${(height / 80).toFixed(
                  2
                )}em Nunito Sans, sans-serif`;
                ctx.fillText(
                  '%',
                  Math.round(
                    (width - ctx.measureText('%').width) / 2
                  ),
                  textY + height / 5
                );
                ctx.save();
              },
            },
          ],
          options: {
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            parsing: false,
            normalized: true,
            hover: { mode: null },
            borderWidth: 0,
            cutout: '80%',
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.size-sm {
  .progress-score {
    padding-top: 4px !important;
    font-size: 16px !important;
  }

  .progress-pct {
    font-size: 10px !important;
  }
}

.size-lg {
  .progress-score {
    padding-top: 8px !important;
    font-size: 40px !important;
  }

  .progress-pct {
    margin-top: -3px;
    font-size: 18px !important;
  }
}
</style>
