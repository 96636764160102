import axios from 'axios';
import config from '../public/config.json';

const axiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? `${config.API_HOST}:${config.API_PORT}`
      : `${process.env.VUE_APP_ROOT_API}:${process.env.VUE_APP_ROOT_API_PORT}`,
});

// Set base url instantly
const loadConfig = async () => {
  try {
    const res = await fetch('/config.json');
    const config = await res.json();

    axiosInstance.defaults.baseURL = `${config.API_HOST}:${config.API_PORT}`;
  } catch (error) {
    console.log(error);
  }
};

const plugin = {
  install: (app) => {
    app.config.globalProperties.$axios = axiosInstance;
  },
};

export { axiosInstance, plugin, loadConfig };
