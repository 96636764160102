<script setup>
import {
  TransitionRoot,
  Dialog,
  TransitionChild,
  DialogPanel,
} from '@headlessui/vue';
import { defineProps, defineExpose, ref, provide } from 'vue';

const isOpen = ref(false);
const props = defineProps({
  size: {
    type: String,
    default: 'lg',
  },
  centered: {
    type: Boolean,
    default: true,
  },
  disableBackground: {
    type: Boolean,
    default: false,
  },
});

const setOpen = (value) => {
  isOpen.value = value;
};

const onClose = (e) => {
  if (props.disableBackground) return;
  isOpen.value = e;
};

provide('setOpen', setOpen);

defineExpose({
  setOpen,
});
</script>

<template>
  <TransitionRoot
    appear
    :show="isOpen"
    as="template"
  >
    <Dialog
      :open="isOpen"
      style="opacity: 1"
      @close="onClose"
    >
      <TransitionChild
        as="template"
        enter-from="bgFrom"
        enter-to="bgTo"
        leave-from="bgTo"
        leave-to="bgFrom"
        enter="transition"
        leave="transition"
      >
        <div
          class="position-fixed top-0 left-0 w-100 h-100"
          style="background: #adadad80; z-index: 4"
        />
      </TransitionChild>
      <div
        class="position-fixed top-0 left-0 w-100 overflow-auto"
        style="z-index: 4"
      >
        <div
          class="d-flex min-vh-100 w-100 justify-content-center p-4"
          :class="[props.centered ? 'align-items-center' : '']"
        >
          <TransitionChild
            as="template"
            enter-from="scaleFrom"
            enter-to="scaleTo"
            leave-from="scaleTo"
            leave-to="scaleFrom"
            enter="transition"
            leave="transition"
          >
            <DialogPanel
              class="mx-auto"
              :class="[`modal-${props.size}`, 'border-0 w-100 panel']"
              style="max-height: 80vh"
            >
              <slot />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
export default {
  name: 'Modal',
};
</script>

<style lang="scss">
.transition {
  transition: all 0.3s ease;
}

.bgFrom {
  opacity: 0;
}

.bgTo {
  opacity: 1;
}

.scaleFrom {
  transform: scale(0.9);
  opacity: 0;
}

.scaleTo {
  transform: scale(1);
  opacity: 1;
}

.modal-content {
  border: 0 !important;
  text-align: start;
}

.panel {
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
}
</style>
