<script setup>
import CheckupProgress from "./CheckupProgress";
import { defineEmits, defineProps } from "vue";

defineProps({
  showPrevious: {
    type: Boolean,
    required: true,
  },
});

defineEmits(["previous", "next"]);
</script>

<template>
  <nav>
    <ul class="pagination justify-content-between mb-0">
      <li
        v-if="showPrevious"
        class="page-item"
      >
        <a
          href="#"
          class="btn"
          aria-label="previous"
          @click.prevent.stop="$emit('previous')"
        >
          <span aria-hidden="true">
            <img
              src="../../assets/icons/previous.svg"
              alt=""
              class="d-block img-fluid"
            >
          </span>
        </a>
      </li>
      <CheckupProgress
        as="li"
        class="ms-md-auto page-item d-block d-md-none"
      />
      <li class="ms-md-auto page-item">
        <a
          class="btn"
          href="#"
          aria-label="next"
          @click.prevent.stop="$emit('next')"
        >
          <span aria-hidden="true">
            <img
              src="../../assets/icons/next.svg"
              alt=""
              class="d-block img-fluid"
            >
          </span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "CheckupNavigation",
};
</script>

<style scoped></style>
