<script setup>
import Card from "../../views/components/simple/Card";
import UserFeedbackForm from "../../views/components/UserFeedbackForm";
import { useCheckupStore } from "../../stores/checkup";
import { ref } from "vue";

const checkupStore = useCheckupStore();

// Variables
const isSubmitted = ref(false);
const isLoading = ref(false);
const feedbackForm = ref(null);

// Functions
/**
 * Update the current submission to finish it
 */
const finalizeSubmission = () => {
  checkupStore.isfinished = true;
  checkupStore.submit();
};

/**
 * Update the submission after form submission
 * @returns {Promise<void>}
 */
const submitFeedback = async () => {
  await feedbackForm.value.submitFeedback();
  finalizeSubmission();
};
</script>

<template>
  <Card>
    <template #header>
      <span />
    </template>
    <h4>{{ $t("message.feedback") }}</h4>
    <UserFeedbackForm ref="feedbackForm">
      <template #feedback-submit>
        <div class="actions row">
          <div class="col-auto mx-auto">
            <button
              v-if="!isSubmitted"
              class="btn btn-primary me-2"
              @click.prevent="submitFeedback"
            >
              <div
                v-if="isLoading"
                style="height: 12px; width: 12px"
                class="spinner-border"
                role="status"
              >
                <span class="visually-hidden">{{ $t("message.loading") }}...</span>
              </div>
              <span
                v-if="!isLoading"
                class="text-sentence"
              >{{
                $t("message.geef_feedback")
              }}</span>
            </button>
            <router-link
              :to="{
                name: 'dashboard',
              }"
              class="btn btn-secondary"
              @click="finalizeSubmission"
            >
              <span
                v-if="isSubmitted"
                class="text-sentence"
              >{{ $t("message.bedankt") + ", " }} </span>{{ $t("message.ga_terug_naar_dashboard") }}
            </router-link>
          </div>
        </div>
      </template>
    </UserFeedbackForm>
  </Card>
</template>

<script>
export default {
  name: "CheckupFeedback",
};
</script>

<style scoped></style>
